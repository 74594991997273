import { curry } from 'ramda';

/* NOTE: Use with styled-components to easily switch between versions of component
   name refers to the prop you would like to use when calling the component:
   <Button variant="primary" ... />
   or
   <Button size="small" ... />

   ex:
   const fontSize = variant('size', {
     sm: '1px,
     lg: '10000px',
   });

   const MyComp = styled.p`
     font-size: ${fontSize};
   `;

   const UseMyComp = () => (
     <MyComp size="sm">Heisann</MyComp>
   );

   it's possible to define variant that retrieves values from theme as well:

   const fontSize = variant('variant', {
     sm: getStyle('font.sm'),
     lg: getStyle('font.lg'),
   });

 */
const variant = curry(
  (name, variants, props) => {
    const appliedVariant = variants[props[name]];

    if (!appliedVariant) {
      /* eslint-disable-next-line */
      console.error(`Unsupported ${name} ${props[name]} supplied to ${JSON.stringify(variants)}`);
    }

    if (typeof appliedVariant === 'function') {
      return appliedVariant(props);
    }

    return appliedVariant;
  }
);

export default variant;
