import PropTypes from 'prop-types';
import { H4, Button, Text } from 'components/elements';
import { styled, getStyle } from 'style';
import { useNavigate } from "react-router-dom";
import { RemoveIcon as CloseIcon } from 'assets/icons'
import AlertIcon from './AlertIcon';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${getStyle('size.overlay.li.height')};
  padding: 0 ${getStyle('spacing.xl')};
  border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};
`;

const NotificationContainer = styled.li`
  display: flex;
  height: ${getStyle('size.overlay.li.height')};
  border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #FFFFFF;

  .dot {
    margin-top: ${getStyle('spacing.sm')};
    margin-left: ${getStyle('spacing.xl')};
  }
  .text {
    margin-left: 3rem;
    width: 100%;
    margin-right: ${getStyle('spacing.xl')};
    h4 {
      padding: ${getStyle('spacing.sm')} 0;
    }
    p {
      margin-bottom: ${getStyle('spacing.sm')};
    }
  }

  &:hover {
    background-color: #F0EFEF;
  }
`;

const NotificationItem = ({ notification, isUser, onClose }) => {
  const navigate = useNavigate();
  const sendToMessages = () => {
    onClose();
    if(isUser) navigate('/messages/');
    else {
      const cameFromMessagesPage = window.location.href.includes('/accountmanager/messages/');
      navigate('/accountmanager/messages/'+notification.createdBy)
      //Must refresh page if we are already in accman/messages
      if(cameFromMessagesPage) {
        navigate(0);
      }
    }
  }

  return (
    <NotificationContainer style={{cursor: 'pointer'}}>
      <AlertIcon className="dot" hidden={notification.status !== 'new'}/>
      <div
        className="text"
        onClick={()=> { if(notification.messageId) sendToMessages(); else { onClose(); navigate('/complaints');}}}
      >
        <H4>{notification.subject}</H4>
        <Text>{notification.text?.length > 50 ? notification.text.substr(0, 50) + "..." : notification.text}</Text>
      </div>
    </NotificationContainer>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

const NotificationList = ({ notifications, onClose, isUser }) => (
  <>
    <Header>
      <H4>Varsler</H4>
      <Button variant="inline" aria-label="Lukk" onClick={() => onClose()}>
        <CloseIcon />
      </Button>
    </Header>
    <ul>
      {notifications.map(notification => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          isUser={isUser}
          onClose={onClose}
        />
      ))}
    </ul>
  </>
);

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default NotificationList;
