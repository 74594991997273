import {
    httpGet, httpPost, jsonOrThrow, httpPut, throwOnError
} from 'services/common';
import config from 'config';

const getNotificationsUrl = () => (
    `${config.apiHost}/api/notifications`
);

const getNotificationStatusUrl = id => (
    `${config.apiHost}/api/notifications/${id}/status`
);

const notifications = {
    postNotification: notification => (
        httpPost(getNotificationsUrl(), notification)
            .then(throwOnError)
    ),

    getNotifications: ({ limit }) => (
        httpGet(`${getNotificationsUrl()}?limit=${limit}`)
            .then(jsonOrThrow)
    ),

    setNotificationStatus: (notificationId, status) => (
        httpPut(getNotificationStatusUrl(notificationId), status)
            .then(throwOnError)
    )
};

export default notifications;
