import { sanity } from './index.js';

const fetchAssociationAndCategories = async (borettslagId) => {

  const data = await sanity.fetch(`*[_type == 'postCategory']
  {
    title, slug, _id, subtitle, descriptionSmall, description, buttonText, render, wide
    ,image{
      asset->{_id,url}
    },
    "posts": *[_type=='post' && references(^._id)]{
      _id,
      title,
      subtitle,
      render,
      checklist,
      publishedAt,
      mainImage{
        asset->{
          _id,
          url
         }
       },
       body,
       "name": author->name,
       "authorImage": author->image,
       files[]{asset->{url,originalFilename}}
    },
  parent,
  "children":[]
  } | order(order asc)`);
  const categories = data.map((val, index) => {
    // eslint-disable-next-line no-param-reassign
    val.slug.current = `/borettslag/${val.slug.current}`;
    if (val.parent) {
      const parent = data.find(obj => (obj._id === val.parent._ref));
      if (parent) {
        parent.children.push(val);
        return undefined;
      }
    }
    return val;
  }).filter((val, index) => val !== undefined);
  const borettslag = await sanity.fetch(`*[_type == 'borettslag' && _id == '${borettslagId}']
  {
    name,
    bio,
    slug,
    _id,
    image{
      asset->{_id,url}
    },
    contacts[]->{name, title, email, mobile, image{asset->{url}}}
  }`);
  const data2 = borettslag[0];
  return {
    borettslag: data2,
    categories
  };
}

const fetchAssociationList = async () => {
  const list = await sanity.fetch(`*[_type == 'borettslag']
  {
    name, _id
  } | order(order asc)`);
  return list.map((o) => {
    return {id: o._id, label: o.name};
  });
}

const fetchAssociationInformationList = async () => {
  const list = await sanity.fetch(`*[_type == 'borettslag' && name != 'Privat']
{
  name,
  bio,
  slug,
  _id,
  image{
    asset->{_id,url}
  }}| order(_updatedAt desc)`);
  return list.map((o) => {
    return {id: o._id, label: o.name, picture: o.image ? o.image.asset.url : null};
  });
}


const fetchAssociationAndPostForCategory = async (borettslagId, slug) => {
  // category = context.params.slug
  const slugParsed = typeof slug === 'object' ? slug.current.split('/').pop() : slug;
  const data = await sanity.fetch(`*[_type=="postCategory" && slug.current=='${slugParsed}']{
  title,
  subtitle,
  usage,
  description,
  showContact,
  wide,
  imageMain{
    asset->{_id,url}
  },
  "posts": *[_type=='post' && references(^._id) && borettslag._ref =='${borettslagId}']{
    title,
    subtitle,
    render,
    checklist,
    publishedAt,
    mainImage{
      asset->{
        _id,
        url
       }
     },
     body,
     "name": author->name,
     "authorImage": author->image,
     files[]{asset->{url,originalFilename}}
  }
}`);

const borettslag = await sanity.fetch(`*[_type == 'borettslag' && _id == '${borettslagId}']
{
  name,
  bio,
  slug,
  _id,
  image{
    asset->{_id,url}
  },
  contacts[]->{name, title, email, mobile, image{asset->{url}}}
}`);
  const data2 = borettslag[0];
  return {
    borettslag: data2,
    category: data.length > 0 ? data[0] : { title: '', posts: [] }
  };
}

const fetchAllierPublic = async () => {
  const allier = await sanity.fetch(`*[_type == 'allier']
  {
    _id,
    title,
    subtitle,
    mainImage{
      asset->{_id,url}
    },
    aboutAllierImage{
      asset->{
        _id,
        url
       }
     },
     aboutUnikImage{
      asset->{
        _id,
        url
       }
     },
    bioallier,
    biounik,
    checklist,
    welcomeGdpr
  }`);

  const quotes = await sanity.fetch(`*[_type == 'quotes']
  {
    _id,
    content,
    quotee
  }`);
  const references = await sanity.fetch(`*[_type == 'references']
  {
    _id,
    slug,
    title,
    intro,
    content
  }`);
  return {
    allier: allier[0],
    quotes,
    references
  };
}

const fetchAssociationChecklist = async (profile) => {
  const borettslagId = profile.association.sanityId;
  const borettslag = await sanity.fetch(`*[_type == 'borettslag' && _id == '${borettslagId}']
  {
    name,
    checklist
  }`);
  return {
    borettslag: borettslag[0]
  };
}

const fetchAssociationIdehefteUrl = async (profile) => {
  const borettslagId = profile.association.sanityId;
  var result = await sanity.fetch(`*[_type == 'borettslag' && _id == '${borettslagId}']
  {
    name,
    file{asset->{url,originalFilename}}
  }`);
  return {
    url: result[0]?.file.asset.url
  }
}

const fetchPrivateAssociation = async () => {
  const association = await sanity.fetch(`*[_type == 'borettslag' && name == 'Privat']
{
  name,
  bio,
  slug,
  _id,
  image{
    asset->{_id,url}
  },
  contacts[]->{name, title, email, mobile, image{asset->{url}}}
}`);
  return {
    privateAssociation: association[0]
  }
}

const fetchReference = async slug => {
  const reference = await sanity.fetch(`*[_type=="references" && slug.current=='${slug}']{
    title,
    intro,
    content
  }`);
  return {
    reference: reference[0]
  };
}

const fetchInspiration = async () => {
  return sanity.fetch(`*[_type=="inspiration"]{
  title,
  "slug": slug.current,
  _id,
  "linkArray": linkArray[] {
    "linkTitle": ^.linkTitle,
    "link": *[_type == "inspiration" && _id == ^.LinkRef._ref][0].slug.current,
  },
  content[] {
    _type,
    _key,
    title,
    text,
    showTitle,
    line,
    titleType,
    linkToBooklet,
    linkTitle,
    elementType,
    bookletText,
    "imageUrl":asset->url,
    imageType,
    hotspot,
    "link": *[_type == "inspiration" && _id == ^.link._ref][0].slug.current,
  },
}`) || [];
}

const fetchPhaseDescriptions = async () => {
  const [{ phaseDescriptions } = {} ] = await sanity.fetch(`*[_type == "phaseDescriptions"]{
    phaseDescriptions
  }`) || [];

  return phaseDescriptions;
}

const service = {
  fetchAllierPublic,
  fetchAssociationAndCategories,
  fetchAssociationAndPostForCategory,
  fetchAssociationChecklist,
  fetchAssociationIdehefteUrl,
  fetchPrivateAssociation,
  fetchReference,
  fetchPhaseDescriptions,
  fetchInspiration,
  fetchAssociationList,
  fetchAssociationInformationList
}

export default service;
