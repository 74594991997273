import {
  httpGet, httpPost, httpPutFile, httpDelete, jsonOrThrow, throwOnError
} from 'services/common';
import config from 'config';

const getFilesUrl = () => (
  `${config.apiHost}/api/files`
);

const getFilesWithTagUrl = () => (
  `${config.apiHost}/api/files/getFilesByTag`
);

const getFileLocation = fileId => (
  `${getFilesUrl()}/content/${fileId}`
);

const getFileSas = fileId => (
  `${getFilesUrl()}/sas/${fileId}`
);

const getFileSasTyped = fileId => (
  `${getFilesUrl()}/sastype/${fileId}`
);

const service = {
  getFiles: userId => (
    userId ? (
      httpGet(`${getFilesUrl()}?userId=${userId}`)
        .then(jsonOrThrow)
    ) : (
      Promise.resolve({ files: [] })
    )
  ),

  getFilesByTagId: tagId => (
    tagId ? (
      httpGet(`${getFilesWithTagUrl()}/${tagId}`)
        .then(jsonOrThrow)
    ) : (
      Promise.resolve({ files: [] })
    )
  ),

  getMediabankFiles: name => (
    name ? (
      httpGet(`${getFilesUrl()}/tagByName/${name}`)
        .then(jsonOrThrow)
    ) : (
      Promise.resolve({ tagId: '' })
    )
  ),

  getCustomersFromFile: fileId => {
    return fileId
      ? httpGet(`${getFilesUrl()}/getCustomersFromFile/${fileId}`).then(jsonOrThrow)
      : Promise.resolve({ customers: [] });
  },


  removeCustomerFromFile: (customerId, fileId) => {
    return (fileId && customerId)
      ? httpPost(`${getFilesUrl()}/removeCustomerFromFile`, ({ CustomerId: customerId, FileId: fileId })).then(jsonOrThrow)
      : Promise.resolve({ customers: [] });
  },


  getTagId: tagName => (
    httpGet(`${getFilesUrl()}/getTagId/${tagName}`)
      .then(jsonOrThrow)
  ),

  uploadFile: (meta, content) => (
    httpPost(getFilesUrl(), meta)
      .then(res => {
        if(res.ok) {
          return res.json()
        }
        return res.text().then(text => {throw new Error(text)})
      })
      .then(result => (
        httpPutFile(result.location, content)
          .then(throwOnError)
          .then(() => result)
      ))
  ),

  downloadFile: fileId => (
    httpGet(getFileLocation(fileId))
      .then(throwOnError)
      .then(response => (
        response.arrayBuffer()
          .then(buffer => ({
            type: response.headers.get('Content-Type'),
            content: buffer
          }))
      ))
  ),

  getFileSas: fileId => (
    httpGet(getFileSas(fileId))
      .then(jsonOrThrow)
      .then(result => (
        result.url
      ))
  ),

  getFileSasTyped: fileId => (
    httpGet(getFileSasTyped(fileId))
      .then(jsonOrThrow)
      .then(result => (
        result.url
      ))
  ),

  deleteFile: fileId => (
    httpDelete(`${getFilesUrl()}/${fileId}`)
      .then(throwOnError)
  ),

  deleteFiles: files => (
    httpPost(`${getFilesUrl()}/deleteFiles`, files)
      .then(throwOnError)
  ),

  getImportTemplateUrl: () => (
    httpGet(`${getFilesUrl()}/importtemplate`)
      .then(jsonOrThrow)
  ),

  sendFilesToCustomers: (fileAccessRequest) => (
    httpPost(`${getFilesUrl()}/sendFilesToCustomers`, fileAccessRequest)
      .then(throwOnError)
  ),
};

export default service;
