import {
  httpGet, httpPost, httpPut, jsonOrThrow, throwOnError
} from 'services/common';
import config from 'config';

const guide = {
  steps: [
    {
      id: '1',
      type: 'area',
      title: 'Gulvareal',
      text: 'Hvor mange kvadratmeter er baderommet ditt i dag?',
      value: {
        label: 'Areal',
        min: 1,
        max: 20,
        default: 8,
        step: 1,
        unit: 'm²'
      },
      next: '2'
    },
    {
      id: '2',
      type: 'slider',
      title: 'Vegger',
      text: 'Hvor mange løpemeter vegg har baderommet ditt?',
      description: 'Hjelpetekst?',
      value: {
        label: 'Løpemeter',
        min: 1,
        max: 50,
        default: 12,
        step: 1,
        unit: 'meter'
      },
      next: '3'
    },
    {
      id: '3',
      type: 'number',
      title: 'Takhøyde',
      text: 'Hvor høyt er det til taket på baderommet ditt?',
      description: 'Dersom badet har skråtak, velg gjennomsnittlig takhøyde.',
      value: {
        label: 'Takhøyde i meter',
        min: 1.8,
        max: 4.0,
        decimals: 2,
        default: 2.1,
        step: 0.1,
        unit: 'meter'
      },
      next: '4'
    },
    {
      id: '4',
      type: 'style',
      title: 'Stilarter',
      text: 'La oss vise deg noen stiler vi tror du kan like',
      options: [
        {
          id: '1',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/klassisk_1.jpg',
          tag: 'Klassisk'
        },
        {
          id: '2',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/klassisk_2.jpg',
          tag: 'Klassisk'
        },
        {
          id: '3',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/klassisk_3.jpg',
          tag: 'Klassisk'
        },
        {
          id: '4',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/minimalist_1.jpg',
          tag: 'Minimalist'
        },
        {
          id: '5',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/minimalist_2.jpg',
          tag: 'Minimalist'
        },
        {
          id: '6',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/minimalist_3.jpg',
          tag: 'Minimalist'
        },
        {
          id: '7',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/moderne_1.jpg',
          tag: 'Moderne'
        },
        {
          id: '8',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/moderne_2.jpg',
          tag: 'Moderne'
        },
        {
          id: '9',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/moderne_3.jpg',
          tag: 'Moderne'
        },
        {
          id: '10',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/nostalgi_1.jpg',
          tag: 'Nostalgi'
        },
        {
          id: '11',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/nostalgi_2.jpg',
          tag: 'Nostalgi'
        },
        {
          id: '12',
          url: 'https://rfinhouseallierdiag.blob.core.windows.net/tmp-allier-inspiration-images/nostalgi_3.jpg',
          tag: 'Nostalgi'
        }
      ],
      next: '5'
    },
    {
      id: '5',
      type: 'inventory',
      title: 'Baderommet ditt',
      text: 'Hva inneholder badet i dag?',
      options: [
        { id: '1', value: 'Toalett' },
        { id: '2', value: 'Badekar' },
        { id: '3', value: 'Dusj' },
        { id: '4', value: 'Boblebad' },
        { id: '5', value: 'Vaskemaskin' },
        { id: '6', value: 'Vask' },
        { id: '7', value: 'Tørketrommel' },
        { id: '8', value: 'Oppbevaring' },
        {
          id: '9',
          type: 'number',
          value: {
            label: 'Antall gulvsluk',
            min: 1,
            max: 5,
            default: 1,
            step: 1
          }
        }
      ],
      next: '6'
    },
    {
      id: '6',
      type: 'inventory',
      title: 'Drømmebadet ditt',
      text: 'Hva ønsker du at det nye badet skal inneholde?',
      options: [
        { id: '1', value: 'Toalett' },
        { id: '2', value: 'Badekar' },
        { id: '3', value: 'Dusj' },
        { id: '4', value: 'Boblebad' },
        { id: '5', value: 'Vaskemaskin' },
        { id: '6', value: 'Vask' },
        { id: '7', value: 'Tørketrommel' },
        { id: '8', value: 'Oppbevaring' },
        {
          id: '9',
          type: 'number',
          value: {
            label: 'Antall gulvsluk',
            min: 1,
            max: 5,
            default: 1,
            step: 1
          }
        }
      ],
      next: '7'
    },
    {
      id: '7',
      type: 'package',
      title: 'Estimat',
      text: 'Velg det som passer for deg!',
      options: [
        {
          id: '1', title: 'Flott', theme: { color: '#524F4F' }, next: '8'
        },
        {
          id: '2', title: 'Flottere', theme: { color: '#439551' }, next: '9'
        },
        {
          id: '3', title: 'Flottest', theme: { color: '#FFC534' }, next: '10'
        }
      ]
    },
    {
      id: '8',
      type: 'packageSummary',
      title: 'Du har valgt flott',
      text: 'Du er et skritt nærmere drømmebadet ditt',
      description: 'Hva skal stå her?',
      next: '11'
    },
    {
      id: '9',
      type: 'packageSummary',
      title: 'Du har valgt flottere',
      text: 'Du er et skritt nærmere drømmebadet ditt',
      description: 'Hva skal stå her?',
      next: '11'
    },
    {
      id: '10',
      type: 'packageSummary',
      title: 'Du har valgt flottest',
      text: 'Du er et skritt nærmere drømmebadet ditt',
      description: 'Hva skal stå her?',
      next: '11'
    },
    {
      id: '11',
      type: 'singleChoice',
      title: 'Boligtype',
      text: 'Hvilken type bolig har du?',
      options: [
        { id: '1', value: 'Enebolig' },
        { id: '2', value: 'Rekkehus/generasjonsbolig' },
        { id: '3', value: 'Boligblokk' }
      ],
      next: '12'
    },
    {
      id: '12',
      type: 'number',
      title: 'Etasje',
      text: 'Hvilken etasje er baderommet plassert i?',
      value: {
        label: 'Etasje',
        min: 0,
        max: 20,
        default: 1,
        step: 1
      },
      next: '13'
    },
    {
      id: '13',
      type: 'slider',
      title: 'Alder på baderommet',
      text: 'Når ble badet bygget/sist oppusset?',
      description: 'Om du ikke vet, velg ca årstall.',
      value: {
        label: 'Årstall',
        min: 1990,
        max: 2020,
        default: 2000,
        step: 1
      },
      next: '14'
    },
    {
      id: '14',
      type: 'singleChoice',
      title: 'Plassering',
      text: 'Skal ny innredning og utstyr plasseres tilsvarende som i dag?',
      options: [
        { id: '1', value: 'Ja', next: '16' },
        { id: '2', value: 'Ønsker vurdering', next: '16' },
        { id: '3', value: 'Nei' }
      ],
      next: '15'
    },
    {
      id: '15',
      type: 'text',
      title: 'Beskriv plassering',
      text: 'Beskriv ønsket ny plassering',
      value: {
        rows: 8,
        max: 300,
        placeholder: 'Beskrivelse (maks 300 tegn)'
      },
      next: '16'
    },
    {
      id: '16',
      type: 'singleChoice',
      title: 'Tilstøtende rom eller skap',
      text: 'Skal deler av tilstøtende rom eller skap inngå i det nye baderommet?',
      options: [
        { id: '1', value: 'Ja' },
        { id: '2', value: 'Ønsker vurdering', next: '19' },
        { id: '3', value: 'Nei', next: '19' }
      ],
      next: '17'
    },
    {
      id: '17',
      type: 'area',
      title: 'Gulvareal',
      text: 'Hvor mange kvadratmeter gulv i tilstøtende rom eller skap inngår i det nye baderommet?',
      value: {
        label: 'Areal',
        min: 1,
        max: 20,
        default: 8,
        step: 1,
        unit: 'm²'
      },
      next: '18'
    },
    {
      id: '18',
      type: 'slider',
      title: 'Vegger',
      text: 'Hvor mange løpemeter vegg i tilstøtende rom eller skap inngår i det nye baderommet?',
      description: 'Hjelpetekst?',
      value: {
        label: 'Løpemeter',
        min: 1,
        max: 50,
        default: 12,
        step: 1,
        unit: 'meter'
      },
      next: '19'
    },
    {
      id: '19',
      type: 'singleChoice',
      title: 'Skråtak',
      text: 'Har baderommet ditt skråtak?',
      options: [
        { id: '1', value: 'Ja' },
        { id: '2', value: 'Nei' }
      ],
      next: '20'
    },
    {
      id: '20',
      type: 'number',
      title: 'Dører',
      text: 'Hvor mange dører har baderommet ditt?',
      value: {
        label: 'Antall dører',
        min: 1,
        max: 5,
        default: 1,
        step: 1
      },
      next: '21'
    },
    {
      id: '21',
      type: 'number',
      title: 'Dører',
      text: 'Hvor mange dører skal det nye baderommet ha?',
      value: {
        label: 'Antall dører',
        min: 1,
        max: 5,
        default: 1,
        step: 1
      },
      next: '22'
    },
    {
      id: '22',
      type: 'number',
      title: 'Vindu',
      text: 'Hvor mange vinduer har badet?',
      value: {
        label: 'Antall vinduer',
        min: 0,
        max: 5,
        default: 0,
        step: 1
      },
      nextValues: {
        0: '24'
      },
      next: '23'
    },
    {
      id: '23',
      type: 'singleChoice',
      title: 'Lister',
      text: 'Ønsker du bad uten vinduslister?',
      options: [
        { id: '1', value: 'Ja' },
        { id: '2', value: 'Ønsker vurdering' },
        { id: '3', value: 'Nei' }
      ],
      next: '24'
    },
    {
      id: '24',
      type: 'singleChoice',
      title: 'Ventilasjon',
      text: 'Er det behov for ny ventilasjon på baderommet ditt?',
      options: [
        { id: '1', value: 'Ja' },
        { id: '2', value: 'Ønsker vurdering' },
        { id: '3', value: 'Nei' }
      ],
      next: '25'
    },
    {
      id: '25',
      type: 'estimate',
      title: 'Estimat',
      text: 'Velg det som passer for deg!',
      next: '26'
    },
    {
      id: '26',
      type: 'registration',
      title: 'Registrering',
      text: 'Registrer deg for å motta tilbud fra oss'
    }
  ]
};

const getEstimateProgressUrl = () => (
  `${config.apiHost}/api/estimates/guide/progress`
);

const estimate = {
  getEstimateGuide: () => (
    Promise.resolve(guide)
  ),

  getEstimateProgress: userId => (
    httpGet(`${getEstimateProgressUrl()}?userId=${userId}`)
      .then(jsonOrThrow)
  ),

  createEstimateProgress: progress => (
    httpPost(getEstimateProgressUrl(), progress)
      .then(jsonOrThrow)
  ),

  updateEstimateProgress: progress => (
    httpPut(`${getEstimateProgressUrl()}/${progress.id}`, progress)
      .then(throwOnError)
  )
};

export default estimate;
