import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { useAuth, isEmployee } from 'services/auth';
import { sanity } from 'services/sanity';
import { allierApi, sanityApi } from 'services';
import { styled, getStyle, responsive } from 'style';
import { BlockContent, BackButton, PhaseStepper, AccordionWidget, FieldList } from 'components/elements';
import {
  Article, ExpandablePost, BlogPost, DocumentPost
} from './components';

import { DoubleCheck } from 'assets/icons';


const serializers = {
  marks: {
    anchorTagDestination: props => (
      <a id={`${props.mark._key}`}>
        {props.children[0]}
      </a>
    ),
    expandable: props => (
      <ExpandablePost props={props} />
    )
  }
};

const StyledBlock = styled.div`
  background: ${getStyle('color.background.light')};
  margin: ${getStyle('spacing.lg')} 0;
  padding: ${getStyle('spacing.sm')};
  border-radius: 10px;
  cursor: pointer;
`;

const CheckList = ({ post }) => (
  <div>
    <AccordionWidget icon={<DoubleCheck />} title={post.title} wide>
      <PhaseStepper
        key={post._key}
        phases={post.checklist
          .map(item => ({ id: item._key, title: item.title, blocks: item.description }))}
        wide
        forceMobile
      />
      <StyledBlock>
        <BlockContent
          blocks={post.body}
          projectId={sanity.clientConfig.projectId}
          dataset={sanity.clientConfig.dataset}
          serializers={serializers}
        />
      </StyledBlock>
    </AccordionWidget>
  </div>
);

CheckList.propTypes = {
  post: PropTypes.object.isRequired
};

const IntroContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  ${props => responsive('grid-template-columns', [
  {
    width: getStyle('screen.sm', props),
    value: props.single ? '100%' : ' 1fr 33%;'
  }
])}
  ${props => responsive('display', [
  {
    width: getStyle('screen.sm', props),
    value: 'grid;'
  }
])}
`;

const StyledAccordian = styled(AccordionWidget)`
    margin-bottom: ${getStyle('spacing.lg')};
`;

const RightPadding = styled.div`
    padding-right: 1.8rem;
`;

const StaticPage = ({ postData }) => {
  const menuItems = postData.body.filter(d => d.markDefs && d.markDefs.length > 0).flatMap(item => item.markDefs).filter(item => item._type === 'anchorTagDestination');
  return (
    <IntroContainer single={menuItems.length === 0 && postData.files === undefined}>
      <RightPadding>
        <BlockContent
          blocks={postData.body}
          projectId={sanity.clientConfig.projectId}
          dataset={sanity.clientConfig.dataset}
          serializers={serializers}
        />
      </RightPadding>
      <div>
        {menuItems.length > 0 && (
          <StyledAccordian title="Innhold" wide>
            <FieldList
              fields={menuItems.filter(item => item.menu && item.menu.length > 0).map(item => ({
                type: 'bookmark', label: item.menu, value: `#${item._key}`
              }))}
            />
          </StyledAccordian>
        )}
        {postData.files && (
          <StyledAccordian title="Dokumenter" wide>
            <FieldList
              fields={postData.files.map(item => ({
                type: 'bookmark', label: item.asset.originalFilename, value: `${item.asset.url}`, target: '_blank'
              }))}
            />
          </StyledAccordian>
        )}
      </div>
    </IntroContainer>
  );
};

StaticPage.propTypes = {
  postData: PropTypes.object.isRequired
};

const Category = () => {
  let params = useParams();
  const { userId, user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [category, setCategory] = useState(null);
  const [borettslag, setBorettslag] = useState(null);
  const [associationId, setAssociationId] = useState("");

  useEffect(() => {
    const employee = isEmployee(user);
    if (!employee) {
      allierApi.getUserProfile(userId).then(profile => {
      setProfile(profile);
      sanityApi.fetchAssociationAndPostForCategory(profile.association.sanityId, params.slug).then(res => {
        setCategory(res.category);
        setBorettslag(res.borettslag);
      });
    });
    } else {
      const urlParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let associationId = urlParams.association_id;
      setAssociationId(associationId);
      sanityApi.fetchAssociationAndPostForCategory(associationId, params.slug).then(res => {
        setCategory(res.category);
        setBorettslag(res.borettslag);
      });
    }
  }, [userId, params.slug]);

  if (category == null || borettslag == null) {
    return <></>;
  }

  return (
    <>
      <BackButton url={`/borettslag?association_id=${associationId}`}>
        Tilbake til mitt borettslag
      </BackButton>
      <Article
        profile={profile}
        title={category.title}
        subtitle={category.subtitle}
        text={category.description}
        borettslag={borettslag}
        imageUrl={(category.mainImage ? category.mainImage.asset.url : borettslag.image.asset.url)}
        showContact={category.showContact}
      >
        {category.posts.map(post => {
          if (post.render === 'chat') {
            return (
              //TODO: <CustomerChatWidget key={post.title} customerId={profile.id} />
              <></>
            );
          }
          if (post.render === 'checklist') {
            return <CheckList key={post.title} post={post} />;
          }
          if (post.render === 'documentblog') {
            return (<DocumentPost key={post.title} post={post} wide />);
          }
          if (category.usage === 'blog') {
            return (<BlogPost key={post.title} post={post} sanity={sanity} wide />);
          }
          return (<StaticPage key={post.title} postData={post} />);
        })}
      </Article>
    </>
  );
}

export default Category;
