import { useDisclosure } from '@chakra-ui/core';

export { default as Dialog } from './Dialog';
export { default as DialogActions } from './DialogActions';
export { default as ConfirmDialog } from './ConfirmDialog';

export const useDialog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return {
    isDialogOpen: isOpen,
    openDialog: onOpen,
    closeDialog: onClose
  };
};
