import { styled, getStyle } from 'style';

const FieldError = styled.label`
  font-size: ${getStyle('font.md')};
  color: ${getStyle('color.status.error')};
  margin-top: 0.2rem;
  min-height: 1rem;
`;

FieldError.defaultProps = {
  variant: 'error'
};

export default FieldError;
