import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'assets/icons';
import { Cell, ColumnHeader, TableRow, Button, Table, TableHeader } from 'components/elements';
import { BikeLoader } from 'components/loading';
import { styled } from 'style';

const RightContent = styled.div`
    margin-left: auto;
    margin-right: 0;
    float: right;
`;

const HiddenRightContent = styled.div`
    visibility: hidden;
`;

const CustomersTable = ({ customers, loadMore, hasMore }) => {
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  const navigate = useNavigate();
  const onClick = useCallback(({ currentTarget }) => {
    const { customerId } = currentTarget.dataset || {};
    if (!customerId) return;
    navigate(`/accountmanager/customer/${customerId}`, {  });
  }, [navigate]);

  const targetElementRef = useCallback((node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoading(true);
          loadMore().then(() => setLoading(false));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, loadMore]
  );

  return (
    <Table>
      <TableHeader>
        <ColumnHeader>Navn</ColumnHeader>
        <ColumnHeader>Adresse</ColumnHeader>
        <ColumnHeader style={{textAlign:'center'}}>Borettslag</ColumnHeader>
        <ColumnHeader width="10%">
          <HiddenRightContent>
            <Button variant="inline" aria-label="Meny">
              <ArrowRight />
            </Button>
          </HiddenRightContent>
        </ColumnHeader>
      </TableHeader>
      {
        customers.map((customer, index) => (
          <TableRow
            key={customer.id}
            onClick={onClick}
            data-customer-id={customer.id}
            ref={customers.length - 5 === index ? targetElementRef : () => {}}
          >
            <Cell>{`${customer.givenName} ${customer.familyName}`}</Cell>
            <Cell>{customer.address.streetAddress} {customer.address.dwellingNumber ? ' - ' + customer.address.dwellingNumber : ''}</Cell>
            <Cell>{customer.association.title}</Cell>
            <Cell talign="right" width="10%">
              <RightContent>
                <Button variant="inline" aria-label="Meny">
                  <ArrowRight />
                </Button>
              </RightContent>
            </Cell>
          </TableRow>
        ))
      }
      {
        loading ? <BikeLoader type="Spinner" /> : null
      }
    </Table>
  );
}

CustomersTable.defaultProps = {
  customers: [],
  loadMore: () => {},
};

CustomersTable.prototype = {
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  loadMore: PropTypes.func,
};

export default CustomersTable;
