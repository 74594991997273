import { styled, getStyle } from 'style';

const Badge = styled.span`
  color: ${getStyle('color.badge.info')};
  background: ${getStyle('color.badge.background.info')};
  border-radius: ${getStyle('border.radius.sm')};
  padding: ${getStyle('spacing.xs')} ${getStyle('spacing.sm')};
  font-size: ${getStyle('font.sm')};
  font-weight: bold;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
`;

export default Badge;
