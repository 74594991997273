import { getTokenSilently } from 'services/auth';
import { HubConnectionBuilder,HttpTransportType } from "@microsoft/signalr";
import config from 'config';

let _connection;
export const isConnected = () => (
    _connection && _connection.connectionState === 'Connected'
);

export const isConnecting = () => (
  _connection && _connection.connectionState === 'Connecting'
);


const messageHub = {
  connect : async (setIsNewNotification) => {
    if (isConnected()) {
      console.log("Message hub connected, no need to connect again")
      return;
    }
    if(isConnecting()){
      console.log("Message hub s connecting, no need to connect again")
      return;
    }
    console.log("connecting...");
    _connection = new HubConnectionBuilder()
        .withUrl(config.messageHub, {
          accessTokenFactory: getTokenSilently,
          // Couldn't get negotiation to work with CORS,
          // but we probably only want websocket anyway.
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true
        })
        .withAutomaticReconnect()
        .build();
    await _connection.start().then(() => console.log("Connection state", _connection.state));

    _connection.on('notification', data => {
      setIsNewNotification(true);
    });
  }
}
export default messageHub;
