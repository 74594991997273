import PropTypes from 'prop-types';
import { Input, Label, TextArea } from 'components/elements';
import FieldContainer from './FieldContainer';
import FieldError from './FieldError';

const FormField = ({
  name,
  label,
  value,
  error,
  type = 'text',
  hidden,
  wide,
  onBlur,
  ...props
}) => {
  const InputElement = type === 'textarea' ? TextArea : Input;

  return (
    <FieldContainer aria-hidden={hidden} hidden={hidden} wide={wide}>
      <Label primary htmlFor={name}>{label}</Label>
        <InputElement
          variant="form"
          value={value}
          name={name}
          type={type}
          error={error}
          {...props}
        />
      <FieldError>{error}</FieldError>
    </FieldContainer>
  );
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  hidden: PropTypes.bool,
  wide: PropTypes.bool
};

export default FormField;
