import PropTypes from 'prop-types';
import { BathtubIcon } from 'assets/icons';
import { Grid, StyledAccordian } from './Style';

const ContainerWidget = ({
  columns, title, children, radius, justifyItems, height, mobile, className
}) => (
  <StyledAccordian
    className={className}
    title={title}
    icon={<BathtubIcon />}
    radius={radius}
    mobile={mobile}
    wide
  >
    <Grid columns={columns} ji={justifyItems} height={height}>
      {children}
    </Grid>
  </StyledAccordian>
);

ContainerWidget.propTypes = {
  columns: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  radius: PropTypes.bool,
  justifyItems: PropTypes.string,
  height: PropTypes.string,
  mobile: PropTypes.string,
  className: PropTypes.string
};

export default ContainerWidget;
