/* eslint-disable quotes */
import PropTypes from 'prop-types';
import { sanity, imageUrlFor } from 'services/sanity';
import { styled, getStyle, responsive } from 'style';
import { BlockContent, WidgetContainer } from 'components/elements';
import { ImageContainer } from './Style';
import UnikLogo from 'assets/logos/unik.png';

import ContactWidget from './ContactWidget';

const Title = styled.div`
  color: ${getStyle('color.widget.header')};
  font-size: ${getStyle('font.heading.xxl')};
  margin: ${getStyle('spacing.xxxl')} 0;
  line-height: 2rem;
  span:first-child {
    font-weight: normal;
    color: ${getStyle('color.primary')};
  }
  img {
    height: 1.5rem;
    float: right;
    margin-right: ${getStyle('spacing.md')};
  }
`;

const Ingress = styled.div`
  color: ${getStyle('color.widget.header')};
  font-size: ${getStyle('font.heading.xl')};
  margin: ${getStyle('spacing.lg')} 0;
  line-height: 2rem;
  span:first-child {
    font-weight: normal;
    color: ${getStyle('color.primary')};
  }
`;

const Container = styled.div`
  margin: 0 0 ${getStyle('spacing.xxxl')} 0;
`;

const IntroContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  ${props => responsive('grid-template-columns', [
  {
    width: getStyle('screen.sm', props),
    value: props.showContact ? ' 1fr 33%' : '100%'
  }
])}
`;

const IntroTextContainer = styled.div`
  margin-top:0;
  margin-right: ${getStyle('spacing.xl')};
  margin-bottom: ${getStyle('spacing.xl')};
`;

const StyledImg = styled.img`
display: none;
${props => responsive('display', [
  {
    width: getStyle('screen.sm', props),
    value: 'block'
  }
])}
`;

const Article = ({
  title, subtitle, text, ingress, borettslag, imageUrl, contacts, children
}) => (
  <>
    <Container>
      <ImageContainer height="50vh" radius="true" url={imageUrlFor(imageUrl).url()} alt="Bilde av borettslag" />
      <Title>
        {title}
        <StyledImg src={UnikLogo} alt="Unik" title="Unik" />
      </Title>
      <IntroContainer showContact={contacts && contacts.length > 0}>
        <IntroTextContainer>
          <Ingress>
            {subtitle}
          </Ingress>
          {ingress && (
            <BlockContent
              blocks={ingress}
              projectId={sanity.clientConfig.projectId}
              dataset={sanity.clientConfig.dataset}
            />
          )}
          {text}
        </IntroTextContainer>
        {(contacts && contacts.length > 0 && borettslag) &&
          <ContactWidget contacts={contacts} borettslag={borettslag} />}
      </IntroContainer>
    </Container>
    <WidgetContainer>
      {children}
    </WidgetContainer>
  </>
);

Article.propTypes = {
  profle: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  ingress: PropTypes.array,
  text: PropTypes.string,
  borettslag: PropTypes.object,
  imageUrl: PropTypes.string,
  children: PropTypes.array.isRequired,
  showContact: PropTypes.bool
};

Article.defaultProps = {
  showContact: true
};

export default Article;
