import React, { Fragment } from 'react';
import { ArrowRight } from 'assets/icons';
import { styled } from 'style';
import { useInspirationNavigation } from './hooks';

const LinkContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: calc(100% - 2em);
  padding: 1em;
  background-color: rgba(196, 196, 196, 0.1);
  gap: 5px;
  margin: 0 1em;
  
  span {
    text-decoration: underline;
    cursor: pointer;
  }
  
  svg {
    width: 1.7em;
    transform: rotate(180deg);
    cursor: pointer;
    margin-right: .2em;
  }
`;

const BackLinks = ({ linkArray, pageTitle, isMobile }) => {
  const goToSlug = useInspirationNavigation();

  if (!linkArray?.length) return null;

  const lastLink = linkArray[linkArray.length - 1];
  return (
    <LinkContainer>
      <ArrowRight onClick={() => goToSlug(lastLink.link)}/>
      {
        !isMobile
          ? (
            linkArray.map(({ link, linkTitle }) => (
              <Fragment key={link}>
                <span onClick={() => goToSlug(link)}> {linkTitle || link}</span>
                <div>></div>
              </Fragment>
            )))
          : (
            <>
              <span onClick={() => goToSlug(lastLink.link)}> {lastLink.linkTitle || lastLink.link}</span>
              <div>></div>
            </>
          )
      }
      <div>{pageTitle}</div>
    </LinkContainer>
  )
};

export default BackLinks;