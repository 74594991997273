// order of this list should be the same as excel files columns and in the backend
export const CUSTOMER_FORM_FIELDS = [
  { name: 'givenName', label: 'Fornavn' },
  { name: 'familyName', label: 'Etternavn' },
  { name: 'email', label: 'Epostadresse' },
  { name: 'phone', label: 'Mobilnummer' },
  { name: 'streetAddress', label: 'Gateadresse' },
  { name: 'zipCode', label: 'Postnummer' },
  { name: 'city', label: 'Poststed' },
  { name: 'dwellingNumber', label: 'Bolignummer' },
  { name: 'electronicInvoice', label: 'Efaktura (ja/nei)' },
];

// comment out field if you wish to hide them
export const CUSTOMER_FORM_FIELDS_AFTER_IMPORT = [
  { name: 'importStatus', label: '', isIcon: true },
  { name: 'givenName', label: 'Fornavn' },
  { name: 'familyName', label: 'Etternavn' },
  { name: 'email', label: 'Epostadresse' },
  { name: 'phone', label: 'Mobilnummer' },
  { name: 'streetAddress', label: 'Gateadresse' },
  { name: 'zipCode', label: 'Postnummer' },
  { name: 'city', label: 'Poststed' },
  { name: 'dwellingNumber', label: 'Bolignummer' },
  { name: 'electronicInvoice', label: 'Efaktura (ja/nei)' },
];