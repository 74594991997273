import { styled, getStyle } from 'style';

const ButtonContainer = styled.div`
  > button {
    width: 100%;
    :not(:last-child) {
      margin-bottom: ${getStyle('spacing.xl')};
    }
  }
`;

export default ButtonContainer;
