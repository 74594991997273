import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled, getStyle, responsive } from 'style';
import { Navbar } from './navbar';
import Menu from './Menu';
import Sidebar from './Sidebar';
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'
import { Dialog } from 'components/dialog';
import { Button } from 'components/elements';

const PageContainer = styled.div`
  display: flex;
  min-height: calc(100vh - ${getStyle('size.header.height')});

  // background-image: url("/backgrounds/allier.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${getStyle('spacing.xxxl')};
  padding: 0 0.5rem;
  width: 100%;
  max-width: 100%;

  ${props => responsive('padding', [
  { width: getStyle('screen.sm', props), value: '0 1.5rem;' },
  { width: getStyle('screen.lg', props), value: '0 6rem;' }
])}

  ${props => responsive('max-width', [
  {
    width: getStyle('screen.sm', props),
    value: `calc(100% - 2 * ${getStyle('size.menu.width.sm', props)})`
  },
  {
    width: getStyle('screen.md', props),
    value: `calc(100% - 2 * ${getStyle('size.menu.width.md', props)})`
  }
])}
`;

const Content = styled.main`
  display: ${props => (props.isOverlayVisible ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  max-width: 90rem;

  ${props => responsive('display', [
  { width: getStyle('screen.sm', props), value: 'flex;' }
])}
`;

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  //console.log("fallback", error);
  const { pathname } = useLocation()
  const originalPathname = useRef(pathname)
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary()
    }
    return () => resetErrorBoundary();
  }, [pathname, resetErrorBoundary])
  useEffect(()=> () => resetErrorBoundary());
  return (
    <>
      <Dialog title="En feil oppstod" isOpen={isOpen} onClose={() => setIsOpen(false)} variant={'error'}>
        <div>{error.message.indexOf("409") > -1 ? 'Kunden finnes allerede' : error.message}</div>
        <Button style={{width: '100%'}} variant={'primary'} onClick={resetErrorBoundary}>Last siden på nytt</Button>
      </Dialog>
    </>
  )
}

const myErrorHandler = (error, info) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log("myErrorHandler", error);
}

const Page = ({ children, isNewNotification, setIsNewNotification }) => {
  const [isMenuVisible, showMenu] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const onCloseMenu = useCallback(() => {
    showMenu(false);
    setOverlayVisible(false);
  }, []);

  return (
    <>
      <Navbar
        setIsNewNotification={setIsNewNotification}
        isNewNotification={isNewNotification}
        isMenuVisible={isMenuVisible}
        showMenu={showMenu}
        overlayVisibilityChanged={setOverlayVisible}
      />
      <PageContainer>
        <Menu isOpen={isMenuVisible} closeMenu={onCloseMenu}/>
        <ContentContainer>
          <Content isOverlayVisible={isOverlayVisible}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
              onReset={() => {
                // reset the state of your app so the error doesn't happen again
              }}
            >
              {children}
            </ErrorBoundary>
          </Content>
        </ContentContainer>
        <Sidebar style={{ visibility: 'hidden' }} />
      </PageContainer>
      {/* <ActionFeedback /> */}
    </>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isNewNotification: PropTypes.bool,
  setIsNewNotification: PropTypes.func
};

Page.defaultProps = {
  isNewNotification: false,
  setIsNewNotification: () => { }
}

export default Page;
