import React, { useState, useEffect } from 'react';
import { AccordionWidget, Button } from '../../components/elements';
import { useErrorHandler } from 'react-error-boundary';
import { Dialog, useDialog } from 'components/dialog';
import { TrashIcon, EditBlack, SendIcon } from 'assets/icons';
import { allierApi } from 'services';
import { styled } from 'style';
import { ButtonContainer } from 'pages/documents/DocumentList';

const TagInput = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TagListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  border-radius: 5px;
  padding: 0 0.5rem;

  &:hover {
    background-color: #6750A570;
  }
`;

const StyledButton = styled.button`
  background-color: #6750A5;
  color: white;
  font-weight: bold;
  padding: 4px;
  border-radius: 3px;
`;

const UpdateButton = styled.button`
  margin: 2px 0 0 auto;
`;

const SendButton = styled.button`
  margin-left: auto;
  margin-right: 2px;
  width: 1.2rem;
  height: 1.2rem;
`;

const StyledInput = styled.input`
  margin: 0 1rem 0 2px;
  border-radius: 5px;
`;




const Tags = () => {
  const [tagList, setTagList] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [deleteTag, setDeleteTag] = useState('');
  const [updateTagInput, setUpdateTagInput] = useState('');
  const [showUpdateTagInputField, setShowUpdateTagInputField] = useState('');
  const [currentTagName, setCurrentTagName] = useState('');
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const handleError = useErrorHandler();

  const handleTagInput = (e) => {
    setNewTag(e.target.value);
  }

  const createTag = async (name, tag) => {
    if (name === '') {
      alert('Tag kan ikke være tom');
      return;
    }

    setNewTag('');

    if (tag?.some((t) => t.name.toLowerCase() === name.toLowerCase()))
      alert('Tag finnes allerede');
    else {
      const payload = {
        name: name,
      }
      await allierApi.createTag(payload).then(() => {
        allierApi.getTags().then((res) => {
          setTagList(res);
        });
      }).catch(handleError);
    }
  }

  const updateTag = async () => {
    if (updateTagInput === '') {
      setShowUpdateTagInputField('');
      alert('Tag kan ikke være tom');
      return;
    }
    const id = showUpdateTagInputField;
    const name = updateTagInput;
    const tagExist = tagList?.tags?.filter((t) => t.name === name).length > 0;
    tagExist && alert('Tag finnes allerede');

    const payload = {
      id: id,
      name: name,
    }
    if (!tagExist) {
      await allierApi.updateTag(payload).catch(handleError);
    }
    setShowUpdateTagInputField('');
    allierApi.getTags().then((res) => {
      setTagList(res);
    });
  }

  const removeTag = async (tagId) => {
    await allierApi.deleteTag(tagId).then(() => {
      allierApi.getTags().then((res) => {
        setTagList(res);
      });
    }).catch(handleError);
  }

  useEffect(() => {
    allierApi.getTags().then((res) => {
      setTagList(res);
    });
  }, []);

  return (
    <>
      <Dialog
        title="Slette tag?"
        isOpen={isDialogOpen}
        onClose={closeDialog}
        wide
      >
        <div>Er du sikker på du vil slette tag: {deleteTag?.name}, alle filer som har tag: {deleteTag?.name} vil miste denne taggen. </div>
        <ButtonContainer>
          <Button variant="primary" onClick={() => { closeDialog(); removeTag(deleteTag.id); }}>Ja</Button>
          <Button variant="secondary" onClick={() => closeDialog()}>Nei</Button>
        </ButtonContainer>
      </Dialog>
      <AccordionWidget title="Tags" wide collapsed>
        <TagInput>
          <StyledButton onClick={() => createTag(newTag, tagList.tags)}>Opprett</StyledButton>
          <StyledInput type="text" name="name" value={newTag} onChange={(e) => handleTagInput(e)} />
        </TagInput>

        <div>
          {tagList?.tags
            ? tagList.tags.map((tag) => (
              <div key={tag.id}>
                <TagListItem>
                  {tag.name}
                  {showUpdateTagInputField !== tag.id ? (
                    <>
                      <UpdateButton variant="inline" onClick={() => { setShowUpdateTagInputField(tag.id); setCurrentTagName(tag.name) }}><EditBlack /></UpdateButton>
                    </>
                  ) : (
                    <>
                      <SendButton onClick={() => updateTag()}><div><SendIcon /></div></SendButton>
                      <StyledInput
                        name="updateTag"
                        value={currentTagName}
                        placeholder="Nytt navn..."
                        onChange={(e) => {
                          setCurrentTagName(e.target.value);
                          setUpdateTagInput(e.target.value);
                        }}
                      />
                    </>
                  )}
                  <Button variant="inline" onClick={() => { setDeleteTag({ id: tag?.id, name: tag?.name }); openDialog() }}><TrashIcon /></Button>


                </TagListItem>
              </div>
            ))
            : null}
        </div>
      </AccordionWidget>
    </>
  );
};

export default Tags;
