import PropTypes from 'prop-types';
import { styled, getStyle, responsive } from 'style';

const Widget = styled.section`
  display: inline-block;
  background: ${getStyle('color.background.primary')};
  box-shadow: ${getStyle('color.background.shadow')};
  max-height: ${props => (props.isExpanded ? 'none' : getStyle('size.widget.header.height', props))};
  width: 100%;

  ${props => responsive('width', [
    {
      width: getStyle('screen.sm', props),
      value: props.wide ? '100%' : `calc(50% - ${getStyle('spacing.lg', props)})`
    }
  ])}
`;

Widget.propTypes = {
  isExpanded: PropTypes.bool,
  wide: PropTypes.bool
};

Widget.defaultProps = {
  isExpanded: true
};

export default Widget;
