import PropTypes from 'prop-types';

const Match = props => {
  const render = props[props.value] || props.default;
  return render ? render() : null;
};

Match.propTypes = {
  value: PropTypes.string.isRequired
};

export default Match;
