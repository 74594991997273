const parseAsLocalTime = s => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(Date.parse(s) - offset);
};

export const getDateNow = () => (
  new Date(new Date().toDateString())
);

export const toLocaleDate = s => (
  parseAsLocalTime(s).toLocaleDateString('nb-no')
);

export const toLocaleDateTime = s => (
  parseAsLocalTime(s).toLocaleString('nb-no')
);

export const toLocaleDateStyle = (dateTimeString, style) => {
  var date = new Date(dateTimeString);
  return date?.toLocaleDateString([], {dateStyle: style});
};
