import { useRef, useCallback } from 'react';

/**
 * Returns a memoized function that will only call the passed function
 * when it hasn't been called for the wait period
 * @param callback {function} The function to be called
 * @param wait {number} Wait period after function hasn't been called for
 * @returns A memoized function that is debounced
 */
const useDebouncedCallback = (callback, wait = 500) => {
  const timeout = useRef();

  return useCallback((...args) => {
    const later = () => {
      clearTimeout(timeout.current);
      if (callback) callback(...args);
    };

    clearTimeout(timeout.current);
    timeout.current = setTimeout(later, wait);
  }, [callback, wait]);
};

export default useDebouncedCallback;
