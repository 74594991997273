import React, { useState, useRef } from 'react';
import { styled } from 'style';
import Dialog from '../dialog/Dialog';
import DialogActions from '../dialog/DialogActions';
import { Button, TextArea, Attachments } from 'components/elements';
import { allierApi } from 'services';

const ReportTextArea = styled(TextArea)`
  width: 100%;
  min-height: 200px;
`;

const ErrorReportDialog = ({ isOpen, handleCloseDialog, userId }) => {
    const [sendingErrorReport, setSendingErrorReport] = useState({
        beingSent: false,
        isSent: false,
      });
    const [errorReportText, setErrorReportText] = useState('');
    const [submittedReportStatus, setSubmittedReportStatus] = useState('');    

    const attachmentsRef = useRef();    

    const resetReport = () => {
        setErrorReportText('');
        handleCloseDialog();
    }

    const afterSendingReport = () => {
        setSubmittedReportStatus('');
        if (sendingErrorReport.isSent) {            
            resetReport();
        }
        setSendingErrorReport({
            beingSent: false,
            isSent: false,
        });        
    }

    const handleErrorReportInputChange = (inputValue) => {
        if (sendingErrorReport.isSent) {
          setSubmittedReportStatus("Skriv en kort beskrivelse av feilen");
        }
        setErrorReportText(inputValue);
    }

    const sendFeedback = () => {
        setSendingErrorReport({
          beingSent: true,
          isSent: false,
        });

        attachmentsRef.current.uploadAttachments(userId)
          .then(attachments => (
            allierApi.sendErrorReport({
              threadId: userId,
              senderId: userId,
              receiverId: "not_used",
              subject: "Feilrapport",
              text: errorReportText,
              type: 1,
              attachments: attachments,
            }))
            .then((res) => {
              if (res.ok) {
                setErrorReportText("");
                setSubmittedReportStatus("Takk for tilbakemeldingen!");
                setSendingErrorReport({
                  beingSent: false,
                  isSent: true,
                });
              } else {                                
                setSubmittedReportStatus(
                  "Noe gikk galt, vennligst prøv igjen eller kontakt administratoren"
                );
                setSendingErrorReport({
                  beingSent: false,
                  isSent: false,
                });
              }
          })
        ).catch(err => {            
            setSubmittedReportStatus(err.message);
        });
    };

    return (
        <>
            <Dialog 
                variant="default" 
                title='Rapporter om feil på nettsiden' 
                isOpen={isOpen && !submittedReportStatus}                 
                onClose={resetReport}
                wide={true}>
                <div>
                    <Attachments ref={attachmentsRef}></Attachments>
                    <ReportTextArea
                        aria-label='Feedback'
                        placeholder='Skriv en kort beskrivelse av feilen' 
                        onChange={(e) => handleErrorReportInputChange(e.target.value)}
                        value={errorReportText}
                        />
                </div>
                <DialogActions variant="default" style={{display:'flex', marginTop:'30px'}}>
                    <Button style={{marginRight: '10px'}} variant="secondary" onClick={resetReport} disabled={sendingErrorReport.beingSent}>Avbryt</Button>
                    <Button onClick={sendFeedback} style={{marginBottom: '1.25rem'}} disabled={sendingErrorReport.beingSent}>{sendingErrorReport.beingSent ? "Sender..." : "Send"}</Button>
                </DialogActions>
            </Dialog>            
            <Dialog 
                title={sendingErrorReport.isSent ? "Melding sendt" : "En feil oppstod"}
                isOpen={!!submittedReportStatus}
                onClose={afterSendingReport}
                variant={sendingErrorReport.isSent ? 'success' : 'error'}>
                <div>{submittedReportStatus && submittedReportStatus.toString()}</div>
                <Button style={{width: '100%'}} variant={'primary'} onClick={afterSendingReport}>Lukk</Button>
            </Dialog>
        </>
    )
};  

export default ErrorReportDialog;
