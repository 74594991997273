import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAuth, isEmployee } from 'services/auth';
import { allierApi } from 'services';
import { useErrorHandler } from 'react-error-boundary';
import { styled } from 'style';
import { WidgetContainer, AccordionWidget, Attachments, Button } from 'components/elements';
import { default as TagsDialog } from 'components/dialog/TagsDialog';
import { ReactComponent as DocumentsIcon } from 'assets/icons/documents.svg';
import { default as DocumentList } from '../documents/DocumentList';
import { Spinner } from 'components/loading';
import { SearchWidget } from 'components/elements/SearchWidget';
import { Dialog } from 'components/dialog';
import { CustomerSearchWidget } from 'components/elements/CustomerSearchWidget';
import { ReactComponent as Icon } from 'assets/icons/back.svg';
import { Label } from 'components/elements/Label';

const SquaredButton = styled(Button)`
  height: 50px;
  width: 15%;
  margin-right: 20px;
`;

const AnswerButton = styled(Button)`
  margin: 20px 5px 5px 5px;
`;

const TagDialogButton = styled(SquaredButton)`  
  width: 10%;  
`;

const BoldText = styled.div`  
  font-weight: bold;
`;

export const CheckedFilesButton = styled.button`  
  width: 8rem;
  height: 3rem;
  background-color: #6750A5;
  color: white;
  margin: 10px;
  border-radius: 5px;
  margin-top: 2rem;

  :disabled {
    background-color: #C4C4C4;
    color: #676767;
    cursor: not-allowed;
  }
`

const AttachmentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
`;

const AttachmentContainerHolder = styled.div`
  padding-top: 20px;
  border-top: 1px solid #00000050;
  border-bottom: 1px solid #00000050;
  margin-bottom: 20px;
`;

const MediabankPage = ({ userIsAccountManager }) => {
  const { userId, user } = useAuth();
  const handleError = useErrorHandler();

  //UseRef

  const attachmentsRef = useRef(null);

  //UseState

  const [attachmentCount, setAttachmentCount] = useState([]);

  const [checkedFiles, setCheckedFiles] = useState([]);
  const [checkedFilesTagsDialogOpen, setCheckedFilesTagsDialogOpen] = useState(false);
  const [chosenTags, setChosenTags] = useState([]);
  const [customerSearch, setCustomerSearch] = useState(false);

  const [deleteFilesFromMediabankDialogOpen, setDeleteFilesFromMediabankDialogOpen] = useState(false);
  const [deleteCheckedFilesTagsDialogOpen, setDeleteCheckedFilesTagsDialogOpen] = useState(false);
  const [deleteTags, setDeleteTags] = useState([]);
  const [deleteFilesDialogOpen, setDeleteFilesDialogOpen] = useState(false);
  const [distinctTags, setDistinctTags] = useState([]);
  const [distinctTagsListAvailableToDelete, setDistinctTagsListAvailableToDelete] = useState([]);

  const [files, setFiles] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [mediabankTagId, setMediabankTagId] = useState(null);

  const [searching, setSearching] = useState(false);
  const [searchFiles, setSearchFiles] = useState(null);
  const [sendFilesOpen, setSendFilesOpen] = useState(false);
  const [showOriginalList, setShowOriginalList] = useState(true);

  const [tagsDialogOpen, setTagsDialogOpen] = useState(false);

  //UseCallback

  const getMediabankTagId = useCallback(async () => {
    await allierApi.getTagId('Mediebank').then((res) => { setMediabankTagId(res) }).catch(handleError);
  }, [handleError]);

  const getFilesFromMediabank = useCallback(async (name) => {
    await allierApi.getMediabankFiles(name).then((file) => {
      setFiles(file.value);
    }).catch(handleError);
  }, [handleError]);

  //UseEffect

  useEffect(() => {
    getMediabankTagId();
  }, [getMediabankTagId]);

  useEffect(() => {
    updateCheckedFilesTags();
  }, [files]);

  useEffect(() => {
    getFilesFromMediabank("Mediebank");
  }, [getFilesFromMediabank]);

  useEffect(() => {
    setDistinctTagsListAvailableToDelete(distinctDeleteTags(checkedFiles.map(checkedFile => checkedFile.tags).flat()));
  }, [checkedFiles, files]);

  useEffect(() => {
    setDistinctTags(Array.from(getDistinctTagsFromFiles(files)).map(x => ({ id: x.id, name: x.name })));
  }, [files]);

  //Functions

  const updateCheckedFilesTags = () => {
    if (files && checkedFiles) {
      const checkedFilesWithTags = checkedFiles.map(checkedFile => {
        const file = files.find(file => file.id === checkedFile.file);
        return { ...checkedFile, tags: file.tags };
      });
      setCheckedFiles(checkedFilesWithTags);
    }
  }

  const upload = () => {
    if (attachmentsRef?.current?.getAttachmentCount() > 0) {
      setIsLoading(true);
      attachmentsRef.current.uploadAttachments(userId)
        .then(() => {
          attachmentsRef.current.clearAttachments();
          getFilesFromMediabank("Mediebank");
          setChosenTags([]);
          setIsLoading(false);
        });
    } else {
      alert("Du må velge en fil før du kan laste opp");
    }
  }

  const onCloseTags = (tags) => {
    setTagsDialogOpen(false);
  }

  const onCloseCheckedFilesTags = () => {
    setCheckedFilesTagsDialogOpen(false);
    setChosenTags([]);
  }

  const onCloseDeleteCheckedFilesTags = () => {
    setDeleteCheckedFilesTagsDialogOpen(false);
    setChosenTags([]);
  }

  const onConfirmTags = (tags) => {
    setChosenTags(tags.filter(tag => tag.documentTag));
    setTagsDialogOpen(false);
  }

  const handleSearchFiles = (files) => {
    setSearchFiles(files.map(x => x.id));
  }

  const handleSearching = (val) => {
    setSearching(val);
  }

  const handleAttachmentChange = (count) => {
    setAttachmentCount(count);
  };

  const isChecked = (id) => {
    return checkedFiles.some(checkedFile => checkedFile.file === id)
  }

  const handleCheckedFiles = (e, file) => {
    const checked = e.target.checked;
    if (checked) {
      setCheckedFiles([...checkedFiles, { file: file.id, tags: file.tags, name: file.name, createdAt: file.createdAt, id: file.id, createdBy: file.createdBy }]);
    } else {
      setCheckedFiles(checkedFiles.filter(checkedFile => checkedFile.file !== file.id));
    }
  }

  const resetCheckedFiles = () => {
    setCheckedFiles([]);
  }

  const handleDeleteTags = (tag, clearTags = false) => {
    if (clearTags) {
      setDeleteTags(deleteTags?.forEach(tag => tag.documentTag = false));
      return;
    }
    if (!Array.isArray(deleteTags)) {
      setDeleteTags([tag]);
      return;
    }
    setDeleteTags([...deleteTags, tag]);
  }

  const filesAllowedToDelete = () => {
    let allowedToDelete = [];
    let notAllowedToDelete = [];
    if (checkedFiles) {
      checkedFiles.forEach(checkedFile => {
        if (checkedFile.createdBy === userId) {
          allowedToDelete.push(checkedFile);
        } else {
          notAllowedToDelete.push(checkedFile);
        }
      });
    }
    return { allowedToDelete, notAllowedToDelete };
  }

  const { allowedToDelete, notAllowedToDelete } = filesAllowedToDelete();

  const allowedToDeleteHasFiles = () => {
    return allowedToDelete.length > 0;
  }

  const distinctDeleteTags = (list) => {
    const uniqueTagIds = new Set();
    const uniqueTags = [];

    list.forEach(tag => {
      if (!uniqueTagIds.has(tag.id)) {
        uniqueTagIds.add(tag.id);
        uniqueTags.push(tag);
      }
    });

    return uniqueTags;
  }

  const buttonDisabled = (num) => {
    return checkedFiles?.length < num;
  }

  const getDistinctTagsFromFiles = (files) => {
    const uniqueTagIds = new Set();
    const uniqueTags = [];

    files?.forEach(file => {
      file.tags.forEach(tag => {
        if (!uniqueTagIds.has(tag.id)) {
          uniqueTagIds.add(tag.id);
          uniqueTags.push(tag);
        }
      });
    });

    return uniqueTags;
  }

  const checkAllFiles = (e) => {
    e.target.checked ? setCheckedFiles(files.map(file => {
      return { file: file.id, tags: file.tags, name: file.name, createdAt: file.createdAt, id: file.id, createdBy: file.createdBy }
    }))
      : setCheckedFiles([]);
  }

  //Async functions

  const deleteFilesThenFetchNewFileList = async () => {
    await allierApi.deleteFiles(allowedToDelete.map(x => x.file));
    setDeleteFilesDialogOpen(false);
    setCheckedFiles([]);
    await getFilesFromMediabank('Mediebank');
  }

  const removeFilesFromMediabankThenFetchNewFileList = async () => {
    await allierApi.removeTagFromFiles(checkedFiles.map(x => { return { file: x.file, tags: [{ id: mediabankTagId.id, name: 'Mediebank' }] } }));
    setDeleteFilesFromMediabankDialogOpen(false);
    setCheckedFiles([]);
    await getFilesFromMediabank('Mediebank');
  }

  const onConfirmCheckedFilesTags = async tags => {

    setChosenTags(tags.filter(tag => tag.documentTag));

    let fileList = checkedFiles.map(checkedFile => {

      const combinedTags = [
        ...checkedFile.tags,
        ...tags.filter(tag => tag.documentTag)
      ];

      const uniqueId = new Set();
      const uniqueTags = combinedTags.filter(tag => {
        if (!uniqueId.has(tag.id)) {
          uniqueId.add(tag.id);
          return true;
        }
        return false;
      });

      setChosenTags([]);

      return {
        file: checkedFile.file,
        tags: uniqueTags
      };
    });

    await allierApi.updateTagsInFiles(fileList).then(() => getFilesFromMediabank("Mediebank")).catch(handleError);
    setCheckedFilesTagsDialogOpen(false);
  };

  const onConfirmDeleteCheckedFilesTags = async () => {
    let fileList = checkedFiles.map(checkedFile => { return { file: checkedFile.file, tags: deleteTags } });

    await allierApi.removeTagFromFiles(fileList)
    getFilesFromMediabank("Mediebank");

    setDeleteCheckedFilesTagsDialogOpen(false);
    setDeleteTags([]);
  };

  let displayedFiles;

  if (!userIsAccountManager || (files && searchFiles && searching)) {
    displayedFiles = files?.filter(file => searchFiles.includes(file.id));
  }

  //Props

  const documentListProps = {
    userId,
    writeAccess: isEmployee(user),
    userIsAccountManager,
    deleteFile: fileId => allierApi.deleteFile(fileId),
    onChange: () => getFilesFromMediabank("Mediebank"),
    getTags: fileId => allierApi.getTagsInFile(fileId),
    handleCheckedFiles: handleCheckedFiles,
    checkedFiles: checkedFiles,
    isChecked: isChecked,
    customerSearch: customerSearch,
    isMediabank: true,
  };

  return (
    <>
      <WidgetContainer>
        <AccordionWidget title="Mediebank" icon={<DocumentsIcon />} customContainer wide>
          {files && !sendFilesOpen ? <SearchWidget files={files} tags={distinctTags} handleSearchFiles={handleSearchFiles} handleSearching={handleSearching} /> : null}
          {!sendFilesOpen && showOriginalList ?
            <>
              <div style={{ marginLeft: "10px" }}>
                <CheckedFilesButton onClick={() => setCheckedFilesTagsDialogOpen(true)} disabled={buttonDisabled(2)}>Legg til tag</CheckedFilesButton>
                <CheckedFilesButton onClick={() => setDeleteCheckedFilesTagsDialogOpen(true)} disabled={buttonDisabled(2)}>Slett tag</CheckedFilesButton>
                <CheckedFilesButton onClick={() => setDeleteFilesDialogOpen(true)} disabled={buttonDisabled(2)}>Slett filer</CheckedFilesButton>
                <CheckedFilesButton onClick={() => { setDeleteFilesFromMediabankDialogOpen(true) }} disabled={buttonDisabled(2)}>Skjul filer</CheckedFilesButton>
                <CheckedFilesButton onClick={() => { setSendFilesOpen(true); setShowOriginalList(false); setCustomerSearch(true) }} disabled={buttonDisabled(1)}>Send filer</CheckedFilesButton>
              </div>
              <AttachmentContainerHolder>
                <Label style={{marginLeft: "20px"}}>Opplastning</Label>
                <AttachmentContainer title="Last opp dokument til mediebank">
                  <div style={{ width: '100%', display: 'flex' }}><Attachments ref={attachmentsRef} tags={chosenTags} mediabankTagId={mediabankTagId?.id} handleCountChange={handleAttachmentChange} /></div>
                  {isLoading && <Spinner style={{ marginTop: '10px', marginRight: '10px', height: '30px', width: '30px' }} />}
                  <TagDialogButton variant="secondary" onClick={() => setTagsDialogOpen(true)} disabled={attachmentCount.length === 0}>Tags</TagDialogButton>
                  <SquaredButton onClick={() => upload()} disabled={attachmentCount.length === 0} >Last opp</SquaredButton>
                </AttachmentContainer>
              </AttachmentContainerHolder>
              {files ? <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "48px" }}><input style={{ width: "20px", height: "20px" }} type="checkbox" onClick={(e) => checkAllFiles(e)} /></div> : null}
              {displayedFiles && displayedFiles.length > 0 ? (
                <DocumentList
                  {...documentListProps}
                  files={displayedFiles}
                />
              ) : null}

              {((!userIsAccountManager || files) && !displayedFiles) ? (<DocumentList
                files={files}
                userId={userId} writeAccess={isEmployee(user)}
                deleteFile={fileId => allierApi.deleteFile(fileId)}
                onChange={() => getFilesFromMediabank('Mediebank')}
                getTags={fileId => allierApi.getTagsInFile(fileId)}
                userIsAccountManager
                handleCheckedFiles={handleCheckedFiles}
                checkedFiles={checkedFiles}
                isChecked={isChecked}
                isMediabank
              />) : null}
            </> : null}
          {sendFilesOpen && !showOriginalList ? <>
            <button onClick={() => { setShowOriginalList(true); setSendFilesOpen(false); setCustomerSearch(false) }} style={{ display: "flex", padding: "1rem" }}>
              <Icon style={{ height: "20px", marginRight: "5px" }} />
              <div>Tilbake</div>
            </button>
            <div style={{ margin: "1rem 0 0 1rem", fontWeight: "bold" }}>Valgte filer</div>
            <DocumentList {...documentListProps} files={checkedFiles} sendFiles /></> : null}
          {customerSearch ? <CustomerSearchWidget files={checkedFiles} resetCheckedFiles={resetCheckedFiles} /> : null}
        </AccordionWidget>
      </WidgetContainer>
      <TagsDialog
        blockOpen={attachmentsRef?.current?.getAttachmentCount() < 1}
        blockText="Du må velge en fil før du kan sette tags"
        isDialogOpen={tagsDialogOpen}
        onCancel={onCloseTags}
        onConfirm={onConfirmTags}
        chosenTags={chosenTags}
      ></TagsDialog>
      <TagsDialog
        isDialogOpen={checkedFilesTagsDialogOpen}
        onCancel={onCloseCheckedFilesTags}
        onConfirm={onConfirmCheckedFilesTags}
        chosenTags={chosenTags}
      ></TagsDialog>
      <TagsDialog
        isDialogOpen={deleteCheckedFilesTagsDialogOpen}
        onCancel={onCloseDeleteCheckedFilesTags}
        onConfirm={onConfirmDeleteCheckedFilesTags}
        chosenTags={chosenTags}
        removeTags
        handleDeleteTags={handleDeleteTags}
        deleteTagsList={distinctTagsListAvailableToDelete}
      ></TagsDialog>
      <Dialog
        title="Slette filer?"
        isOpen={deleteFilesDialogOpen}
        onClose={() => setDeleteFilesDialogOpen(false)}
        wide
        children={
          <>
            {
              allowedToDeleteHasFiles()
                ? <div><BoldText>Bekreft at du virkelig ønsker å slette disse filene:</BoldText>{allowedToDelete.map(x => <div key={x.file}>{x.name}</div>)}</div>
                : <div>Du kan kun slette filer du selv har opprettet</div>
            }
            {
              notAllowedToDelete && notAllowedToDelete.length > 0
                ? <div style={{ marginTop: '20px' }}>
                  <BoldText>Disse filene har du ikke tilgang til å slette og vil ikke slettes:</BoldText>
                  {notAllowedToDelete.map(x => <div key={x.file}>{x.name}</div>)}
                </div>
                : null
            }
            <div style={{ display: "flex" }}>
              <AnswerButton variant="primary" onClick={async () => {
                await deleteFilesThenFetchNewFileList();
              }
              }>
                {allowedToDeleteHasFiles() ? "Ja" : "Ok"}</AnswerButton>
              {allowedToDeleteHasFiles() ? <AnswerButton variant="secondary" onClick={() => setDeleteFilesDialogOpen(false)}>Nei</AnswerButton> : null}
            </div>
          </>
        }
      ></Dialog>
      <Dialog
        title="Fjerne filer fra mediebank?"
        isOpen={deleteFilesFromMediabankDialogOpen}
        onClose={() => setDeleteFilesFromMediabankDialogOpen(false)}
        wide
        children={
          <>
            <div><BoldText style={{ marginBottom: "10px" }}>Bekreft at du virkelig ønsker å fjerne disse filene fra mediebanken:</BoldText>{checkedFiles.map(x => <div key={x.file}>{x.name}</div>)}</div>
            <div style={{ display: "flex" }}>
              <AnswerButton variant="primary" onClick={async () => {
                await removeFilesFromMediabankThenFetchNewFileList();
              }
              }> Ja</AnswerButton>
              <AnswerButton variant="secondary" onClick={() => setDeleteFilesFromMediabankDialogOpen(false)}>Nei</AnswerButton>
            </div>
          </>
        }
      ></Dialog>
    </>
  )
}

export default MediabankPage;

MediabankPage.propTypes = {
  userIsAccountManager: PropTypes.bool
};


