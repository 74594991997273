import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Button } from 'components/elements';
import { useState } from 'react';
import { styled } from 'style';
import { max } from "ramda";

const UploadButtonContainer = styled.div`
  margin-bottom: 7px;

  span {
    position: absolute;
    color: red;
  }
`;

const UploadButton = ({
  accept, multiple, maxSize, files, onFilesChanged, children, ...props
}) => {
  const ref = useRef();
  const [unacceptableFileTypes, setUnacceptableFileTypes] = useState([]);
  const [unacceptableFileSizes, setUnacceptableFileSizes] = useState([]);

  const onClick = e => {
    e.persist();
    ref.current.click();
    setUnacceptableFileTypes([]);
    setUnacceptableFileSizes([]);
  };

  const onChange = () => {
    const filelist = Array.from(files);

    setUnacceptableFileTypes(Array.from(ref.current.files)
      .filter(file => !accept.split(',').includes(file.type))
      .map(file => file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length || file.name)));

    setUnacceptableFileSizes(Array.from(ref.current.files)
        .filter(file => file.size > maxSize)
        .map(file => file));

    Array.from(ref.current.files)
      .filter(file => accept.split(',').includes(file.type) && file.size <= maxSize)
      .forEach(file => {
        if (!filelist.find(f => f.name === file.name)) {
          filelist.push(file);
        }
      });

    // Clear input to allow re-selecting the same files later
    ref.current.value = null;

    onFilesChanged(filelist.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    }));
  };

  return (
    <UploadButtonContainer>
      <Button
        onClick={onClick}
        {...props}
      >
        {children}
        <input
          type="file"
          ref={ref}
          onChange={onChange}
          accept={accept}
          multiple={multiple}
          hidden
          aria-hidden
        />
      </Button>
      {unacceptableFileTypes.length > 0 ? <span>Filtype tillates ikke: {unacceptableFileTypes.toString()}</span> : ''}
      {unacceptableFileSizes.length > 0 ? <span>{`Filen er for stor. Maks størrelse er ${maxSize / 1000000}MB`}</span> : ''}
    </UploadButtonContainer>
  );
};

UploadButton.propTypes = {
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  files: PropTypes.array,
  onFilesChanged: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]).isRequired
};

UploadButton.defaultProps = {
  maxSize: 2 * 1024 ** 3,
  files: []
};

export default UploadButton;
