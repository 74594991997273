import PropTypes from 'prop-types';
import { styled, getStyle } from 'style';

import Icon from 'assets/icons/arrow_right.svg';

import Button from './Button';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > div:first-child {
    width: ${getStyle('size.icon.md')};
  }
  > span {
    flex-grow: 1;
  }
`;

const NavigationButton = ({ variant, children, ...props }) => (
  <Button variant={variant} {...props}>
    <Container>
      <div />
      <span>{children}</span>
      <Icon />
    </Container>
  </Button>
);

NavigationButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.string.isRequired
};

export default NavigationButton;
