import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { styled } from 'style';

const FullWidthVideo = styled.video`
  width: 100%;
`;

const Video = ({ alt, download, type }) => {
  const [url, setUrl] = useState();
  useEffect(() => {
    download()
      .then(sasUrl => {
        setUrl(() => sasUrl);
      });
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <FullWidthVideo key={url} alt={alt} controls>
      <source src={url} type={type} />
    </FullWidthVideo>
  );
};

Video.propTypes = {
  alt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  download: PropTypes.func.isRequired
};

export default Video;
