import * as Yup from 'yup';
import { maxLengthError } from 'components/form';

const validationSchema = Yup.object({
  givenName: Yup.string()
    .max(25, maxLengthError('Fornavn er for langt, maks {0} tegn.'))
    .required('* Fornavn er påkrevd'),
  familyName: Yup.string()
    .max(25, maxLengthError('Etternavn er for langt, maks {0} tegn.'))
    .required('* Etternavn er påkrevd'),
  streetAddress: Yup.string()
    .max(100, maxLengthError('Adresse er for lang, maks {0} tegn.'))
    .required('* Adresse er påkrevd'),
  zipCode: Yup.string()
    .max(4, maxLengthError('Postnummer er for langt, maks {0} tegn.'))
    .required('* Postnummer er påkrevd'),
  city: Yup.string()
    .max(100, maxLengthError('By eller sted er for langt, maks {0} tegn.'))
    .required('* By eller sted er påkrevd'),
  dwellingNumber: Yup.string()
    .matches(/^[h,k,l,u,H,K,L,U]+[0-9]{4}$/, 'Ugyldig bolignummer').transform(value => !value ? undefined : value)
    .matches(/^[h,k,l,u,H,K,L,U]+[0-9]{4}$/, 'Ugyldig bolignummer')
    .when("association", {
      is: (value) => !!value,
      then: Yup.string()
        .required("Bolignummer er påkrevd når en BRL er valgt")
    }),
  email: Yup.string()
    .max(100, maxLengthError('E-postadresse er for lang, maks {0} tegn.'))
    .email('Ugyldig e-postadresse')
    .required('* E-postadresse er påkrevd'),
  phone: Yup.string()
    // Restrictive regex for Norwegian numbers. Let's expand on and move it "somewhere" as needed.
    .matches(/^(0047|\+47|47)?[2-9]\d{7}$/, 'Ugyldig mobilnummer')
    .required('* Mobilnummer er påkrevd')
});

export default validationSchema;
