import React from 'react';
import { AccordionWidget } from 'components/elements';
import { DocumentsIcon } from 'assets/icons';
import { styled } from 'style';
import InspirationPageText from './InspirationPageText';

const BookletContainer = styled.div`
  width: ${({ elementType, isMobile }) => {
    switch (elementType) {
      case 'l-12-5': return '100%';
      case 'l-4-3': return '100%';
      case 'm-1-1': return isMobile ? '100%' : 'calc(50%  - .5em)';
      case 's-1-1': return isMobile ? 'calc(50%  - .5em)' : 'calc(25%  - .75em)';
      case 'm-3-4': return isMobile ? '100%' : 'calc(33.3%  - .665em)';
      default: return '100%';
    }
  }};
`;

const Booklet = styled.div`
  background-color: ${({ hasBookletUrl }) => hasBookletUrl ? '#f9f9f9' : ''};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  aspect-ratio: ${({ elementType }) => {
    switch (elementType) {
      case 'l-12-5': return '12/5';
      case 'l-4-3':
      case 'm-1-1':
      case 's-1-1': return '1/1';
      case 'm-3-4': return '3/4';
      default: return '16/9';
    }
  }};

  section {
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 1.5em 1em;
    margin: 1em;
  }
`;

const DownloadButton = styled.button`
  background-color: #6750A5;
  padding: 10px;
  color: white;
  border-radius: 7px;
`;

const BookletElement = ({ bookletUrl, linkTitle, bookletText, elementType, isMobile }) => (
  <BookletContainer elementType={elementType} isMobile={isMobile}>
    <Booklet elementType={elementType} isMobile={isMobile} hasBookletUrl={!!bookletUrl}>
      {
        bookletUrl
          ? (
            <AccordionWidget title="Idéhefte" icon={<DocumentsIcon />} wide>
              <InspirationPageText text={bookletText} />
              <DownloadButton>
                <a href={bookletUrl} download target="_blank" rel="noreferrer">{linkTitle}</a>
              </DownloadButton>
            </AccordionWidget>
          ) : null
      }
    </Booklet>
  </BookletContainer>
);

export default BookletElement;