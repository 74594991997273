import { useAuth0, clearUserRoles } from './AuthProvider';

const useAuth = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    isLoading
  } = useAuth0();

  return {
    isAuthenticated,
    login: () => loginWithRedirect({
      redirect_uri: `${window.location.origin}/dashboard`,
      ui_locales: 'nb'
    }),
    logout: () => {
      clearUserRoles();
      logout({
        returnTo: window.location.origin
      });
    },
    signup: ({ email } = {}) => loginWithRedirect({
      redirect_uri: `${window.location.origin}/dashboard`,
      login_hint: email,
      screen_hint: 'signup',
      ui_locales: 'nb'
    }),
    userId: user && user.sub,
    user,
    isLoading
  };
};

export default useAuth;
