import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { styled } from 'style';

const Container = styled.div`
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }

  .slick-track:before,
  .slick-track:after
  {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    min-height: 1px;
    height: 100%;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;

const Carousel = ({
  infinite,
  draggable,
  currentSlide,
  slidesToShow,
  slidesToScroll,
  beforeChange,
  afterChange,
  children
}) => {
  const ref = useRef();

  useEffect(() => { ref.current.slickGoTo(currentSlide); }, [currentSlide]);

  return (
    <Container>
      <Slider
        ref={ref}
        arrows={false}
        infinite={infinite}
        draggable={draggable}
        initialSlide={currentSlide}
        useTransform={false}
        speed={500}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        beforeChange={beforeChange}
        afterChange={afterChange}
      >
        {children}
      </Slider>
    </Container>
  );
};

Carousel.propTypes = {
  infinite: PropTypes.bool,
  draggable: PropTypes.bool,
  currentSlide: PropTypes.number,
  slidesToShow: PropTypes.number.isRequired,
  slidesToScroll: PropTypes.number.isRequired,
  beforeChange: PropTypes.func,
  afterChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired
};

Carousel.defaultProps = {
  infinite: false,
  draggable: true,
  currentSlide: 0
};

export default Carousel;
