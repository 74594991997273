import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth, isEmployee, roles, hasRole } from 'services/auth';
import { allierApi, sanityApi } from 'services';
import { Button } from 'components/elements';
import { styled, getStyle, responsive, useMediaQuery } from 'style';
import {
  HomeIcon,
  AssociationIcon,
  CustomersIcon,
  MessagesIcon,
  DocumentsIcon,
  BathtubIcon,
  ExclamationIcon,
  BusinessContactIcon,
  Bulb,
  ProfileIcon,
  Bank,
} from 'assets/icons';
import { PageOverlay } from './navbar';
import Sidebar from './Sidebar';
import DropdownMenu from 'components/elements/DropdownMenu';

const Overlay = styled(PageOverlay)`
  background: ${getStyle('color.background.primary')};
`;

const MenuItems = styled.nav`
  border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};

  ${props => responsive('border-bottom', [
  { width: getStyle('screen.sm', props), value: 'none' }
])}
  hr {
    border: none;
    border-bottom: 0.0625rem solid ${getStyle('color.border.theme')};
    width: 90%;
  }
`;

const ItemContainerSelected = styled.div`
  background: ${props => (props.isSelected ? getStyle('color.status.selected') : 'auto')};
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  width: 5px;
`;

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${getStyle('font.md')};
  background: ${props => (props.isSelected ? getStyle('color.status.selectedBackground') : 'auto')};
  stroke: ${props => (props.isSelected ? getStyle('color.status.selected') : getStyle('color.menu.icon'))};
  fill: ${props => (props.isSelected ? getStyle('color.status.selected') : getStyle('color.menu.icon'))};
  height: ${getStyle('size.element.minHeight')};
  padding-left: ${getStyle('spacing.lg')};
  margin-top: ${getStyle('spacing.xl')};
  margin-bottom: ${getStyle('spacing.xl')};
  cursor: pointer;

  :hover {
    background: ${props => (props.isSelected ? getStyle('color.status.selectedBackground') : getStyle('color.status.hover'))};
    cursor: pointer;
  }

  svg {
    height: ${getStyle('size.icon.md')};
    margin-right: ${getStyle('spacing.xl')};
  }

  ${props => responsive('padding-left', [
  { width: getStyle('screen.sm', props), value: `${getStyle('spacing.xl', props)}` },
  { width: getStyle('screen.md', props), value: `${getStyle('spacing.xxxl', props)}` }
])}
`;

const ItemText = styled.span`
  display: inline;
  pointer-events: none;

  ${props => responsive('display', [
  { width: getStyle('screen.sm', props), value: 'none' },
  { width: getStyle('screen.md', props), value: 'inline' }
])}
`;

const LogoutContainer = styled.div`
  margin: ${getStyle('spacing.xl')};
  button {
    width: 100%;
  }
`;

const MenuItem = ({ icon, href, label, openNewTab, closeMenu }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSelected = pathname.includes(href) || (href.includes('project') && pathname === '/project');
  const onClick = useCallback(() => {
    if (openNewTab) {
      window.open(href);
    } else {
      navigate(href);
      closeMenu();
    }
  }, [closeMenu, href, navigate, openNewTab]);

  return isSelected
    ? (
      <ItemContainer isSelected onClick={onClick}>
        <ItemContainerSelected isSelected />
        <div title={label}>{icon}</div>
        <ItemText>{label}</ItemText>
      </ItemContainer>
    )
    : (
      <ItemContainer onClick={onClick}>
        <div title={label}>{icon}</div>
        <ItemText>{label}</ItemText>
      </ItemContainer>
    );
};

MenuItem.propTypes = {
  icon: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  openNewTab: PropTypes.bool
};

const Menu = ({ isOpen, closeMenu }) => {
  const { userId, isAuthenticated, user, logout } = useAuth();
  const isMobile = useMediaQuery().isMobile();

  const isVisible = isAuthenticated && (isOpen || !isMobile);
  const Container = isMobile ? Overlay : Sidebar;

  const [project, setProject] = useState(null);
  const [idehefteUrl, setIdehefteUrl] = useState('');
  const [profile, setProfile] = useState(null);
  const [employee, setEmployee] = useState(false);
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      const employee = isEmployee(user);
      setEmployee(employee);
      if (!employee) {
        allierApi.getProjects(userId).then((res = []) => setProject(res[0]));
        allierApi.getUserProfile(userId).then((profile) => {
          setProfile(profile);
          sanityApi
            .fetchAssociationIdehefteUrl(profile)
            .then((url) => setIdehefteUrl(url.url));
        });
      }
      else allierApi.getAssociations().then((res) => { setAssociations(res); });
    }
  }, [isAuthenticated, user, userId]);

  if (employee === false && (profile == null)) {
    return <Container
      aria-hidden={!isVisible}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    />;
  }

  const navigateToAss = (selectedName) => {
    const selectedAssociation = associations.find(
      (ass) => ass.title === selectedName
    );
    const selectedAssId = selectedAssociation.sanityId;
    const href = `/borettslag?association_id=${selectedAssId}`;
    window.open(href);
  };

  return (
    <Container
      aria-hidden={!isVisible}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      <MenuItems aria-label="Meny">
        {employee
          ? (
            <>
              <MenuItem icon={<CustomersIcon />} href="/accountmanager/dashboard" label="Mine kunder" closeMenu={closeMenu} />
              <MenuItem icon={<MessagesIcon />} href="/accountmanager/messages" label="Meldinger" closeMenu={closeMenu} />
              <MenuItem icon={<DocumentsIcon />} href="/accountmanager/documents" label="Dokumenter" closeMenu={closeMenu} />
              <MenuItem icon={<Bank />} href="/mediabank" label="Mediebank" closeMenu={closeMenu} />
              <MenuItem icon={<ExclamationIcon />} href="/accountmanager/complaints" label="Reklamasjoner" closeMenu={closeMenu} />
              {hasRole(user, roles.admin) ? <MenuItem icon={<ProfileIcon/>} href="/admin" label="Admin" closeMenu={closeMenu} /> : ""}
              <MenuItem icon={<Bulb />} href="/inspiration" label="Inspirasjon" closeMenu={closeMenu} />
              <DropdownMenu icon={<AssociationIcon/>} initElement={"Borettslag"} elementList={associations} selectionHandler={navigateToAss}/>             
            </>
          )
          : (
            <>
              <MenuItem icon={<HomeIcon />} href="/dashboard" label="Hjem" closeMenu={closeMenu} />
              {project && <MenuItem icon={<BathtubIcon />} href={`/project/${project.id}`} label="Mitt baderom" closeMenu={closeMenu} />}
              {profile?.association?.id && <MenuItem icon={<AssociationIcon/>} href="/borettslag" label="Mitt borettslag" closeMenu={closeMenu}/>}
              <MenuItem icon={<MessagesIcon />} href="/messages" label="Meldinger" closeMenu={closeMenu} />
              <MenuItem icon={<DocumentsIcon />} href="/documents" label="Dokumenter" closeMenu={closeMenu} />
              <MenuItem icon={<BusinessContactIcon />} href="/contactinfo" label="Kontaktpersoner" closeMenu={closeMenu} />
              <MenuItem icon={<ExclamationIcon />} href="/complaints" label="Reklamasjoner" closeMenu={closeMenu} />
              <hr />
              <MenuItem icon={<DocumentsIcon />} href={`${idehefteUrl}`} label="Idéhefte" openNewTab closeMenu={closeMenu} />
              <MenuItem icon={<Bulb />} href="/inspiration" label="Inspirasjon" closeMenu={closeMenu} />
            </>
          )
        }
      </MenuItems>
      {isMobile && (
        <LogoutContainer>
          <Button onClick={logout}>Logg ut</Button>
        </LogoutContainer>
      )}
    </Container>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default Menu;
