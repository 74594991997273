import { httpGet, jsonOrThrow } from 'services/common';
import config from 'config';
import { httpPost, httpDelete, httpPut } from "./http";

const getTagsUrl = () => (
  `${config.apiHost}/api/tags`
);

const service = {
  getTags: () => (
    httpGet(getTagsUrl())
      .then(jsonOrThrow)
  ),

  createTag: (tag) => (
    httpPost(getTagsUrl(), tag)
      .then(jsonOrThrow)
  ),

  deleteTag: (tagId) => (
    httpDelete(`${getTagsUrl()}/${tagId}`)
      .then(jsonOrThrow)
  ),

  updateTag: (tag) => (
    httpPut(getTagsUrl(), tag)
      .then(jsonOrThrow)
  ),
};

export default service;
