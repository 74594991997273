import PropTypes from 'prop-types';
import { useState } from 'react';
import { Dialog, DialogActions } from 'components/dialog';
import { H3, Button } from 'components/elements';
import { styled, getStyle } from 'style';

import ProjectAttachment from './ProjectAttachment';

const Phase = styled.section`
  > h3 {
    font-weight: normal;
    border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};
    padding-bottom: ${getStyle('spacing.md')};
    margin-bottom: ${getStyle('spacing.md')};
  }

  > div {
    display: flex;
    flex-wrap: wrap;

    .selected {
      order: -1;
      margin-bottom: ${getStyle('spacing.xxxl')};
    }
  }
`;

const ProjAttachH3 = styled.h3`
font-size: ${getStyle('font.heading.md')};
font-weight: bold !important;
`

const ProjectAttachmentDialog = (
  {
    accountManagerPage, project, isOpen, onClose, onProjectEdit
  }
) => {
  const phases = project.phases
    .reduce((acc, phase) => ([
      ...acc,
      {
        ...phase,
        attachments: project.attachments.filter(a => a.phaseId === phase.id)
      }
    ]), [])
    .filter(phase => phase.attachments.length > 0);

  const [selectedAttachment, setSelectedAttachment] = useState();

  return (
    <Dialog
      title="Bilder fra prosjektet"
      isOpen={isOpen}
      onClose={onClose}
      wide
    >
      {phases.map(phase => (
        <Phase key={phase.id}>
          <ProjAttachH3>{phase.title}</ProjAttachH3>
          <div>
            {(
              project.attachments
                .filter(a => a.phaseId === phase.id)
                .map(attachment => {
                  const selected = attachment.fileId === selectedAttachment;
                  return (
                    <ProjectAttachment
                      accountManagerPage={accountManagerPage}
                      key={attachment.fileId}
                      attachment={attachment}
                      className={selected ? 'selected' : ''}
                      wide={selected}
                      onClick={e => {
                        e.persist();
                        setSelectedAttachment(
                          selectedAttachment !== attachment.fileId
                            ? attachment.fileId
                            : null
                        );
                        setTimeout(() => e.target.scrollIntoView(), 0);
                      }}
                      onProjectEdit={onProjectEdit}
                      onClose={onClose}
                    />
                  );
                })
            )}
          </div>
        </Phase>
      ))}
      <DialogActions>
        <Button variant="secondary" onClick={() => onClose()}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
};

ProjectAttachmentDialog.propTypes = {
  accountManagerPage: PropTypes.bool,
  project: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProjectEdit: PropTypes.func.isRequired
};

export default ProjectAttachmentDialog;
