/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { styled, getStyle } from 'style';

import ContentContainer from './ContentContainer';
import Widget from './Widget';

import BrandingLogo from 'assets/logos/unik.png';

const Title = styled.div`
  color: ${getStyle('color.widget.header')};
  font-size: ${getStyle('font.heading.lg')};
  font-weight: bold;
`;
const Subtitle = styled.div`
  color: ${getStyle('color.widget.subtitle')};
  font-size: ${getStyle('font.heading.sm')};
`;

const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Icon = styled.div`
  color: ${getStyle('color.widget.subtitle')};
  background: #AEA0CD;
  border-radius: 50%;
  padding: ${getStyle('spacing.sm')};
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: ${getStyle('spacing.md')};
  svg {
    stroke-width: 1.2;
    height:20px;
    width:20px;
  }
`;

const HeaderSection = ({
  title, subtitle, icon, showBranding
}) => (
  <Header>
    {(icon && title) && (<Icon>{icon}</Icon>)}
    <HeaderGroup>
      <Title>{title}</Title>
      {subtitle && (
        <Subtitle>
          {subtitle}
        </Subtitle>
      )}
    </HeaderGroup>
    {showBranding && <StyledImg src={BrandingLogo} alt="Unik" title="Unik" />}
  </Header>
);

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.object,
  showBranding: PropTypes.bool
};

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${getStyle('spacing.md')};

  color: ${getStyle('color.widget.header')};
  stroke: ${getStyle('color.widget.header')};
  fill: ${getStyle('color.widget.header')};

  height: ${getStyle('size.widget.header.height')};
`;

const StyledImg = styled.img`
  height: 1.5rem; 
  margin-left: auto;
  margin-right: ${getStyle('spacing.md')};
`;

const Content = styled.div`
  display: ${props => (props.isExpanded ? 'inherit' : 'none')};
  font-size: ${getStyle('font.md')};
  width: 100%;
  flex: 1;
  position: relative;
`;

const StyledWidget = styled(Widget)`
      border-radius: 10px;
`;

const AccordionWidget = ({
  title, subtitle, children, collapsible, collapsed, onExpanded, customContainer, wide, icon,
  showBranding,
  className
}) => {
  const [isExpanded, setExpanded] = useState(!collapsible || !collapsed);

  const onClick = () => {
    if (!isExpanded && onExpanded) {
      onExpanded();
    }
    setExpanded(!isExpanded);
  };

  return (
    <StyledWidget className={className} isExpanded={isExpanded} wide={wide}>
      {title &&
        <HeaderSection title={title} subtitle={subtitle} icon={icon} showBranding={showBranding} />}
      <Content isExpanded={isExpanded}>
        {(customContainer
          ? children
          : <ContentContainer>{children}</ContentContainer>
        )}
      </Content>
    </StyledWidget>
  );
};

AccordionWidget.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  onExpanded: PropTypes.func,
  customContainer: PropTypes.bool,
  wide: PropTypes.bool,
  icon: PropTypes.object,
  showBranding: PropTypes.bool,
  className: PropTypes.string
};

AccordionWidget.defaultProps = {
  collapsible: false,
  customContainer: false,
  showBranding: false
};

export default AccordionWidget;
