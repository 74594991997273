import { styled, getStyle, responsive } from 'style';

const ContentContainer = styled.div`
  margin-top: ${getStyle('spacing.xl')};
  padding: 0 ${getStyle('spacing.xxl')};
  width: 100%;
  ${props => responsive('padding', [
    { width: getStyle('screen.sm', props), value: `0 ${getStyle('spacing.xxl', props)}` }
  ])}

  > * {
    margin-bottom: ${getStyle('spacing.xl')};
  }
`;

export default ContentContainer;
