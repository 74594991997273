import config from 'config';
import { httpGet, httpPost, jsonOrThrow, throwOnError } from 'services/common';
import { httpPatch } from '../common/http';

const getMessagesUrl = () => (
  `${config.apiHost}/api/messages`
);

const getConversationsUrl = () => (
  `${getMessagesUrl()}/conversations`
);

const getMessageThreadUrl = threadId => (
  `${getMessagesUrl()}?threadId=${threadId}`
);

const messages = {
  getConversations: () => (
    httpGet(getConversationsUrl())
      .then(jsonOrThrow)
  ),

  getMessageThread: threadId => (
    threadId ? (
      httpGet(getMessageThreadUrl(threadId))
        .then(jsonOrThrow)
    ) : (
      Promise.resolve({ messages: [] })
    )
  ),

  sendMessage: message => (
    httpPost(getMessagesUrl(), message)
      .then(jsonOrThrow)
  ),

  sendErrorReport: message => (
    httpPost(getMessagesUrl() + "/sendfeedback", message)
      .then(res => res)
  ),

  getComplaints: userId => {
    return httpGet(`${getMessagesUrl()}/complaints/${userId ? userId : ''}`)
      .then(jsonOrThrow)
  },

  setComplaintStatus: (userId, messageId, status) => (
    httpPatch(`${getMessagesUrl()}/complaints/${userId}/${messageId}/${status}`)
      .then(throwOnError)
  ),

  getUsersHavingUnreadMessages: async () => (
    httpGet(`${getMessagesUrl()}/unread`)
      .then(jsonOrThrow)
  ),

  setMessagesUnread: (senderId, isRead) => (
    httpPatch(`${getMessagesUrl()}/setisread/${senderId}/${isRead}`)
      .then(throwOnError)
  )
};

export default messages;
