import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from 'services/auth';
import { allierApi, sanityApi } from 'services';
import { AccountManagerWidget, WrapContainer,  AccordionWidget, FieldList } from 'components/elements';
import { BikeLoader } from 'components/loading';
import { ProjectPhases, ProjectIdehefteWidget } from 'pages/projects';
import ProjectAttachmentWidget from './projectAttachment/ProjectAttachmentWidget';

import { BathtubIcon, KeyIcon } from 'assets/icons';

const ProjectPage = () => {

  const { userId } = useAuth();
  const [project, setProject] = useState(null);
  const [profile, setProfile] = useState(null);
  const [manager, setManager] = useState(null);
  const [ideHefteUrl, setIdeHefteUrl] = useState(null);
  let params = useParams();

  useEffect(() => {
    allierApi.getProject(params.projectId).then(res => setProject(res));
    allierApi.getUserProfile(userId).then(profile => {
      setProfile(profile);
      sanityApi.fetchAssociationIdehefteUrl(profile).then(url => {
        setIdeHefteUrl(url);
        //console.log(url);
      });
    });
    allierApi.getAccountManager(userId).then(manager => setManager(manager));
  }, [params.projectId, userId]);

  if (project == null || profile == null || manager == null ) {
    return <BikeLoader />;
  }

  return (
    <>
      <AccordionWidget icon={<BathtubIcon />} title="Mitt baderom" wide>
        <ProjectPhases
          phases={project.phases}
          currentPhaseId={project.currentPhaseId}
        />
        <FieldList
          fields={[
            { label: 'Adresse', value: `${profile.address.streetAddress} - ${profile.address.dwellingNumber}`, icon: <KeyIcon /> }
          ]}
        />
      </AccordionWidget>
      <WrapContainer>
        <ProjectAttachmentWidget project={project} />
        {ideHefteUrl && <ProjectIdehefteWidget url={ideHefteUrl} />}
        <AccountManagerWidget manager={manager} />
      </WrapContainer>
    </>
  );
}

export default ProjectPage;
