import PropTypes from 'prop-types';
import { styled, getStyle } from 'style';

import {ExpandIcon} from 'assets/icons';

const Container = styled.label`
  display: block;
  position: relative;
  cursor: ${props => props.disabled ? "auto" : "pointer"};
  user-select: none;

  line-height: ${getStyle('size.icon.lg')};
  padding-left: calc(${getStyle('size.icon.lg')} + ${getStyle('spacing.md')});
  
  input {
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${getStyle('size.icon.lg')};
    width: ${getStyle('size.icon.lg')};
    stroke: ${getStyle('color.button.primary')};

    border: 0.0625rem solid ${props => props.disabled ? "grey" : getStyle('color.button.background.primary')};
    border-radius: ${getStyle('border.radius.sm')};

    svg {
      display: none;
    }
  }

  input:checked ~ .checkmark {
    background-color: ${getStyle('color.button.background.primary')};

    svg {
      display: block;
    }
  }
`;

const Checkbox = ({ children, checked, onChange, disabled }) => (
  /* eslint-disable jsx-a11y/label-has-associated-control */
  <Container disabled={disabled}>
    {children}
    <input
      disabled={disabled}
      type="checkbox"
      checked={checked}
      onChange={e => onChange && onChange(e.target.checked)}
    />
    <span className="checkmark">
      <ExpandIcon />
    </span>
  </Container>
);

Checkbox.propTypes = {
  children: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false
}

export default Checkbox;
