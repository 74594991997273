
import PropTypes from 'prop-types';
import { styled, getStyle } from 'style';
import { WidgetContainer, AccordionWidget } from 'components/elements';
import { MessageList, MessageForm } from 'pages/messages';
import { MessagesIcon } from 'assets/icons';

const MessageWidget = ({ receiverId, userIsAccountManager, userId, messages, sendMessage, refresh, uploadFiles, hideForm }) => (
  <WidgetContainer>
    <AccordionWidget title="Meldinger" icon={<MessagesIcon />} customContainer wide>
      <MessageList userId={userId} messages={messages} />

      {!hideForm && <>
        <Hr />
        <MessageForm
          subject="Melding"
          placeholder={`Send melding til ${userIsAccountManager ? 'kunde' : 'kundeveileder'}`}
          senderId={userId}
          threadId={userIsAccountManager ? receiverId : userId}
          sendMessage={sendMessage}
          receiverId={receiverId}
          refresh={refresh}
          uploadFiles={uploadFiles}
          userIsAccountManager={userIsAccountManager === true}
        />
      </>
      }
    </AccordionWidget>
  </WidgetContainer>
);

const Hr = styled.hr`
border-color: ${getStyle('color.border.theme')};
`;

PropTypes.defaultTypes = {
  hideForm: false
}

export default MessageWidget;
