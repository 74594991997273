import { useEffect, useState } from 'react';
import { BackButton } from 'components/elements';
import { Page } from 'components/layout';
import { sanity, sanityApi } from 'services/sanity';
import { BlockContent } from 'components/elements';
import { ArticlePublic } from './components';

const AllierPage = () => {
  const [allier, setAllier] = useState(null);
  useEffect(() => {
    sanityApi.fetchAllierPublic().then(res => setAllier(res.allier));
  }, []);
  if (allier == null) {
    return <></>;
  }
  return (
    <Page>
      <BackButton url="/">
        Tilbake til forsiden
      </BackButton>
      <ArticlePublic
        title="Om Allier"
        text={allier.description}
        imageUrl={(allier.mainImage ? allier.mainImage.asset.url : '')}
      >
        <BlockContent
          blocks={allier.bioallier}
          projectId={sanity.clientConfig.projectId}
          dataset={sanity.clientConfig.dataset}
        />
      </ArticlePublic>
    </Page>
  )
};

export default AllierPage;
