import PropTypes from 'prop-types';
import { styled, getStyle } from 'style';

const Title = styled.div`
  color: ${getStyle('color.widget.header')};
  font-size: ${getStyle('font.heading.lg')};
  font-weight: bold;
`;
const Subtitle = styled.div`
  color: ${getStyle('color.widget.subtitle')};
  font-size: ${getStyle('font.heading.sm')};
`;

const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Icon = styled.div`
  color: ${getStyle('color.widget.subtitle')};
  background: #AEA0CD;
  border-radius: 50%;
  padding: ${getStyle('spacing.sm')};
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: ${getStyle('spacing.md')};
  svg {
    stroke-width: 1.2;
    height:20px;
    width:20px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${getStyle('spacing.md')};

  color: ${getStyle('color.widget.header')};
  stroke: ${getStyle('color.widget.header')};
  fill: ${getStyle('color.widget.header')};

  height: ${getStyle('size.widget.header.height')};
`;

const HeaderSection = ({ title, subtitle, icon }) => (
  <Header>
    {icon && (<Icon>{icon}</Icon>)}
    <HeaderGroup>
      <Title>{title}</Title>
      {subtitle && (
      <Subtitle>
        {subtitle}
      </Subtitle>
      )}
    </HeaderGroup>
  </Header>
);

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.object
};

export default HeaderSection;
