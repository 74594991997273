import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Button, AccordionWidget, Widget, ButtonContainer } from 'components/elements';
import { styled, getStyle } from 'style';

import { MessagesIcon, PhoneIcon,  SpeechBubblesIcon, BusinessContactIcon } from 'assets/icons';

const Link = styled.a``;

const Field = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${getStyle('spacing.sm')};
  margin-bottom: 0;
  background: inherit;

  color: ${getStyle('color.widget.header')};
  stroke: ${getStyle('color.widget.header')};
  fill: ${getStyle('color.widget.header')};

  min-height: 3.3rem;
  border-radius: ${getStyle('border.radius.sm')};
`;

const FieldLabel = styled.div`
  color: ${getStyle('color.widget.subtitle')};
  font-size: ${getStyle('font.sm')};
`;
const FieldValue = styled.div`
  font-size: ${getStyle('font.sm')};
  color: #000;
  margin-top: 3px;
`;

const FieldGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;    
`;

const Icon = styled.div`
  @media screen and (min-width: ${getStyle('screen.sm')}px) and (max-width: ${getStyle('screen.xl')}px) {
    display: none;
  }

  background: #fff;
  color: ${getStyle('color.widget.subtitle')};
  border: 0.125rem solid #AEA0CD;
  border-radius: 50%;
  padding: ${getStyle('spacing.xs')};
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: ${getStyle('spacing.md')};
  svg {
    stroke-width: 1.2;
    height:10px;
    width:10px;
  } 
`;

const Content = styled.div`
  display: inherit;
  font-size: ${getStyle('font.md')};
  width: 100%;
  flex: 1;
  position: relative;
  header: {
    background:inherit;
  }
  
  > button: {    
    display:none;    
  }
`;

const ContactInfoWidget = styled(Widget)`
  background: #f9f9f9;
  border: 1px solid rgba(188, 186, 187, 0.25);
  box-sizing: border-box;
  border-radius: 5px;  
`;

const MessageButtonContainer = styled(ButtonContainer)`
  margin: 5px 5% 10px 5%;
  svg {
    position: absolute;
    left:15%;
  }
`;

const MessageButton = styled(Button)`
  min-height: 40px;
  font-size: ${getStyle('font.sm')};

  svg {
    @media screen and (min-width: ${getStyle('screen.sm')}px) and (max-width: ${getStyle('screen.xl')}px) {
      display: none;
    }
  }  
`;

const HeaderTitle = styled.div`
  color: #000000;
  font-size: 0.9rem;
  font-weight: bold;
  @media screen and (min-width: ${getStyle('screen.sm')}px) and (max-width: ${getStyle('screen.xl')}px) {
    font-size: ${getStyle('font.heading.sm')};
  }
`;

const HeaderSubtitle = styled.div`
  color: ${getStyle('color.widget.subtitle')};
  font-size: ${getStyle('font.heading.sm')};

  a {
    text-decoration: none;
  }
`;

const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const ContactImageContainer = styled.div`
  margin-left: auto;
  margin-right: 15px;
  margin-top:50px;

  img {
    width:95px;
    height:95px;
    border-radius:25px;
    border: 1px solid rgb(85, 59, 114)    
  }

  @media screen and (min-width: ${getStyle('screen.sm')}px) and (max-width: ${getStyle('screen.xl')}px) {
    display: none;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${getStyle('spacing.md')};

  color: ${getStyle('color.widget.header')};
  stroke: ${getStyle('color.widget.header')};
  fill: ${getStyle('color.widget.header')};

  height: ${getStyle('size.widget.header.height')};
`;

const ContactInfoWidgetFullPage = styled(Widget)`
  background: #f9f9f9;
  border: 1px solid rgba(188, 186, 187, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  
  @media screen and (min-width: ${getStyle('screen.xl')}px) {
    width:75%;
  }

  @media screen and (min-width: 1550px) {
    width: 48%;
  }    
`;

const ContactsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InfoSection = styled.div`
  width: 75%;
`;

const ContactImageFullPage = styled.div`
  margin-left: auto;
  margin-right: 15px;

  img {
    width:150px;
    height:150px;
    border-radius:5px;
    border: 1px solid rgb(85, 59, 114)    
  }
`;

const ContactFullPage = ({contact}) => {  
  return (
    <ContactInfoWidgetFullPage wide>
      <InfoSection>
        <Header>
          <HeaderGroup>
            <HeaderTitle>{contact.name}</HeaderTitle>
            {contact.title && (
            <HeaderSubtitle>
              {contact.title}
            </HeaderSubtitle>
            )}
          </HeaderGroup>          
        </Header>
        <Content>
          <ContactField
            key="Telefonnummer"
            title="Telefonnummer"
            subtitle={<Link variant="notdefault" href={`tel:${contact.mobile}`}>{contact.mobile}</Link>}
            icon={<PhoneIcon />}
          />
          <ContactField
            key="E-postadresse"
            title="E-postadresse"
            subtitle={<Link variant="notdefault" href={`mailto:${contact.email}`}>{contact.email}</Link>}
            icon={<MessagesIcon />}
          />
        </Content>
      </InfoSection>
      <div>
        <ContactImageFullPage>
          {contact.image
          ? <img size="contain" expand="contain" position="left" src={contact.image.asset.url} alt="Kontaktperson bilde" />
          : <></>
          }          
        </ContactImageFullPage>
      </div>
    </ContactInfoWidgetFullPage>    
  );
};

ContactFullPage.propTypes = {
  contact: PropTypes.object.isRequired
};

const ContactField = ({ title, subtitle, icon }) => (
  <Field>
    <Icon>{icon}</Icon>
    <FieldGroup>
      <FieldLabel>{title}</FieldLabel>
      <FieldValue>
        {subtitle}
      </FieldValue>
    </FieldGroup>
  </Field>
);

ContactField.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  icon: PropTypes.object
};

const HeaderSection = ({
  title, subtitle, image, mimetype
}) => (
  <Header>
    <HeaderGroup>
      <HeaderTitle>{title}</HeaderTitle>
      {subtitle && (
        <HeaderSubtitle>
          {subtitle}
        </HeaderSubtitle>
      )}
    </HeaderGroup>
    <ContactImageContainer>
      <img size="contain" expand="contain" position="left" src={`data:${mimetype};base64,${image}`} alt="Kontaktperson bilde" />
    </ContactImageContainer>
  </Header>
);

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  url: PropTypes.string
};

const Contact = ({ contact, borettslag }) => {
  return (
    <ContactInfoWidget wide>
      <HeaderSection
        title={`${contact.profile.givenName} ${contact.profile.familyName}`}
        subtitle={contact.role}
        image={contact.image}
        mimetype={contact.imageMimetype}
      />
      <Content>
        <ContactField
          key="Telefon"
          title="Telefon"
          subtitle={<Link variant="notdefault" href={`tel:${contact.profile.phone}`}>{contact.profile.phone}</Link>}
          icon={<PhoneIcon />}
        />
      </Content>
    </ContactInfoWidget>
  );
};

Contact.propTypes = {
  contact: PropTypes.object.isRequired,
  borettslag: PropTypes.object.isRequired
};

const ContactWidget = ({ contacts, borettslag }) => {
  let navigate = useNavigate();
  return (
    <>
      {contacts
      ? (
        <AccordionWidget
          title="Mine kontakter"
          icon={<BusinessContactIcon />}
          wide
        >
            {contacts && contacts.length > 0 &&
                contacts.map(contact =>(
                    <Contact key={contact.profile.id} contact={contact} borettslag={borettslag}/>
                ))
            }
            <MessageButtonContainer>
                <MessageButton
                    role="link"
                    onClick={() => navigate('/messages')}
                    variant="secondary"
                >
                    <SpeechBubblesIcon />
                    Send melding
                </MessageButton>
            </MessageButtonContainer>
        </AccordionWidget>
      )
      : (
        <AccordionWidget        
          icon={<BusinessContactIcon />}
          wide
        >
          <ContactsWrapper>
            {borettslag.contacts.map(contact => (
              <ContactFullPage key={contact.name} contact={contact} />
            ))}
          </ContactsWrapper>
        </AccordionWidget>
      )}
    </>
  );
};

ContactWidget.propTypes = {
  borettslag: PropTypes.object
};

export default ContactWidget;
