import {
  httpGet, httpPost, httpPut, httpDelete, jsonOrThrow, throwOnError
} from 'services/common';
import config from 'config';

const getProjectsUrl = () => (
  `${config.apiHost}/api/projects`
);

const getProjectAttachments = (projectId) => (
  httpGet(`${getProjectsUrl()}/${projectId}/attachments`)
    .then(jsonOrThrow)
    .then(data => data.attachments)
)

const getProjectsByCustomerId = (customerId, projectId = '') => {
  if (!customerId) return  [];

  return httpGet(`${getProjectsUrl()}/${customerId}`)
    .then(jsonOrThrow)
    .then(({ projects = [] } = {}) => (
      Promise.all((projectId ? projects.filter(({ id }) => id === projectId) : projects).map(project => (
        getProjectAttachments(project.id)
          .then(attachments => ({
            ...project,
            attachments
          }))
      )))
    )) || [];
};

const getProjects = (projectId = '') => {
  return httpGet(getProjectsUrl())
    .then(jsonOrThrow)
    .then(({ projects = [] } = {}) => (
      Promise.all((projectId ? projects.filter(({ id }) => id === projectId) : projects).map(project => (
        getProjectAttachments(project.id)
          .then(attachments => ({
            ...project,
            attachments
          }))
      )))
    )) || [];
};

const project = {
  getProjectTemplate: () => (
    httpGet(`${getProjectsUrl()}/template`)
      .then(jsonOrThrow)
  ),

  createProject: project => (
    httpPost(getProjectsUrl(), project)
      .then(jsonOrThrow)
      .then(created => ({
        ...created,
        attachments: []
      }))
  ),

  updateProject: project => (
    httpPut(`${getProjectsUrl()}/${project.id}`, project)
      .then(throwOnError)
  ),

  getProjectsByCustomerId: (customerId) => getProjectsByCustomerId(customerId) || [],

  getProjectByCustomerId: async (customerId, projectId) => (await getProjectsByCustomerId(customerId, projectId)).find(({ id }) => projectId === id) || {},

  getProjects: () => getProjects() || [],

  getProject: async (projectId) => (await getProjects(projectId)).find(({ id }) => projectId === id) || {},

  createProjectAttachment: (projectId, attachment) => (
    httpPost(`${getProjectsUrl()}/${projectId}/attachments`, attachment)
      .then(jsonOrThrow)
  ),

  getProjectAttachments,

  getProjectAttachment: (projectId, attachmentId) => (
    httpGet(`${getProjectsUrl()}/${projectId}/attachment/${attachmentId}`)
      .then(jsonOrThrow)
  ),

  updateProjectAttachment: (projectId, attachment) => (
    httpPut(`${getProjectsUrl()}/${projectId}/attachment`, attachment)
      .then(throwOnError)
  ),

  deleteProjectAttachment: (projectId, attachmentId) => (
    httpDelete(`${getProjectsUrl()}/${projectId}/attachment/${attachmentId}`)
      .then(throwOnError)
  )
};

export default project;
