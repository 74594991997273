import PropTypes from 'prop-types';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/core';
import { H2, Button, Match } from 'components/elements';
import { styled, getStyle, responsive } from 'style';

import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import {ClappingHands} from "../../assets/icons";

const DialogContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${getStyle('size.header.height')};
`;

const ContentContainer = styled.div`
  background: ${getStyle('color.background.primary')};
  box-shadow: ${getStyle('color.background.shadow')};
  margin: 0 ${getStyle('spacing.xs')};
  width: 100%;

  ${props => responsive('width', [
    {
      width: getStyle('screen.sm', props),
      value: props.wide ? `calc(100% - 2 * ${getStyle('size.menu.width.sm', props)})` : '50%'
    },
    {
      width: getStyle('screen.md', props),
      value: props.wide ? `calc(100% - 2 * ${getStyle('size.menu.width.md', props)})` : '50%'
    }
  ])}
  ${props => responsive('max-width', [
    {
      width: getStyle('screen.sm', props),
      value: props.wide ? '50rem' : '25rem'
    }
  ])}
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  color: ${getStyle('color.widget.header')};
  stroke: ${getStyle('color.widget.header')};
  fill: ${getStyle('color.widget.header')};
  height: ${getStyle('size.widget.header.height')};
  margin: 0 ${getStyle('spacing.md')};
  user-select: none;

  > svg:first-child {
    margin-right: ${getStyle('spacing.sm')};
    width: ${getStyle('size.icon.lg')};
  }

  h2 {
    font-size: ${getStyle('font.heading.md')};
    font-weight: bold;
    flex-grow: 1;
  }
`;

const Content = styled.div`
  font-size: ${getStyle('font.md')};
  border-top: 0.0625rem solid ${getStyle('color.border.primary')};
  padding: 0 ${getStyle('spacing.md')};
  padding-top: ${getStyle('spacing.md')};

  > * {
    margin-bottom: ${getStyle('spacing.xl')};
    width: auto;
  }
`;

const Dialog = ({
  variant, title, isOpen, onClose, wide, children
}) => (
  <Modal isOpen={isOpen} closeOnOverlayClick={false}>
    <ModalOverlay bg="#00000050" zIndex={10} />
    <ModalContent width="100vw" marginTop="0" bg="none" boxShadow="none" zIndex={10}>
      <DialogContainer>
        <ContentContainer wide={wide}>
          <Header>
            <Match
              value={variant}
              success={() => <SuccessIcon />}
              error={() => <ErrorIcon />}
              hands={() => <ClappingHands />}
              default={() => null}
            />
            <H2>{title}</H2>
            <Button variant="inline" aria-label="Lukk" onClick={onClose}>
              <CloseIcon />
            </Button>
          </Header>
          <Content>
            {children}
          </Content>
        </ContentContainer>
      </DialogContainer>
    </ModalContent>
  </Modal>
);

Dialog.propTypes = {
  variant: PropTypes.oneOf([
    'default', 'success', 'error','hands'
  ]),
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  wide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired
};

Dialog.defaultProps = {
  variant: 'default'
};

export default Dialog;
