import PropTypes from 'prop-types';
import { allierApi } from 'services';
import {
  H3, H4, Text, FileLink
} from 'components/elements';
import { styled, getStyle } from 'style';
import { toLocaleDateTime } from 'services/common';

const Message = styled.li`
  background: ${props => (props.mine
    ? 'rgba(0, 0, 0, 0.13)'
    : getStyle('color.background.secondary', props))};

  margin: ${props => (props.mine ? '0 0 0 20%' : '0 20% 0 0')};

  :not(:last-child) {
    margin-bottom: ${getStyle('spacing.md')};
  }

  border-radius: ${getStyle('border.radius.sm')};
  padding: ${getStyle('spacing.md')};

  h3 {
    margin-bottom: ${getStyle('spacing.md')};
  }
  p {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .attachments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: ${getStyle('spacing.lg')};

    > * {
      margin-bottom: ${getStyle('spacing.xs')};
    }
  }
`;

const MessageListContainer = styled.ul`
padding: 0 ${getStyle('spacing.md')};
`;



const MessageList = ({ userId, messages }) => {

  const sortedMessages =
    messages.length > 0
      ? messages.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      : messages;

return (
<MessageListContainer>
    { sortedMessages.length > 0 ?
        sortedMessages.map(message => {
          const mine = message.senderId === userId;
          return (
            <Message key={message.id} mine={mine}>
              <H3>{`${mine ? 'Meg' : message.senderName ? message.senderName : 'Ukjent'} (${toLocaleDateTime(message.createdAt)})`}</H3>
              <Text>{message.text}</Text>
              {message.attachments.length !== 0 && (
                <div className="attachments">
                  <H4>Vedlegg:</H4>
                  {message.attachments.map(f => (
                    <FileLink
                      key={f.target}
                      filename={f.name}
                      download={() => allierApi.getFileSas(f.target)}
                      onError={err => {}}
                    />
                  ))}
                </div>
              )}
            </Message>
          );
    }) : <p style={{margin: '15px 0'}}>Ingen meldinger</p> }
  </MessageListContainer>
  )
}

MessageList.propTypes = {
  userId: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired
};

export default MessageList;
