import { styled } from '../../style';
import { Button } from '../../components/elements';

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const CreateButton = styled(Button)`
  width: 100%;
`;
