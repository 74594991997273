import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Image = ({ alt, download, ...props }) => {
  const [url, setUrl] = useState();
  useEffect(() => {
    download()
      .then(sasUrl => {
        setUrl(sasUrl);
      });
  }, []);

  return (
    <img alt={alt} src={url} hidden={!url} {...props} />
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  download: PropTypes.func.isRequired
};

export default Image;
