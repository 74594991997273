import { styled, getStyle, responsive } from 'style';

const Sidebar = styled.div`
  background: ${getStyle('color.background.primary')};
  border-right: 0.0625rem solid ${getStyle('color.border.primary')};
  max-width: 0;
  width: 100%;

  ${props => responsive('max-width', [
    { width: getStyle('screen.sm', props), value: getStyle('size.menu.width.sm', props) },
    { width: getStyle('screen.md', props), value: getStyle('size.menu.width.md', props) }
  ])}
`;

export default Sidebar;
