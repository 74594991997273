import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, isEmployee } from 'services/auth';
import { useErrorHandler } from 'react-error-boundary'
import { allierApi } from 'services';
import useURLParam, { getUrlParamFromQueries } from 'services/helpers/useURLParam';
import useDebounce from 'services/helpers/useDebounce';
import { AddUserIcon, CustomersAddIcon, RemoveIcon } from 'assets/icons';
import { AccordionWidget, H1, Button } from 'components/elements';
import { BikeLoader } from 'components/loading';
import { FormField, SelectFormField } from 'components/form';
import { CustomerForm } from 'pages';
import Dialog from 'components/dialog/Dialog';
import DialogActions from 'components/dialog/DialogActions';
import CustomersTable from './CustomersTable';
import { getStyle, styled, useMediaQuery } from 'style';

const DEFAULT_ASSOCIATION = [
  {value: 'ALL', label: 'Alle'},
  {value: 'PRIVATE_CUSTOMER', label: 'Privatkunde'},
];

const Container = styled.div`
  padding-bottom: 5rem;
`;

const Title = styled(H1)`
  line-height: 2rem;
  span:first-child {
    font-weight: normal;
    color: ${getStyle('color.secondary')};
  }
  margin: 1rem 0;
  border-bottom: 0.0625rem solid rgb(224, 224, 224);
`;

const TopContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ isMobile }) => isMobile ? '1fr 1.3rem 1fr' : '2fr 1.3rem 1fr'};
  
  * {
    width: 100%;
  }
  
  input {
    border: 0.0625rem solid #6750A5;
  }
`;

export const TextInputClearButton = styled.span`
  margin: 38px 0 0 -30px;
  height: 1.3rem;
  stroke: #6750A5;
  
  ${({ visible }) => visible ? 'cursor: pointer;' : 'width: 0'}
`;

const ButtonContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  height: 50px;
  gap: 1rem;
`;

const ImportDialog = ({
  title, confirmText, cancelText, isOpen, onConfirm, onCancel, children, isDisabled
}) => (
  <Dialog title={title} isOpen={isOpen} onClose={onCancel}>
    {children}
    <DialogActions style={{display:'flex', marginTop:'30px'}}>
      <Button style={{marginRight: '10px'}} variant="secondary" onClick={onCancel} disabled={isDisabled}>{cancelText}</Button>
      <Button onClick={onConfirm} style={{marginBottom: '1.25rem'}} disabled={isDisabled}>{confirmText}</Button>
    </DialogActions>
  </Dialog>
);

const useCustomersData = (queries) => {
  const { user, isAuthenticated } = useAuth();

  const [customers, setCustomers] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState(false);

  const lastPageQueries = useRef(queries);
  const lastUserPageLoaded = useRef(0);
  const loaded = useRef(true);
  
  const fetchCustomersData = useCallback(async (reset = true) => {
    if (reset) {
      lastUserPageLoaded.current = 0;
    } else {
      lastUserPageLoaded.current++;
    }

    const queries = { page: lastUserPageLoaded.current, ...lastPageQueries.current }
    const customersData = await allierApi.getCustomers(getUrlParamFromQueries(queries));
    
    if (loaded.current) {
      if (reset) {
        setCustomers(customersData.customers);
      } else {
        setCustomers(prev => [...prev, ...customersData.customers]);
      }

      setPageInfo(customersData.pageInfo);
    }
  }, []);

  const debouncedFetchCustomerData = useDebounce(fetchCustomersData, 500);

  const getData = useCallback(async (showLoading = true) => {
    if (!isAuthenticated || !isEmployee(user)) return;
    if (!showLoading) setLoading(true);

    const [
      associationsData,
      contactData,
    ] = await Promise.all([
      allierApi.getCustomerAssociations(),
      allierApi.isCustomerContact(),
      fetchCustomersData(true),
    ]);

    if (loaded.current) {
      setAssociations(associationsData);
      setContact(contactData || {});
      setLoading(false);
    }
  }, [fetchCustomersData, isAuthenticated, user]);

  const loadMoreCustomers = useCallback(async () => fetchCustomersData(false), [fetchCustomersData]);

  useEffect(() => {
    getData();

    return () => {
      loaded.current = false;
    };
  }, [user, isAuthenticated, getData]);
  
  if (JSON.stringify(queries) !== JSON.stringify(lastPageQueries.current)) {
    lastPageQueries.current = queries;
    debouncedFetchCustomerData(true);
  }

  return { customers, loading, refreshData: getData, associations, contact, loadMoreCustomers, pageInfo, user };
};

const AccountManager = () => {
  const { userId } = useAuth();
  const isMobile = useMediaQuery().isMobile();
  const { queries, updateQueries } = useURLParam();
  const navigate = useNavigate();

  const [showNewUser, setShowNewUser] = useState(false);
  const [selectedImportAssociation, setSelectedImportAssociation] = useState();
  const [ importDialogOpen, setImportDialogOpen ] = useState(false);

  const { customers, loading, refreshData, associations, contact, loadMoreCustomers, pageInfo } = useCustomersData(queries);

  const handleError = useErrorHandler();

  const onCreateCustomer = useCallback(async (customer) => {
    const payLoad = {
      accountManagerId: userId,
      status: 'active',
      profile: {
        givenName: customer.givenName || '',
        familyName: customer.familyName || '',
        email: customer.email || '',
        phone: customer.phone || '',
        address: {
          zipCode: customer.zipCode || '',
          city: customer.city || '',
          streetAddress: customer.streetAddress || '',
          dwellingNumber: customer.dwellingNumber || '',
        },
        association: {
          id: customer.association || '',
        },
        electronicInvoice: customer.electronicInvoice || false
      }
    };

    await allierApi.createCustomer(payLoad).catch(handleError);
    await refreshData();
  }, [handleError, refreshData, userId]);

  if(!selectedImportAssociation && associations && associations.length > 0) setSelectedImportAssociation(associations[0].id);

  if (loading) return <BikeLoader />

  return (
    showNewUser
      ? <>
        <Title><span>Registrer kunde</span></Title>
        <CustomerForm associations={associations} onSave={onCreateCustomer} onCancel={() => setShowNewUser(false)} isNew/>
      </>
      : <Container>
        {
          contact.isCustomerContact
            ? null
            : <TopContainer>
              <ButtonContainer>
                <Button role="link" onClick={() => setShowNewUser(true)}>
                  <span>Registrer kunde</span>
                  <AddUserIcon />
                </Button>
                <Button role="link" onClick={() => setImportDialogOpen(true)}>
                  <span>Importer kunder</span>
                  <CustomersAddIcon />
                </Button>
              </ButtonContainer>
              <FilterContainer isMobile={isMobile}>
                <FormField
                  label="Fritekstsøk"
                  onChange={({ target }) => updateQueries({ query: target.value || ''})}
                  variant="form"
                  name="freetext"
                  value={queries.query || ''}
                />
                <TextInputClearButton onClick={() => updateQueries({ query: ''})} visible={!!queries.query}>
                  <RemoveIcon />
                </TextInputClearButton>
                <SelectFormField
                  label="Borettslag"
                  onChange={({ target }) => updateQueries({ associationId: (target.value !== 'ALL' ? target.value : '') || ''})}
                  value={queries.associationId || DEFAULT_ASSOCIATION[0].value}
                  options={[...DEFAULT_ASSOCIATION, ...associations.map(({ id: value, title: label }) => ({ value, label }))]}
                />
              </FilterContainer>
            </TopContainer>
        }
        <AccordionWidget title={"Kunder"} customContainer={!isMobile} wide>
          <CustomersTable customers={customers} loadMore={loadMoreCustomers} hasMore={pageInfo.hasMore} />
        </AccordionWidget>
        {selectedImportAssociation &&
          <ImportDialog
            isOpen={importDialogOpen}
            confirmText={"Importer"}
            cancelText={"Avbryt"}
            onCancel={() => setImportDialogOpen(false)}
            onConfirm={()=>navigate(`/accountmanager/import/${selectedImportAssociation}`, {  })}
            title={"Velg borettslag du vil importere til"}
            children={<SelectFormField
              wide
              label="Borettslag"
              onChange={({ target }) => setSelectedImportAssociation(target.value)}
              value={selectedImportAssociation}
              options={[...associations.map(({ id: value, title: label }) => ({ value, label }))]}
            />}
          />
        }
      </Container>
  );
}

export default AccountManager;
