import { httpDelete, httpGet, httpPut, jsonOrThrow } from 'services/common';
import config from 'config';
import { httpPost } from "./http";

const getFileTagsUrl = () => (
  `${config.apiHost}/api/filetags`
);

const service = {
  getFileTags: () => (
    httpGet(getFileTagsUrl())
      .then(jsonOrThrow)  
  ),

  createFileTags: (tags) => (
    httpPost(getFileTagsUrl(), tags)
      .then(jsonOrThrow)
  ),

  updateTagsInFile: (fileId, tags) => (
    httpPut(getFileTagsUrl() + `/${fileId}`, tags)
      .then(jsonOrThrow)
  ),

  updateTagsInFiles: (files) => (
    httpPut(getFileTagsUrl() + `/addtags`, files)
      .then(jsonOrThrow)
  ),

  removeTagFromFiles: (files) => (
    httpPost(getFileTagsUrl() + '/removeTags', files)
      .then(jsonOrThrow)
  ),

  removeTagFromFile: (fileId, tag) => (
    httpPost(getFileTagsUrl() + `/removeTagFromFile/${fileId}/${tag}`)
    .then(jsonOrThrow)
  ),
};

export default service;
