import PropTypes from 'prop-types';
import { styled } from 'style';

const Container = styled.label`
  .toggle-button {
    position: relative;
    width: 54px;
    height: 32px;
    margin: 0;
    vertical-align: top;
    background: #ffffff;
    border: 1px solid #bbc1e1;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .toggle-button::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 1px;
    width: 28px;
    height: 28px;
    background-color: #280071;
    border-radius: 50%;
    transform: translateX(0);
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .toggle-button:checked::after {
    transform: translateX(calc(77%));
    background-color: #fff;
  }

  .toggle-button:checked {
    background-color: #280071;
  }
`;

const ToggleButton = ({ children, checked, onChange }) => (
    /* eslint-disable jsx-a11y/label-has-associated-control */
    <Container>
      {children}
      <input
          type="checkbox"
          checked={checked}
          className="toggle-button"
          onChange={e => onChange && onChange(e.target.checked)}
      />
    </Container>
);

ToggleButton.propTypes = {
  children: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};


export default ToggleButton;
