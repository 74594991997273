import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { allierApi } from 'services/allier';
import { useErrorHandler } from 'react-error-boundary';
import { Dialog, useDialog } from 'components/dialog';
import { default as TagsDialog } from 'components/dialog/TagsDialog';
import {
  Table, TableRow, Button, FileLink
} from 'components/elements';
import { styled, getStyle } from 'style';

import { DocumentsIcon, DownloadIcon, TrashIcon, EditBlack, Hierarchy, CloseIcon, RemoveBookmark, MediabankArrowDown, MediabankArrowUp } from 'assets/icons';
import { toLocaleDateStyle } from 'services/common';

const ListItem = styled(TableRow)`
  > svg {
    margin-right: ${getStyle('spacing.md')};
    min-width: ${getStyle('size.icon.md')};
    width: ${getStyle('size.icon.md')};
  }
  > span {
    margin-right: ${getStyle('spacing.md')};
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  > button {
    white-space: nowrap;
    text-transform: uppercase;
    margin-right: ${getStyle('spacing.lg')};
  }
`;

const StyledTable = styled(Table)`
  > div, [role=row] {
    display: flex;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 10px;
    height: auto;
    min-height: 4rem;
    border-bottom: ${props => props.isMediabank ? 'none' : `0.0625rem solid ${getStyle('color.border.primary')}`};
    
  }
`;

const DateHolder = styled.div`
  @media (max-width: 1300px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  margin: ${getStyle('spacing.md')} 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledTitleAndTag = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width:  50%;
  word-break: break-all;
`;

const CheckBox = styled.input`
  width: 20px;
  height: 20px;
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2px 10px 0 0;
  overflow: hidden;
`;

export const Tag = styled.button`
  background-color: #d5d5d5;
  border-radius: 18px;
  padding: 1px 5px;
  margin: 2px;
  text-align: center;
  min-width: 50px;
  max-width: 140px;
  font-size: 0.7rem;  
  height: 14px;
  max-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
`;

export const ActionsHolder = styled.div`
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-around;
  margin-left: 12px;
  width: 14rem;
`;

const MediaAction = styled.button`
  width: 19px;
  height: 19px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #2f292920;
    border-radius: 5px;
    
  }

  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    height: 30px;
    width: 200px;
    top: 100%;
    left: 40%;
    padding: .5rem;
    border: 1px #6750A5 solid;
    border-radius: 5px;
    background-color: white;
  }

`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

const DocumentList = ({
  files = [], userId, onChange, deleteFile, userIsAccountManager, handleCheckedFiles, isChecked, sendFiles, customerSearch = false, isMediabank = false
}) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const [selectedFile, setFile] = useState('');
  const [selectedTagsFile, setTagsFile] = useState('');
  const [tagsDialogOpen, setTagsDialogOpen] = useState(false);
  const [chosenTags, setChosenTags] = useState([]);
  const [isConnectedCustomersDialogOpen, setConnectedCustomersDialogOpen] = useState(false);
  const [customersInFile, setCustomersInFile] = useState([]);
  const [removeFileFromMediabankDialogOpen, setRemoveFileFromMediabankDialogOpen] = useState(false);
  const [openActions, setOpenActions] = useState(null);
  const handleError = useErrorHandler();

  const getCustomersFromFile = useCallback(async (file) => {
    const customers = await allierApi.getCustomersFromFile(file.id).catch(handleError);
    setCustomersInFile(customers);
    setConnectedCustomersDialogOpen(true);
  }, [handleError]);

  const removeCustomerFromFile = useCallback(async (customerId, fileId) => {
    const res = await allierApi.removeCustomerFromFile(customerId, fileId).catch(handleError);
    if (res) await getCustomersFromFile({ id: fileId });
  }, [handleError, getCustomersFromFile]);

  const showDialog = file => {
    setFile(file);
    openDialog();
  };

  const removeMediabankFromFile = useCallback(async (fileId) => {
    const { id } = await allierApi.getTagId('Mediebank');
    await allierApi.removeTagFromFile(fileId, id).catch(handleError);
    onChange();
  }, [handleError, onChange]);


  const openTagsDialog = file => {
    setTagsFile(file);
    setChosenTags(file.tags);
    setTagsDialogOpen(true);
  };

  const onCloseTags = () => {
    setTagsDialogOpen(false);
    setChosenTags([]);
  };

  const onConfirm = tags => {
    const newChosenTags = tags.filter(tag => tag.documentTag);
    allierApi.updateTagsInFile(selectedTagsFile.id, newChosenTags)
      .then(() => {
        const file = files.find(f => f.id === selectedTagsFile.id);
        file.tags = newChosenTags;
        setChosenTags([]);
        setTagsDialogOpen(false);
        onChange();
      }).catch(handleError);
  };

  const openConnectedCustomersDialog = async (file) => {
    getCustomersFromFile(file);
    setFile(file);
  };

  const handleOpenActions = (file) => {
    setOpenActions(prevId => prevId === file.id ? null : file.id);
  };

  return (
    <StyledTable isMediabank>
      <Dialog
        title="Slette fil?"
        isOpen={isDialogOpen}
        onClose={closeDialog}
        wide
      >
        Er du sikker på at du vil slette {selectedFile.name}?
        <ButtonContainer>
          <Button variant="primary" onClick={() => { closeDialog(); deleteFile(selectedFile.id).then(() => onChange()); }}>Ja</Button>
          <Button variant="secondary" onClick={() => closeDialog()}>Nei</Button>
        </ButtonContainer>
      </Dialog>
      <Dialog
        title="Fjerne fil fra mediebank?"
        isOpen={removeFileFromMediabankDialogOpen}
        onClose={() => setRemoveFileFromMediabankDialogOpen(false)}
        wide
      ><div>Er du sikker på at du vil fjerne filen fra mediebanken?</div>
        <ButtonContainer>
          <Button variant="primary" onClick={() => { removeMediabankFromFile(selectedFile.id); setFile(''); setRemoveFileFromMediabankDialogOpen(false) }}>Ja</Button>
          <Button variant="secondary" onClick={() => setRemoveFileFromMediabankDialogOpen(false)}>Nei</Button>
        </ButtonContainer>
      </Dialog>
      <Dialog
        title={`Tilknyttede kunder - ${selectedFile.name}`}
        isOpen={isConnectedCustomersDialogOpen}
        onClose={() => setConnectedCustomersDialogOpen(false)}
        wide
      >
        <div style={{ fontWeight: "bold" }}>{customersInFile.length > 0 ? 'Kunder som er tilknyttet denne filen:' : 'Ingen kunder tilknyttet filen' }</div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          {Array.isArray(customersInFile) && customersInFile.map((customer) => (
            <StyledRow
              key={customer.id}
            >
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between", margin: "5px 0" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ minWidth: "10rem" }}>{`${customer.givenName} ${customer.familyName}`}</div>
                  <div style={{ minWidth: "16rem" }}>
                    {customer.address?.streetAddress} {customer.address?.dwellingNumber ? ' - ' + customer.address?.dwellingNumber : ''}
                  </div>
                </div>
                <button onClick={() => removeCustomerFromFile(customer.id, selectedFile.id)}>
                  <div style={{ width: "1rem", cursor: "pointer" }}>
                    <CloseIcon />
                  </div>
                </button>
              </div>
            </StyledRow>
          ))}
        </div>

        <ButtonContainer>
          <Button variant="primary" onClick={() => setConnectedCustomersDialogOpen(false)}>OK</Button>
        </ButtonContainer>
      </Dialog>

      <TagsDialog
        isDialogOpen={tagsDialogOpen}
        onCancel={onCloseTags}
        onConfirm={onConfirm}
        chosenTags={chosenTags}
      ></TagsDialog>
      {files.length > 0
        ? files.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(file => (
          <div key={file.id} style={{ display: "flex", alignItems: "stretch", flexDirection: "column" }}>
            <ListItem style={{ cursor: "auto" }} key={file.id} onClick={() => handleOpenActions(file)}>

              <DocumentsIcon />
              <DateHolder style={{ width: '8rem', minWidth: "8rem" }}>{toLocaleDateStyle(file.createdAt, 'short')}</DateHolder>
              <StyledTitleAndTag tags={file.tags?.length}>
                <div style={{ textAlign: 'left', margin: "0px auto 0 2px" }}>
                  {file.name}
                </div>
                {file.tags.length ? <Tags>{file?.tags.map(tag => <Tag key={tag.id} title={tag.name}>{tag.name}</Tag>)}</Tags> : null}
              </StyledTitleAndTag>
              {userIsAccountManager && <div style={{ marginLeft: '10px', width: '8rem' }}>{file.createdByFullName}</div>}
              <div style={{ display: "flex", marginLeft: "auto", width: "10rem", maxWidth: "10rem", justifyContent: "flex-end" }}>
                {
                  !isMediabank ?
                    <>
                      {userIsAccountManager && !sendFiles ? <EditBlack style={{ cursor: 'pointer' }} onClick={() => openTagsDialog(file)} /> : null}
                      <FileLink
                        filename={file.name}
                        download={() => allierApi.getFileSas(file.id)}
                        onError={() => { }}
                      >
                        <Button variant="inline"><DownloadIcon /></Button>
                      </FileLink>
                      {/*Omitted 'writeAccess' check. Backend checks createdBy*/}
                      {(file.createdBy === userId && !customerSearch) &&
                        <Button variant="inline" onClick={() => showDialog(file)}><TrashIcon /></Button>}
                    </>
                    : null
                }
                {isMediabank ? <button style={{ marginRight: "10px" }} onClick={(e) => { handleOpenActions(file); e.stopPropagation() }}>{openActions === file.id ? <MediabankArrowUp /> : <MediabankArrowDown />}</button> : null}
                {handleCheckedFiles ? <CheckBox type="checkbox" id={file.id} onChange={(e) => { handleCheckedFiles(e, file); }} onClick={(e) => e.stopPropagation()} checked={isChecked(file.id)} /> : null}
              </div>


            </ListItem>
            {isMediabank && openActions === file.id ?
              <ActionsHolder>
                <MediaAction data-tooltip="Fjern fil fra mediebank" onClick={() => { setFile(file); setRemoveFileFromMediabankDialogOpen(true); }}><RemoveBookmark style={{ width: "19px", height: "19px"}}/></MediaAction>
                <MediaAction data-tooltip="Tilknyttede kunder" style={{ width: "19px", height: "19px", marginRight: "5px" }}><Hierarchy onClick={() => openConnectedCustomersDialog(file)} style={{height: "19px", width:"19px"}}/></MediaAction>
                {userIsAccountManager && !sendFiles ? <MediaAction data-tooltip="Legg til tags"><EditBlack style={{ cursor: 'pointer', height: "19px", width:"19px" }} onClick={() => openTagsDialog(file)} /></MediaAction> : null}
                <FileLink
                  filename={file.name}
                  download={() => allierApi.getFileSas(file.id)}
                  onError={() => { }}
                >
                  <MediaAction data-tooltip="Last ned fil" variant="inline"><DownloadIcon /></MediaAction>
                </FileLink>
                {/*Omitted 'writeAccess' check. Backend checks createdBy*/}
                {(file.createdBy === userId && !customerSearch) &&
                  <MediaAction data-tooltip="Slett dokument" variant="inline" onClick={() => showDialog(file)}><TrashIcon style={{ width: "19px", height: "19px"}}/></MediaAction>}
              </ActionsHolder>
              : null}
          </div>
        )) : <p style={{ margin: '20px' }}>Ingen dokumenter</p>
      }
    </StyledTable>
  );
};

DocumentList.propTypes = {
  files: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  deleteFile: PropTypes.func,
  userIsAccountManager: PropTypes.bool.isRequired,
  handleCheckedFiles: PropTypes.func,
  isChecked: PropTypes.func,
  sendFiles: PropTypes.bool,
  isMediabank: PropTypes.bool,
};

export default DocumentList;
