import ReactSelect from 'react-select';

//Format: {value: "",label:""}
const MultiSelect = ({ options, onChange }) => (
  <ReactSelect
    placeholder="Filtrer på status"
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        minHeight: '3rem',
        border: `0.0625rem solid #6750A5`,
        borderRadius: '0.3125rem',
        padding: `0 0.625rem`,
        appearance: 'none',
        outline: 'none'
      }),
      multiValueRemove: base => ({
        ...base,
        backgroundColor: 'transparent',
        color: '#6750A5',
      }),
      option: base => ({
        ...base,
        backgroundColor: 'white',
        color: "black"
      })
    }}
    options={options}
    noOptionsMessage={() => "Ingen flere statuser"}
    isMulti
    closeMenuOnSelect={false}
    defaultValue={[options[0],options[1]]}
    hideSelectedOptions={true}
    onChange={onChange}
 />
);

export default MultiSelect;
