import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { toLocaleNumber } from 'services/common';
import { Input } from 'components/elements';

const NumberInput = ({
  min, max, decimals, value, onChange, ...props
}) => {
  const ref = useRef();

  const clamp = v => {
    if (v < min) return min;
    if (v > max) return max;
    return v;
  };

  const setValue = newValue => {
    const v = clamp(newValue);
    ref.current.value = toLocaleNumber(v, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
    onChange(Math.round(v * 10 ** decimals + Number.EPSILON) / 10 ** decimals);
  };

  useEffect(() => { if (value !== undefined) setValue(value); }, [value]);

  return (
    <Input
      ref={ref}
      variant="form"
      inputMode={decimals > 0 ? 'decimal' : 'numeric'}
      min={min}
      max={max}
      onKeyPress={e => e.key === 'Enter' && e.target.blur()}
      onFocus={e => e.target.select()}
      onChange={e => {
        e.target.value = e.target.value.replace(/[^0-9.,]/, '');
      }}
      onBlur={e => {
        const v = Number.parseFloat(e.target.value.replace(',', '.'));
        setValue(v);
      }}
      {...props}
    />
  );
};

NumberInput.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  decimals: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

NumberInput.defaultProps = {
  decimals: 0
};

export default NumberInput;
