import { styled, getStyle, responsive } from 'style';
import { AccordionWidget } from 'components/elements';

export const ImageContainer = styled.div`
  min-height: 10rem;
  height: ${props => props.height || '100%'};
  background-image: url('${props => props.url}');
  background-position: ${props => props.position || 'center'};
  background-repeat: no-repeat;
  background-size:  ${props => props.size || 'cover'};
  border-radius: 0;
  ${props => responsive('border-radius', [
    {
      width: getStyle('screen.sm', props),
      value: (props.radius ? '10px !important' : 0)
    }
  ])}
  ${props => responsive('background-size', [
    {
      width: getStyle('screen.sm', props),
      value: props.expand || 'cover'
    }
  ])}
  ${props => responsive('min-height', [
    {
      width: getStyle('screen.sm', props),
      value: props.miHeight || '10rem'
    }
  ])}
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  height: ${props => props.height || 'auto'};
  justify-items: ${props => props.ji || 'inherit'};
  background:  ${props => props.background || 'white'};;
  border-radius: 10px !important;
  overflow: hidden;
  padding-bottom: 5px;
  ${props => responsive('grid-template-columns', [
    {
      width: getStyle('screen.md', props),
      value: props.columns || '50% 50%'
    }
  ])}
`;

export const Empty = styled.div`
  content: " "; 
`;

export const StyledAccordian = styled(AccordionWidget)` 
display: flex;
flex-direction: column;
justify-content: space-between;
padding-bottom: 10px;
border-radius: ${props => (props.radius ? '10px !important' : 0)};
${props => responsive('min-height', [
    {
      width: getStyle('screen.lg', props),
      value: '25rem'
    }
  ])}
`;
