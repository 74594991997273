import React from 'react';
import { useParams } from 'react-router-dom';
import { AccordionWidget } from 'components/elements';
import { Bulb } from 'assets/icons';
import { styled, useMediaQuery, getStyle, responsive } from 'style';
import { BikeLoader } from 'components/loading';
import { useInspirationSanityData } from './hooks';
import { InspirationPageText, InspirationPageImage, BackLinks, BookletElement } from './index';

export const MAIN_PAGE_SLUG = 'inspirasjonside';

const PageContainer = styled.div`
  margin: 1.25rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2em 1em;

  ${props => responsive('margin', [
    {
      width: getStyle('screen.sm', props),
      value: '1em 1em'
    }
    ])}
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ line }) => line ? '#6750A5' : 'transparent'};
  transform: translateY(1.5em);
`;

const InspirationPage = () => {
  const isMobile = useMediaQuery().isMobile();
  const { slug = MAIN_PAGE_SLUG } = useParams();
  const { title, content, loading, linkArray, bookletUrl } = useInspirationSanityData(slug);

  if (loading) return <BikeLoader />;
  const isMainPage = slug === MAIN_PAGE_SLUG;

  return (
    <AccordionWidget icon={<Bulb />} title="Inspirasjonsside" customContainer={true} wide>
      <BackLinks linkArray={linkArray} pageTitle={title} isMobile={isMobile}/>
      <PageContainer>
        {
          content.map(({ _key: key, ...element }) => {
            switch (element._type) {
              case 'inspirationPageText': return <InspirationPageText key={key} backgroundColor={isMainPage ? '#f9f9f9' : ''} {...element}/>
              case 'separator': return <Separator key={key} line={element.line} />
              case 'bookletElement': return <BookletElement bookletUrl={bookletUrl} key={key} {...element} isMobile={isMobile} />
              case 'image': return (
                <InspirationPageImage
                  key={key}
                  {...element}
                  isMainPage={isMainPage}
                  isMobile={isMobile}
                  bookletUrl={bookletUrl}
                />
              )
              default: return null;
            }
          })
        }
      </PageContainer>
    </AccordionWidget>
  );
};

export default InspirationPage;