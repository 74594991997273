import PropTypes from 'prop-types';
import { Label, Select } from 'components/elements';
import { styled, getStyle } from 'style';
import FieldContainer from './FieldContainer';
import FieldError from './FieldError';

const Container = styled(FieldContainer)`
  p {
    color: ${getStyle('color.secondary')};
    font-weight: bold;
    min-height: 1.5rem;
  }
`;

const SelectFormField = ({ name, label, value, onChange, options, wide, notChosenOption, error }) => (
  <Container wide={wide}>
    <Label primary htmlFor={name}>{label}</Label>
    <Select name={name} value={value} onChange={onChange}>
        { notChosenOption?.length > 0
          ? <option value="" disabled selected >{notChosenOption}</option> 
          : '' 
        }
      {options && options.map(option => (
        <option
          aria-label={option.label || option.title}
          value={option.value || option.id}
          key={option.value || option.id || option.label || option.title}
        >
          {option.label || option.title || option.value || option.id}
        </option>
      ))}
    </Select>
    <FieldError hidden={!error}>{error}</FieldError>
  </Container>
);

SelectFormField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  wide: PropTypes.bool,
  notChosenOption: PropTypes.string,
};

export default SelectFormField;
