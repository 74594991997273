import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {
    Button, UploadButton
  } from 'components/elements';
  import {
    RemoveIcon, AttachmentIcon, ImageIcon
  } from 'assets/icons';
import { getStyle, styled } from 'style'
import { allierApi } from 'services';
import config from "../../config";

const UploadButtonContainer = styled.div`
  display: flex;
  margin-bottom: ${getStyle('spacing.md')};

  > * {
    margin-right: ${getStyle('spacing.md')};
  }
`;

const AttachmentList = styled.div`
    ul {    
        margin-bottom: 1rem;        

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;            

            svg {
                height: ${getStyle('size.icon.sm')};
            }
        }
    }
`;

const Attachments = forwardRef(({ tags = [], width, mediabankTagId = null, handleCountChange = () => {}}, ref) => {
    const [attachments, setAttachments] = useState([]);
    const [images, setImages] = useState([]);
    
useEffect(() => {
  handleCountChange(attachments.concat(images));
}, [attachments, images]);

    useImperativeHandle(ref, () => ({
      uploadAttachments(userId) {
        return Promise.all((attachments.concat(images) || [])
            .map(attachment => {
                const meta = {
                    name: attachment.name,
                    access: [{ userId }],
                    tags: mediabankTagId ? [...tags, {id: mediabankTagId, name: 'Mediebank'}] : tags ?? [],
                };
                return allierApi.uploadFile(meta, attachment)
                    .then(file => ({
                        type: 'fileRef',
                        name: file.name,
                        target: file.id
                    }));
        }))
      },
      clearAttachments() {
        setAttachments([]);
        setImages([]);
      },
      getAttachmentCount() {
        if(attachments && images)
          return attachments?.concat(images).length;
        return 0;
      }
    }));

    const removeAttachment = (file) => {
        setAttachments(attachments.filter(f => f.name !== file.name));
        setImages(images.filter(f => f.name !== file.name));
    }

    return (
        <>
            <UploadButtonContainer>
                <UploadButton
                    variant="secondary"
                    shape="square"
                    aria-label="Vedlegg"
                    accept="application/pdf"
                    multiple
                    files={attachments}
                    onFilesChanged={setAttachments}
                    icon
                    maxSize={config.fileUploadLimitBytes}
                >
                    <AttachmentIcon />
                </UploadButton>
                <UploadButton
                    variant="secondary"
                    shape="square"
                    aria-label="Bilder"
                    accept="image/jpeg,image/png,video/mp4,video/webm,video/quicktime"
                    multiple
                    files={images}
                    onFilesChanged={setImages}
                    maxSize={config.fileUploadLimitBytes}
                >
                    <ImageIcon />
                </UploadButton>
            </UploadButtonContainer>
            <AttachmentList>
              <ul hidden={attachments.concat(images).length === 0} style={{ width: width ?? '100%'}}>
                {attachments.concat(images).map(f => (
                  <li key={f.name} style={{border: '1px dashed black', padding: '3px', marginTop: '3px', borderRadius: '3px'}}>
                    <span>{f.name}</span>
                    <Button variant="inline" onClick={() => removeAttachment(f)}><RemoveIcon /></Button>
                  </li>
                ))}
              </ul>
            </AttachmentList>
        </>
    );
});

export default Attachments;
