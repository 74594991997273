import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormField, SelectFormField } from 'components/form';
import { Button, UploadButton, Image, Video } from 'components/elements';
import { styled, getStyle, responsive } from 'style';
import { DeleteIcon } from 'assets/icons';
import { allierApi } from 'services';
import { Spinner } from "../../../components/loading";
import { useErrorHandler } from 'react-error-boundary'

const MAX_PER_PHASE = 6;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  
  &:first-child {
    margin-bottom: 3rem;
  }

  ${props => responsive('flex-direction', [
  { width: getStyle('screen.sm', props), value: 'row' }
])}

  > * {
    width: 100%;

  ${props => responsive('width', [
  {
    width: getStyle('screen.sm', props),
    value: `calc(50% - ${getStyle('spacing.xxxl', props)})`
  }
])}
  }

  img {
    border-radius: ${getStyle('border.radius.sm')};
    margin-bottom: ${getStyle('spacing.md')};
  }
`;

const FromContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DeleteButton = styled.div`  
  position: absolute;
  top: -75px;
  right: 25px;

  svg {
    margin-left: 10px;
  }

  button {
    float: right;
    width: 125px;
  }
`;

const FullWidthImage = styled(Image)`
  width: 100%;
`;

const FullWidthVideo = styled.video`
  width: 100%;
`;

const FullWidthHtmlImage = styled.img`
  width: 100%;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  height: 88%;
  width: 94%;
  opacity: 0.9;
  display: block;
  z-index: 999;
  top: 0;
`;

const BlinkingContent = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  animation: blinker 1s linear infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

const validate = ({ phaseId, description }, { attachments = [] }) => {
  const errors = {};

  if(!phaseId) errors.phaseId = '* Fase er påkrevd';
  if (!description) errors.description = '* Beskrivelse er påkrevd';
  if (phaseId) {
    if(attachments.filter(att => att.phaseId === phaseId).length >= MAX_PER_PHASE) errors.phaseId = `Maks ${MAX_PER_PHASE} vedlegg per fase`
  }

  const hasError = Object.keys(errors).some(key => !!errors[key]);
  return { hasError, errors };
};

const Attachment = ({ attachment }) => {
  const downloadFile = () => allierApi.getFileSas(attachment.fileId);
  const isNewFile = !attachment.fileId;

  if (attachment.type?.includes('video') || attachment.file?.type?.includes('video')) {
    return isNewFile
      ? (
        <FullWidthVideo controls alt="Ny video">
          <source src={URL.createObjectURL(attachment)} type={attachment.type} />
          <track kind="subtitles" />
        </FullWidthVideo>
      )
      : <Video alt={attachment.file.name} type={attachment.file.type} download={downloadFile} onClick={() => {}}/>;
  }

  return isNewFile
    ? <FullWidthHtmlImage alt="Nytt bilde" src={URL.createObjectURL(attachment)} />
    : <FullWidthImage alt={attachment.file.name} download={downloadFile} />;
}

const create = async (attachment, data, customerId, projectId, changePhase, projectTagId, handleError) => {
  const { description, phaseId } = data;

  const meta = {
    name: attachment.name,
    access: [{ userId: customerId }],
    tags: [ projectTagId ]
  }
  
  const { id: fileId, name: fileName } = await allierApi.uploadFile(meta, attachment)
    .catch(() => handleError(new Error('Det skjedde en feil med opplasting av fil')))|| {};

  if (!fileId)  return false;

  const content = {
    description,
    fileId,
    fileName,
    phaseId,
    changePhase
  };
  const { id } = await allierApi.createProjectAttachment(projectId, content)
    .catch(() => handleError(new Error('Det skjedde en feil med å legge til fil til prosjektet')))|| {};

  return !!id;
};

const update = async (attachment, attachmentId, data, customerId, projectId, projectTagId, handleError) => {
  const { description, phaseId } = data;

  let file = {};

  if (attachment.file) {
    file = attachment.file;
    file.access = [{ userId: customerId }];
  } else {
    let meta = { 
      name: attachment.name,
      access: [{ userId: customerId }],
      tags: [ projectTagId ]
    };
    console.log(meta);
    file = await allierApi.uploadFile(meta, attachment)
      .catch(() => handleError(new Error('Det skjedde en feil med opplasting av fil'))) || {};
  }

  if (!file.id)  return false;

  const content = {
    description,
    fileId: file.id,
    id: attachmentId,
    phaseId,
    file,
  };
  const { ok } = await allierApi.updateProjectAttachment(projectId, content)
    .catch(() => handleError(new Error('Det skjedde en feil med oppdatering av prosjekt'))) || {};

  return ok;
};

const ProjectAttachmentForm = ({ attachment, attachmentId, acceptFiles, project, onCancel, customerId, refreshData, onFilesChanged, phaseDescriptions }) => {  
  const { phaseId = '', description = '', fileId: preFileId } = attachment;
  const isNew = !attachmentId;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ phaseId, description });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [projectTagId, setProjectTagId] = useState({});
  const handleError = useErrorHandler();

  const getProjectTagId = useCallback(async () => {
    await allierApi.getTagId('Prosjekt').then((res) => { setProjectTagId(res) }).catch(handleError);
  }, [handleError]);

  useEffect(() => {
    getProjectTagId();
  }, [getProjectTagId]);

  const onChange = useCallback(({ target }) => {
    const { name, value } = target;
    if (!name) return;

    setTouched(prevState => ({ ...prevState, [name]: true }));
    setData(prevState => {
      const newState = { ...prevState, [name]: value };
      const { errors } = validate(newState, project);
      setErrors(errors);
      return newState;
    });
  }, [project]);

  const onDelete = useCallback(async () => {
    if (preFileId) {
      setLoading(true);
      const { ok } =await allierApi.deleteFile(preFileId)
        .catch(console.error)|| {};

      if (ok) {
        await refreshData(false);
        onCancel();
      }

      setLoading(false);
    }
  }, [onCancel, preFileId, refreshData]);

  const onSubmit = async ({ target }) => {
    const { changePhase = false } = target.dataset;
    setTouched({ phaseId: true, description: true });
    const { hasError, errors } = validate(data, project);
    setErrors(errors);
    if (hasError) return;

    setLoading(true);
    const success = isNew
      ? await create(attachment, data, customerId, project.id, changePhase, projectTagId, handleError)
      : await update(attachment, attachmentId, data, customerId, project.id, projectTagId, handleError);

    if (success) {
      await refreshData(false);
      onCancel();
    } else {
      setLoading(false);
    }
  };

  const onBlur = useCallback(({ target }) => {
    const { name } = target;
    if (name) {
      setTouched(prevState => ({ ...prevState, [name]: true }));
    }
  }, []);

  const insertDescriptions =  useCallback(({ target }) => {
    const { value = '' } = target;
    if (value) {
      setTouched(prevState => ({ ...prevState, description: true }));
      setData(prevState => {
        const newState = { ...prevState, description: phaseDescriptions.find(e => e.title === value)?.text || '' };
        const { errors } = validate(newState, project);
        setErrors(errors);
        return newState;
      });
    }
  }, [phaseDescriptions, project])

  return (
    <>
      <Container style={{opacity: loading ? '0.3': '1'}}>
        <div>
          <Attachment attachment={attachment}/>
          <UploadButton accept={acceptFiles} onFilesChanged={onFilesChanged}>
            Endre bilde
          </UploadButton>
        </div>
        <FromContainer>
          { !isNew &&
            <DeleteButton>
              <Button disabled={loading} onClick={onDelete}>
                Slett
                <DeleteIcon/>
              </Button>
            </DeleteButton>
          }
          <SelectFormField
            wide
            label="Velg fase"
            options={isNew ? [{ title: 'Velg fase...', id: '' }, ...project.phases] : project.phases}
            defaultValue={phaseId}
            value={data.phaseId}
            onChange={onChange}
            name="phaseId"
            error={(touched.phaseId && errors.phaseId) || ''}
            onBlur={onBlur}
          />
          <SelectFormField
            wide
            label="Velg beskrivelse"
            options={phaseDescriptions}
            defaultValue={phaseId}
            onChange={insertDescriptions}
          />
          <FormField
            value={data.description}
            type="textarea"
            name="description"
            label="Beskrivelse"
            placeholder="Skriv en liten beskrivelse ..."
            onChange={onChange}
            error={(touched.description && errors.description) || ''}
            onBlur={onBlur}
            rows="4"
            wide
          />
        </FromContainer>
      </Container>
      { loading &&
        <LoadingOverlay>
          <div style={{left: '50%', position: 'absolute', top: '50%',transform: 'translate(-50%, -50%)'}}>
            <Spinner style={{height: '100px',width: '100px',border: '15px solid #f3f3f3',borderTop: '15px solid #424242'}} />
            <BlinkingContent><b>Lagrer...</b></BlinkingContent>
          </div>
        </LoadingOverlay>
      }
      <Container>
        <Button disabled={loading} onClick={onCancel} variant="secondary">
          Avbryt
        </Button>
        <Button disabled={loading} onClick={onSubmit} data-change-phase={false} >
          { isNew ? 'Last opp' : 'Oppdater' }
        </Button>
        { isNew &&
          <Button disabled={loading} onClick={onSubmit} data-change-phase={true}>
            Last opp og skift fase
          </Button>
        }
      </Container>
    </>
  );
};

ProjectAttachmentForm.defaultProps = {
  attachment: {},
  acceptFiles: '',
  project: {},
  onCancel: () => {},
  customerId: '',
  refreshData: () => {},
  onFilesChanged: () => {},
  phaseDescriptions: [],
}

ProjectAttachmentForm.propTypes = {
  attachment: PropTypes.shape({}),
  acceptFiles: PropTypes.string,
  project: PropTypes.shape({}),
  onCancel: PropTypes.func,
  customerId: PropTypes.string,
  refreshData: PropTypes.func,
  onFilesChanged: PropTypes.func,
  phaseDescriptions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
  }))
};

export default ProjectAttachmentForm;
