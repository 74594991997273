import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "style";
import { allierApi } from "services";
import { useErrorHandler } from 'react-error-boundary';
import { CheckedFilesButton } from "pages/mediabank/MediabankPage";

const CustomerTab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SearchContainer = styled.div`
  border: 2px solid #6750A5;
  border-radius: 5px;
  margin-top: 1rem;
`;

const SearchBox = styled.div`
  padding: 1rem;
`;

const InputField = styled.input`
  border: 0.0625rem solid #6750A5;
  border-radius: 5px;
  padding: 12px;

  :focus {
    outline: none;
    background-color: #FAFAFA;
  }
`;

const TableCell = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 25%;
  padding: 0.5rem;
  
`;

const HeaderCell = styled.div`
display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  font-weight: bold;
  min-width: 25%;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`;

const StyledCheckbox = styled.input`
  display: flex;
  height: 30px;
  width: 20px;
`;

const StyledTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.0625rem solid #E0E0E0;

  :nth-child(2) {
      background: #D8D8D81A;
    }
`;

const DropDown = styled.select`
  border: 0.0625rem solid #6750A5;
  border-radius: 5px;
  height: 44px;
  padding: 8px;

  :focus {
    outline: none;
    background-color: #FAFAFA;
  }
`;

const TextLabel = styled.label`
  font-weight: bold;
`;

const InputFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
`;

const Tab = styled.div`
  display: flex;
  margin: 0.5rem;
  cursor: pointer;
  ${({ chosen }) => chosen && `
    border-bottom: 5px solid #6750A5;
  `}
`;

const SentFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const Title = styled.div`
  font-weight: bold;
  margin: 1rem;
`;

const TABS = {
  CUSTOMERS: "customers",
  CHOSEN_CUSTOMERS: "chosenCustomers"
};

const TableRow = ({ customer, addToCustomerList, chosenCustomers }) => {

  return (
    <StyledTableRow key={customer.id}>
      <TableCell>{`${customer.givenName} ${customer.familyName}`}</TableCell>
      <TableCell>{`${customer.address.streetAddress} ${customer.address.dwellingNumber}`}</TableCell>
      <TableCell>{customer.association.title}</TableCell>
      <TableCell>
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <StyledCheckbox type="checkbox" onChange={() => addToCustomerList(customer)} checked={chosenCustomers.some(c => c.id === customer.id)} style={{ display: "flex" }} />
        </div>
      </TableCell>
    </StyledTableRow>
  )
};

const SendFileList = ({ tab, sendFile, chosenCustomers, addToCustomerList, customers }) => {
  const customersToDisplay = (tab === TABS.CUSTOMERS ? customers : chosenCustomers);
  return (
    <>
      {customersToDisplay?.map(customer => (
        <TableRow customer={customer} addToCustomerList={addToCustomerList} chosenCustomers={chosenCustomers} key={customer.id} />
      ))}
      <CheckedFilesButton style={{ width: "auto", fontWeight: "bold", height: "3rem" }} onClick={sendFile} disabled={(chosenCustomers.length === 0)}>Send filer til mottakere</CheckedFilesButton>
    </>
  );
};

export const CustomerSearchWidget = ({ files, resetCheckedFiles }) => {
  const [associations, setAssociations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [chosenTab, setChosenTab] = useState("customers");
  const [chosenCustomers, setChosenCustomers] = useState([]);
  const [chosenFiles] = useState(files);
  const [chosenAssociations, setChosenAssociations] = useState();
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [sendFilesSuccess, setSendFilesSuccess] = useState(false);
  const [search, setSearch] = useState("");

  const handleError = useErrorHandler();

  const getAssociations = useCallback(async () => {
    await allierApi.getAssociations().then((response) => setAssociations(response)).catch(handleError);
  }, [handleError]);

  const getCustomers = useCallback(async (associationId) => {
    await allierApi.getCustomers(`?size=1000&associationId=${associationId}`)
      .then((response) => {
        setCustomers(response.customers);
        setFilteredCustomers(response.customers);
      })
      .catch(handleError);
  }, [handleError]);

  const filterCustomers = useCallback((search) => {
    const normalizedSearch = search?.replace(/\s/g, '').toUpperCase();

    let filteredCustomers = customers.filter(customer => {
      const matchesSearch =

        customer.givenName?.replace(/\s/g, '').toUpperCase().includes(normalizedSearch) ||
        customer.familyName?.replace(/\s/g, '').toUpperCase().includes(normalizedSearch) ||
        customer.address.streetAddress?.replace(/\s/g, '').toUpperCase().includes(normalizedSearch) ||
        customer.address.dwellingNumber?.replace(/\s/g, '').toUpperCase().includes(normalizedSearch);
      return matchesSearch;
    });

    setFilteredCustomers(filteredCustomers);
  }, [customers]);

  useEffect(() => {

    filterCustomers(search);

  }, [customers, filterCustomers, search]);

  useEffect(() => {
    getAssociations();
  }, [getAssociations]);

  useEffect(() => {
    chosenAssociations ? getCustomers(chosenAssociations) : setCustomers([]);
  }, [chosenAssociations, getCustomers]);

  const toggleHeader = (e) => {
    setChosenTab(e.target.id);
  }

  const addToCustomerList = (customer) => {
    if (chosenCustomers.some(c => c.id === customer.id)) {
      setChosenCustomers(chosenCustomers.filter(c => c.id !== customer.id));
    } else {
      setChosenCustomers([...chosenCustomers, customer]);
    }
  }

  const sendFilesToCustomers = useCallback(async () => {
    await allierApi.sendFilesToCustomers(
      {
        fileId: files.map(f => f.file),
        customers: chosenCustomers.map(c => c.id)
      }
    ).then((response) => {
      setSendFilesSuccess(response?.ok || false);
      resetCheckedFiles();
    }).catch(handleError);
  }, [files, chosenCustomers, handleError, resetCheckedFiles]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  }

  const changeAssociation = (e) => {
    setChosenAssociations(e.target.value);
  }

  return (
    <SearchContainer>
      {sendFilesSuccess ?
        (
          <>
            <SentFiles>
              <Title>Filene er nå tilgjengelig for mottakerene:</Title>
              {chosenCustomers.map(customer => <div key={customer.id}>{customer.givenName} {customer.familyName}</div>)}
              <Title>Filer: </Title>
              {chosenFiles.map(file => <div key={file.file}>{file.name}</div>)}
            </SentFiles></>
        ) :
        (
          <>
            <SearchBox>
              <div style={{ display: "flex" }}>
                <InputFlex>
                  <TextLabel htmlFor="search">Fritekstsøk</TextLabel>
                  <InputField type="text" onChange={(e) => handleSearch(e)} />
                </InputFlex>
                <InputFlex>
                  <TextLabel htmlFor="search">Borettslag</TextLabel>
                  <DropDown value={chosenAssociations} onChange={(e) => changeAssociation(e)}>
                    <option>Velg</option>
                    <option value="PRIVATE_CUSTOMER">Privat</option>
                    {associations?.map(association => <option value={association.id} key={association.id}>{association.title}
                    </option>)}
                  </DropDown>
                </InputFlex>
              </div>
            </SearchBox>
            <div style={{ margin: "16px" }}>
              <CustomerTab>
                <Tab onClick={(e) => toggleHeader(e)} id="customers" chosen={chosenTab === TABS.CUSTOMERS} >Finn mottakere</Tab>
                <Tab onClick={(e) => toggleHeader(e)} id="chosenCustomers" chosen={chosenTab === TABS.CHOSEN_CUSTOMERS} >Valgte mottakere</Tab>
              </CustomerTab>

              <TableContainer>
                <StyledTableRow>
                  <HeaderCell>Navn</HeaderCell>
                  <HeaderCell>Adresse</HeaderCell>
                  <HeaderCell>Borettslag</HeaderCell>
                  <HeaderCell><div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>Velg</div></HeaderCell>
                </StyledTableRow>
                <SendFileList tab={chosenTab} sendFile={sendFilesToCustomers} chosenCustomers={chosenCustomers} addToCustomerList={addToCustomerList} customers={filteredCustomers} />
              </TableContainer>
            </div>
          </>
        )
      }
    </SearchContainer>
  )
};

CustomerSearchWidget.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    file: PropTypes.string,
    name: PropTypes.string
  })),
  resetCheckedFiles: PropTypes.func
};
