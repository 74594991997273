import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import config from 'config';

export const sanity = sanityClient({
  // Find your project ID and dataset in `sanity.json` in your studio project
  projectId: 'tq0aiy8z',
  dataset: config.sanityDataset || 'production',
  useCdn: true
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
});

const builder = imageUrlBuilder(sanity);
export const imageUrlFor = source => builder.image(source);

export { default as sanityApi } from './service';