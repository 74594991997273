import PropTypes from 'prop-types';
import { H1 } from 'components/elements';
import { styled, getStyle, useMediaQuery } from 'style';

const Container = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background: #BCBABB26;
  border-radius: 10px;
  margin-bottom: ${getStyle('spacing.xxxl')};
  padding: ${getStyle('spacing.lg')} ${getStyle('spacing.lg')};

  h1 {
    margin-bottom: ${getStyle('spacing.lg')};
  }
`;

const Title = styled(H1)`
  line-height: 2rem;
  span:first-child {
    font-weight: normal;
    color: ${getStyle('color.primary')};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: ${props => (props.isMobile ? 'wrap' : 'nowrap')};

  button {
    min-width:100px;
    margin-top: ${props => (props.isMobile ? getStyle('spacing.lg') : 'auto')};
  }
`;

const PageHeader = ({ title, subTitle, welcomeText }) => {
  const isMobile = useMediaQuery().isMobile();

  return (
    <Container>
      <div>
        {title && <Title><span>{title}</span> <span>{subTitle}</span></Title>}
      </div>
      <Content isMobile={isMobile}>
        {welcomeText}        
      </Content>
    </Container>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isPrivateCustomer: PropTypes.bool
};

export default PageHeader;
