import React from 'react';
import ReactDOM from 'react-dom';
import RouteManager from './RouteManager';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'services/auth';
import config from './config';
import { BrowserRouter } from "react-router-dom";
import { Theme } from 'style';
import './style/css/normalize.css';
import './style/css/reset.css';
import ScrollToTop from "./components/elements/ScrollToTop";

/* FIRST ENTRRY POINT. WRAP ALL GLOBAL STUFF HERE. KEEP IT SIMPLE */

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <ScrollToTop/>
      <AuthProvider client_id={config.authClientId} domain={config.authDomain} useRefreshTokens={true} cacheLocation={'localstorage'}>
        <Theme>
            <RouteManager />
        </Theme>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
