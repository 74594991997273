import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { allierApi, sanityApi } from 'services';
import { H1, BackButton } from 'components/elements';
import { BikeLoader } from 'components/loading';
import ProjectAttachmentWidget from './projectAttachment/ProjectAttachmentWidget';
import { ProjectWidget } from 'pages/projects';
import { getStyle, styled } from '../../style';

const Title = styled(H1)`
  line-height: 2rem;
  span:first-child {
    font-weight: normal;
    color: ${getStyle('color.secondary')};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getStyle('spacing.xl')};
`;

const useProjectData = () => {
  const { projectId = '', customerId = '' } = useParams();

  const [project, setProject] = useState({});
  const [customer, setCustomer] = useState({});
  const [phaseDescriptions, setPhaseDescriptions] = useState([]);

  const [loading, setLoading] = useState(true);
  const loaded = useRef(true);

  const getData = useCallback(async (showLoading = true) => {
    if (showLoading) setLoading(true);

    const [
      projectData,
      customerData,
      phaseDescriptionsData,
    ] = await Promise.all([
      allierApi.getProjectByCustomerId(customerId, projectId),
      allierApi.getUserProfile(customerId),
      sanityApi.fetchPhaseDescriptions(),
    ])
      .catch(console.error);

    if (loaded.current) {
      setProject(projectData);
      setCustomer(customerData);
      setPhaseDescriptions(phaseDescriptionsData || [])
      setLoading(false)
    }
  }, [customerId, projectId]);

  useEffect(() => {
    getData();

    return () => {
      loaded.current = false;
    };
  }, [getData, projectId, customerId]);

  return { project, customer, loading, refreshData: getData, phaseDescriptions };
};

const AccountManagerProjectPage = () => {
  const { project, customer, loading, refreshData, phaseDescriptions } = useProjectData();

  if (loading) return <BikeLoader />;

  return (
    <Container>
      <Title><span>Prosjektside,</span><br /><span>{`${customer.givenName} ${customer.familyName}`}</span></Title>
      <BackButton url={`/accountmanager/customer/${customer.id}`}>Tilbake til kundeside</BackButton>
      <ProjectWidget project={project} customer={customer} refreshData={refreshData} />
      <ProjectAttachmentWidget
        project={project}
        customerId={customer.id}
        refreshData={refreshData}
        phaseDescriptions={phaseDescriptions}
        accountManagerPage
      />
    </Container>
  );
}

export default AccountManagerProjectPage;
