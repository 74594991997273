import customers from "./customers";
import files from "./files";
import estimate from "./estimate";
import messages from "./messages";
import project from "./project";
import user from "./user";
import notifications from "./notifications";
import associations from './associations';
import tags from './tags';
import filetags from './filetags';

const service = {
  ...customers,
  ...files,
  ...estimate,
  ...messages,
  ...project,
  ...user,
  ...notifications,
  ...associations,
  ...tags,
  ...filetags,
}
export default service;
