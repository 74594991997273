import React, { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { allierApi, sanityApi } from 'services';
import { SelectFormField } from 'components/form';
import { Spinner } from 'components/loading';
import { AccordionWidget } from 'components/elements';
import { getStyle, styled } from 'style';
import { Container, CreateButton } from './AdminPageComponents';

const CreateAssociationContainer = styled(AccordionWidget)`
  margin-bottom: ${getStyle("spacing.xl")};
`;

const getAssociationList = async () => {
  const [sanityAssList = [], dbAssList = []] = await Promise.all([
    sanityApi.fetchAssociationList(),
    allierApi.getAssociations()
  ]);

  if (!sanityAssList.length) return [];
  return dbAssList.length
    ? sanityAssList
      .filter(({ id, label }) => (
        label !== 'Privat' && !dbAssList.some(({ sanityId }) => id === sanityId)
      ))
    : sanityAssList;
};

const Association = () => {
  const handleError = useErrorHandler();
  const [selectedAssId, setSelectedAssId] = useState('');
  const [availableAsses, setAvailableAsses] = useState([]);
  const [isAssSaving, setIsAssSaving] = useState(false);

  useEffect(() => {
    getAssociationList()
      .then(assList => {
        if (assList.length) {
          setAvailableAsses(assList);
          setSelectedAssId(assList[0].id);
        }
      })
      .catch(handleError);
  }, [handleError]);

  const handleCreateAss = async () => {
    setIsAssSaving(true);
    const selectedAss = availableAsses.filter(a => a.id === selectedAssId)[0];
    const assName = selectedAss.label.split(' ');

    const payload = {
      id: assName[0].toLowerCase(),
      title: assName[0],
      description: selectedAss.label,
      associationType: 'Borettslag',
      sanityId: selectedAss.id,
    }

    await allierApi.createAssociation(payload);
    getAssociationList().then(assList => {
      setAvailableAsses(assList);
      setSelectedAssId(assList.length ? assList[0].id : '');
    });
    setIsAssSaving(false);
  };

  return (
    <CreateAssociationContainer title="Opprett et borettslag" wide collapsed>
      <Container>
        { availableAsses && availableAsses.length > 0
          ?
          <>
            <SelectFormField
              key={"id"}
              label={"Velg borettslag"}
              name={"name"}
              onChange={(e) => setSelectedAssId(e.target.value)}
              value={selectedAssId}
              options={availableAsses}
            />

            <CreateButton disabled={isAssSaving} onClick={handleCreateAss}>
              { isAssSaving && <Spinner style={{left: '40px', position: 'absolute'}} /> }
              Opprett
            </CreateButton>
          </>
          : <div>
            <b>Ingen nye borettslag tilgjengelig i Sanity.</b>
            <p style={{marginTop: '5px'}}>Om De ønsker å opprette et nytt borettslag, kan De opprette et nytt under "Borettslag"-seksjonen i Sanity og laste denne siden på nytt.</p>
          </div>
        }
      </Container>
    </CreateAssociationContainer>
  );
};

export default Association;
