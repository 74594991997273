import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from 'services/auth';
import { allierApi } from 'services';
import { useErrorHandler } from 'react-error-boundary'
import { default as DocumentList } from './DocumentList';
import { default as TagsDialog } from 'components/dialog/TagsDialog';
import { WidgetContainer, AccordionWidget, Label, Select, Attachments, Button } from 'components/elements';
import { ReactComponent as DocumentsIcon } from 'assets/icons/documents.svg';
import { sortBy, prop } from 'ramda';
import { BikeLoader, Spinner } from 'components/loading';
import { styled, getStyle } from 'style';
import { SearchWidget } from 'components/elements/SearchWidget';

const Container = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${getStyle('size.input.width.lg')};
  margin-bottom: ${getStyle('spacing.xl')};
`;

const AttachmentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TagDialogButton = styled(Button)`
  height: 50px;
  width: 10%;
  margin-right: 20px;
`

const UploadButton = styled(Button)`
  height: 50px;
  width: 25%;
  margin-right: 20px;
`;

const DocumentsPage = ({ userIsAccountManager }) => {

  const { userId } = useAuth();
  const [files, setFiles] = useState(null);
  const [searchFiles, setSearchFiles] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [tagsDialogOpen, setTagsDialogOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [chosenTags, setChosenTags] = useState([]);
  const [searchTags, setSearchTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFileList, setIsLoadingFileList] = useState(false);
  const attachmentsRef = useRef(null);
  const handleError = useErrorHandler();

  const getCustomers = useCallback(() => {
    allierApi.getCustomers('?sort=givenName&size=1000').then(({ customers }) => setCustomers(customers));
  }, []);

  const getFiles = useCallback(async (id) => {
    setIsLoadingFileList(true);
    await allierApi.getFiles(id).then(res => {
      const searchTags = [
        ...new Map(res.files.map(f => f.tags.map(t => t)).flat().sort((a,b) => a.name.localeCompare(b.name))
          .map((st) => [st["id"], st])).values()
      ];

      setSearchTags(searchTags);
      setFiles(res.files);
      setIsLoadingFileList(false);
    });
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      const res = await allierApi.getTags();
      const formattedTags = res.tags.map(tag => ({ id: tag.id, name: tag.name, documentTag: false }));
      setTags(formattedTags);
    };

    fetchTags().catch(handleError);
  }, [handleError]);

  useEffect(() => {
    if (userIsAccountManager) getCustomers();
    else getFiles(userId);
  }, [getFiles, getCustomers, userIsAccountManager, userId]);

  const handleSearchFiles = (files) => {
    setSearchFiles(files);
  }

  const upload = () => {
    if (attachmentsRef?.current?.getAttachmentCount() > 0) {
      setIsLoading(true);
      attachmentsRef.current.uploadAttachments(selectedCustomerId)
        .then(() => {
          attachmentsRef.current.clearAttachments();
          getFiles(selectedCustomerId);
          setChosenTags([]);
          setIsLoading(false);
        });
    } else {
      alert("Du må velge en fil før du kan laste opp");
    }
  }

  const openTagsDialog = () => {
    setTagsDialogOpen(true);
  }

  const onCloseTags = (tags) => {
    setTags(tags);
    setTagsDialogOpen(false);
  }

  const onConfirmTags = (tags) => {
    setTags(tags);
    setChosenTags(tags.filter(tag => tag.documentTag));
    setTagsDialogOpen(false);
  }

  const documentListProps = {
    userId,
    deleteFile: fileId => allierApi.deleteFile(fileId),
    onChange: () => getFiles(userIsAccountManager ? selectedCustomerId : userId),
    userIsAccountManager: userIsAccountManager !== undefined && userIsAccountManager
  };

  let displayedFiles;

  if (files && !searchFiles) {
    displayedFiles = sortBy(prop('name'))(files || []);
  } else if (searchFiles && searchFiles.length > 0) {
    displayedFiles = sortBy(prop('name'))(searchFiles || []);
  }

  if ((!userIsAccountManager && files == null)) {
    return <BikeLoader />
  }

  return (
    <>
      <TopContainer>
        {files ? <SearchWidget files={files} tags={searchTags} handleSearchFiles={handleSearchFiles} selectedCustomerId={selectedCustomerId} handleSearching={() => {}}/> : null}

        {userIsAccountManager &&
          <Container>
            <Label htmlFor="customerSelect">Kunde</Label>
            <Select
              id="customerSelect"
              aria-label="Kunde"
              onChange={e => {
                setSearchFiles(null);
                getFiles(e.target.value);
                setSelectedCustomerId(e.target.value);

              }}
            >
              <option key="key" aria-label="Velg kunde" value="">Velg kunde...</option>
              {customers && customers.map(customer => (
                <option key={customer.email} value={customer.id}>
                  {customer.displayName()}
                </option>
              ))}
            </Select>
          </Container>
        }
      </TopContainer>
      <WidgetContainer>
        <AccordionWidget title="Dokumenter" icon={<DocumentsIcon />} customContainer wide>
          {isLoadingFileList && <Spinner style={{marginLeft:'49%'}} />}
          {displayedFiles ? (
            <DocumentList
              {...documentListProps}
              files={displayedFiles}
            />
          ) : null}
          {userIsAccountManager && selectedCustomerId &&
            <AttachmentContainer title="Last opp dokument til kunde">
              <div style={{ width: '100%', display: 'flex' }}><Attachments ref={attachmentsRef} tags={tags.filter(t => t.documentTag)}></Attachments></div>
              {isLoading && <Spinner style={{ marginTop: '10px', marginRight: '10px', height: '30px', width: '35px' }} />}
              <TagDialogButton variant="secondary" onClick={() => openTagsDialog()}>Tags</TagDialogButton>
              <UploadButton onClick={() => upload()} >Last opp</UploadButton>
            </AttachmentContainer>
          }
          <TagsDialog
            blockOpen={attachmentsRef?.current?.getAttachmentCount() < 1}
            blockText="Du må velge en fil før du kan sette tags"
            isDialogOpen={tagsDialogOpen}
            onCancel={onCloseTags}
            onConfirm={onConfirmTags}
            chosenTags={chosenTags}
          ></TagsDialog>
        </AccordionWidget>
      </WidgetContainer>
    </>
  );
}

export default DocumentsPage;
