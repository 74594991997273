import { styled, getStyle, responsive } from 'style';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${props => (props.hidden ? '0' : 'none')};
  margin-bottom: ${props => (props.hidden ? '0' : getStyle('spacing.xs'))};

  ${props => responsive('width', [
    {
      width: getStyle('screen.sm', props),
      value: props.hidden || props.wide
        ? '100%'
        : `calc(50% - ${getStyle('spacing.xxxl', props)})`
    }
  ])}
`;

export default FieldContainer;
