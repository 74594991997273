import PropTypes from 'prop-types';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { clone } from 'ramda';

const defaultTheme = {
  screen: {
    sm: 768,
    md: 1024,
    lg: 1216,
    xl: 1408
  },
  font: {
    sm: '0.8125rem', // 11px '0.6875rem'
    md: '1rem', // 13px
    lg: '1.2rem', // 16px
    xl: '1.4rem', // 18
    xxl: '1.6rem',
    heading: {
      sm: '0.8125rem',
      md: '1rem',
      lg: '1.125rem',
      xl: '1.375rem',
      xxl: '2.25rem'
    }
  },
  color: {
    primary: '#212121',
    secondary: '#898989',
    status: {
      disabled: '#898989',
      hover: '#F8F8F8',
      selected: '#6750A5',
      selectedBackground: '#F0EFEF',
      alert: '#FF5028',
      success: '#05634C',
      error: '#E02020',
      warn: '#C53B2B',
      test: 'orange'
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#FAFAFA',
      shadow: '0.2rem 0.2rem 0.5rem #0000000D',
      light: '#F2F2F2'
    },
    border: {
      primary: '#E0E0E0',
      secondary: '#898989',
      theme: '#AEA0CD' // purple
    },
    header: {
      foreground: '#212121',
      background: '#FFFFFF'
    },
    menu: {
      icon: '#898989'
    },
    widget: {
      header: '#553B72',
      subtitle: 'rgba(0, 0, 0, 0.7)',
      info: '#EDF3F8',
      li: {
        background: 'rgba(196, 196, 196, 0.1) '
      },
      th: {
        background: '#5B6E7C1A'
      },
      tr: {
        background: '#D8D8D81A'
      }
    },
    badge: {
      info: '#FFFFFF',
      background: {
        info: '#0000004D'
      }
    },
    button: {
      primary: '#FFFFFF',
      secondary: '#6750A5',
      background: {
        primary: '#6750A5',
        secondary: '#FFFFFF',
        hover: '#553B72'
      }
    },
    form: {
      header: '#666666'
    },
    input: {
      form: 'none',
      focused: {
        form: '#8383834D'
      }
    },
    progress: {
      foreground: '#449D53',
      background: '#64D265'
    },
    slider: {
      selected: {
        foreground: '#8D77CA',
        background: '#553B72'
      },
      background: '#EBE7F2',
      foregroundDot: '#553B72',
      current: '#FFBD2E'
    }
  },
  border: {
    radius: {
      sm: '0.3125rem',
      md: '0.625rem',
      lg: '1.25rem'
    }
  },
  size: {
    header: {
      height: '4rem'
    },
    menu: {
      width: {
        sm: '4rem',
        md: '13rem'
      }
    },
    overlay: {
      notification: {
        width: '30rem'
      },
      profile: {
        width: '10rem'
      },
      li: {
        height: '4rem'
      }
    },
    widget: {
      header: {
        height: '4rem'
      },
      li: {
        height: '4rem'
      }
    },
    element: {
      minWidth: '2rem',
      minHeight: '3rem'
    },
    button: {
      height: '3.125rem',
      width: {
        sm: '10rem',
        md: '15rem',
        lg: '20rem'
      }
    },
    icon: {
      sm: '1rem',
      md: '1.2rem',
      lg: '2rem'
    },
    input: {
      height: '3rem',
      width: {
        sm: '10rem',
        md: '15rem',
        lg: '20rem'
      }
    },
    progress: {
      md: '0.5rem'
    }
  },
  spacing: {
    xs: '0.5rem',
    sm: '0.625rem',
    md: '0.8125rem',
    lg: '1rem',
    xl: '1.25rem',
    xxl: '1.5rem',
    xxxl: '2rem'
  }
};

const employeeTheme = () => {
  const theme = clone(defaultTheme);
  theme.color.header.foreground = '#FFFFFF';
  theme.color.header.background = '#000000';
  return theme;
};

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    font-family: Arial;
    line-height: 1.2;
    background: ${defaultTheme.color.background.secondary};
    color: ${defaultTheme.color.primary};
    stroke: ${defaultTheme.color.primary};
    fill: ${defaultTheme.color.primary};
    overflow-x: hidden;
  }

  html, body, #__next {
    width: 100vw;
    height: 100%;
  }
`;

const Theme = ({ isEmployee, children }) => {
  const theme = isEmployee ? employeeTheme() : defaultTheme;
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </>
  );
};

Theme.propTypes = {
  isEmployee: PropTypes.bool,
  children: PropTypes.element.isRequired
};

export default Theme;
