import { styled, getStyle, responsive } from 'style';

const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  ${props => responsive('flex-direction', [
    { width: getStyle('screen.sm', props), value: 'unset' }
  ])}
  ${props => responsive('flex-wrap', [
    { width: getStyle('screen.sm', props), value: 'wrap' }
  ])}

  button {
    margin-top: ${getStyle('spacing.xl')};
    width: 100%;

  ${props => responsive('width', [
    {
      width: getStyle('screen.sm', props),
      value: `calc(50% - ${getStyle('spacing.xxxl', props)})`
    }
  ])}

    &:nth-child(even) {
  ${props => responsive('margin-left', [
    {
      width: getStyle('screen.sm', props),
      value: `calc(2 * ${getStyle('spacing.xxxl', props)})`
    }
  ])}
    }
  }
`;

export default FormActions;
