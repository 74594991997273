import PropTypes from 'prop-types';
import { AccordionWidget, ButtonContainer } from 'components/elements';
import { useDialog } from 'components/dialog';
import { Button, UploadButton } from 'components/elements';
import ProjectAttachmentCarousel from './ProjectAttachmentCarousel';
import ProjectAttachmentDialog from './ProjectAttachmentDialog';
import { ImageIcon, AddIcon } from 'assets/icons';
import { getStyle, styled } from 'style';
import { useCallback, useState } from 'react';
import { scaleToWidth } from 'services/image';
import ProjectAttachmentForm from './ProjectAttachmentForm';
import config from "../../../config";

const ACCEPTED_FILETYPES = 'image/jpeg,image/png,video/mp4,video/webm,video/quicktime';

const AttachmentPlaceholder = styled.div`
  width: 30%;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${getStyle('border.radius.sm')};
    background: ${getStyle('color.background.secondary')};
    width: 100%;
    height: 10rem;

    svg {
      width: ${getStyle('size.icon.md')};
    }
  }
`;

const ProjectAttachmentWidget = ({ project, customerId, refreshData, accountManagerPage, phaseDescriptions }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const [newAttachment, setNewAttachment] = useState();
  const [editAttachment, setEditAttachment] = useState();

  const onCancel = useCallback(() => {
    setEditAttachment(undefined);
    setNewAttachment(undefined);
  }, []);

  const onFilesChanged = useCallback(files => {
    if (files[0].type.includes('video')) {
      setNewAttachment(files[0]);
    } else {
      scaleToWidth(files[0], 600).then(setNewAttachment);
    }
  }, []);

  const onProjectEdit = useCallback(attachment => {
    setEditAttachment(attachment);
  }, []);

  if (!accountManagerPage && !project.attachments.length) return null;

  return (
    <AccordionWidget title="Bilder og video fra prosjektet" icon={<ImageIcon />} wide>
      {(
        (newAttachment || editAttachment)
          ? <ProjectAttachmentForm
            attachment={newAttachment || editAttachment}
            attachmentId={editAttachment?.id}
            acceptFiles={ACCEPTED_FILETYPES}
            project={project}
            onCancel={onCancel}
            customerId={customerId}
            refreshData={refreshData}
            onFilesChanged={onFilesChanged}
            phaseDescriptions={phaseDescriptions}
          />
          : (
            <>
              {(project.attachments.length
                ? (
                  <ProjectAttachmentCarousel
                    accountManagerPage={accountManagerPage}
                    project={project}
                    onProjectEdit={onProjectEdit}
                  />
                )
                : (
                  <AttachmentPlaceholder>
                    <UploadButton
                      variant="inline"
                      accept={ACCEPTED_FILETYPES}
                      onFilesChanged={onFilesChanged}
                      maxSize={config.fileUploadLimitBytes}
                    >
                      <AddIcon />
                      <span>Legg til bilde eller video</span>
                    </UploadButton>
                  </AttachmentPlaceholder>
                )
              )}
              <ButtonContainer>
                {
                  project.attachments.length
                    ? (
                      <>
                        <Button variant="secondary" onClick={openDialog}>
                          Se flere bilder og videoer fra prosjektet
                        </Button>
                        <ProjectAttachmentDialog
                          accountManagerPage={accountManagerPage}
                          project={project}
                          isOpen={isDialogOpen}
                          onClose={closeDialog}
                          onProjectEdit={onProjectEdit}
                        />
                      </>
                    ) : null
                }
                {
                  accountManagerPage
                    ? <UploadButton accept={ACCEPTED_FILETYPES} onFilesChanged={onFilesChanged}>
                      Legg til bilde eller video
                    </UploadButton>
                    : null
                }

              </ButtonContainer>
            </>
          )
      )}
    </AccordionWidget>
  );
};

ProjectAttachmentWidget.defaultProps = {
  customerId: '',
  refreshData: () => {},
  editable: false,
}

ProjectAttachmentWidget.propTypes = {
  project: PropTypes.object.isRequired,
  customerId: PropTypes.string,
  refreshData: PropTypes.func,
  editable: PropTypes.bool,
};

export default ProjectAttachmentWidget;
