import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { styled } from 'style';

const applyDownload = (url, filename) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;
  anchor.click();
  // document.body.removeChild(anchor);
};

const onClick = (filename, download, onError) => (
  download()
    .then(file => {
      if (typeof file === 'string' || file instanceof String) {
        applyDownload(file, filename);
        return;
      }
      const blob = new Blob([file.content]);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE / Edge
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Sane browsers
        const url = URL.createObjectURL(blob);
        applyDownload(url, filename);
        URL.revokeObjectURL(url);
      }
    })
    .catch(onError)
);

const DownloadButton = styled.button`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-decoration: underline;
  text-overflow: ellipsis;
  outline: none;
`;

export const FileLink = ({
  children, filename, download, onError, style
}) => (
  children
    ? (
      cloneElement(children, {
        type: 'button',
        role: 'link',
        onClick: () => onClick(filename, download, onError)
      })
    )
    : (
      <DownloadButton
        type="button"
        role="link"
        onClick={() => onClick(filename, download, onError)}
        style={style}
      >
        {filename}
      </DownloadButton>
    )
);

FileLink.propTypes = {
  children: PropTypes.object,
  filename: PropTypes.string.isRequired,
  download: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export default FileLink;
