import { styled, getStyle, responsive } from 'style';

export const PageOverlay = styled.div`
  position: absolute;
  top: ${getStyle('size.header.height')};
  left: 0;
  width: 100%;
  height: calc(100vh - ${getStyle('size.header.height')});
  z-index: 1;
`;

const Overlay = styled(PageOverlay)`
  ${props => responsive('position', [
    { width: getStyle('screen.sm', props), value: 'relative;' }
  ])}
  ${props => responsive('top', [
    { width: getStyle('screen.sm', props), value: getStyle('size.header.height', props) }
  ])}
  ${props => responsive('width', [
    { width: getStyle('screen.sm', props), value: '0;' }
  ])}
  ${props => responsive('height', [
    { width: getStyle('screen.sm', props), value: '0;' }
  ])}
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  color: ${getStyle('color.primary')};
  stroke: ${getStyle('color.primary')};
  fill: ${getStyle('color.primary')};
  background: ${getStyle('color.background.primary')};
  border-style: solid;
  border-color: ${getStyle('color.border.primary')};
  border-width: 0 0.0625rem;

  ${props => responsive('height', [
    { width: getStyle('screen.sm', props), value: 'auto;' }
  ])}
  ${props => responsive('background', [
    { width: getStyle('screen.sm', props), value: getStyle('color.background.secondary', props) }
  ])}
  ${props => responsive('box-shadow', [
    { width: getStyle('screen.sm', props), value: getStyle('color.background.shadow', props) }
  ])}
`;

const NotificationOverlay = styled(Overlay)`
  ${props => responsive('left', [
    { width: getStyle('screen.sm', props), value: `-${getStyle('size.overlay.notification.width', props)};` }
  ])}
`;

const NotificationContainer = styled(Container)`
  ${props => responsive('margin-left', [
    { width: getStyle('screen.sm', props), value: '-1rem;' }
  ])}
  ${props => responsive('width', [
    { width: getStyle('screen.sm', props), value: getStyle('size.overlay.notification.width', props) }
  ])}
`;

export const renderNotificationOverlay = children => (
  <NotificationOverlay>
    <NotificationContainer>
      {children}
    </NotificationContainer>
  </NotificationOverlay>
);

const ProfileOverlay = styled(Overlay)`
  ${props => responsive('left', [
    { width: getStyle('screen.sm', props), value: `-${getStyle('size.overlay.profile.width', props)};` }
  ])}
`;

const ProfileContainer = styled(Container)`
  ${props => responsive('margin-left', [
    { width: getStyle('screen.sm', props), value: '-0.5rem;' }
  ])}
  ${props => responsive('width', [
    { width: getStyle('screen.sm', props), value: getStyle('size.overlay.profile.width', props) }
  ])}
`;

export const renderProfileOverlay = children => (
  <ProfileOverlay>
    <ProfileContainer>
      {children}
    </ProfileContainer>
  </ProfileOverlay>
);
