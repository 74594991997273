import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "components/elements";
import { styled, getStyle, responsive } from "style";
import AnimateHeight from "react-animate-height";
import { ExpandIcon } from "assets/icons";

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: ${getStyle("font.md")};
  background: ${(props) =>
    props.isSelected ? getStyle("color.status.selectedBackground") : "auto"};
  stroke: ${(props) =>
    props.isSelected
      ? getStyle("color.status.selected")
      : getStyle("color.menu.icon")};
  fill: ${(props) =>
    props.isSelected
      ? getStyle("color.status.selected")
      : getStyle("color.menu.icon")};
  height: ${getStyle("size.element.minHeight")};
  padding-left: ${getStyle("spacing.lg")};
  margin-top: ${getStyle("spacing.xl")};
  margin-bottom: ${getStyle("spacing.xl")};
  cursor: pointer;

  transition: min-height 0.3s ease-in-out;
  min-height: 20px;
  ${(props) =>
    props.expanded 
      ?`
        transition: min-height .3s ease-in-out;
        min-height: 200px;
      `
      : ''};

  :hover {
    background: ${(props) =>
      props.isSelected
        ? getStyle("color.status.selectedBackground")
        : getStyle("color.status.hover")};
    cursor: pointer;
  }

  svg {
    height: ${getStyle("size.icon.md")};
    margin-right: ${getStyle("spacing.xl")};
  }

  ${(props) =>
    responsive("padding-left", [
      {
        width: getStyle("screen.sm", props),
        value: `${getStyle("spacing.xl", props)}`,
      },
      {
        width: getStyle("screen.md", props),
        value: `${getStyle("spacing.xxxl", props)}`,
      },
    ])}
`;

const ItemText = styled.span`
  display: inline;
  pointer-events: none;

  ${(props) =>
    responsive("display", [
      { width: getStyle("screen.sm", props), value: "none" },
      { width: getStyle("screen.md", props), value: "inline" },
    ])}
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0;

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    display: block;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 8em;
`;

const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  width: 9em;

  button {
    margin-bottom: 10px;

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      z-index: 1;
    }
  }
`;

const OpenPanelIcon = styled(ExpandIcon)`
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;

  ${(props) =>
    props.expanded === 'true'
      ? 'transform: rotate(90deg);'
      : ''
  };

  ${(props) =>
    responsive("display", [
      { width: getStyle("screen.sm", props), value: "none" },
      { width: getStyle("screen.md", props), value: "inline" },
    ])}
`;

const DropdownMenu = ({ icon, initElement, elementList, selectionHandler, title }) => {
  const [selectedElement, setSelectedElement] = useState(initElement);
  const [height, setHeight] = useState(0);

  const handleSelection = (target) => {
    setHeight(0);
    setSelectedElement(target.value);
    selectionHandler(target.value);
  };

  return (
    <ItemContainer
      expanded={height === "auto"}
      onClick={() => setHeight(prevState => prevState === 0 ? "auto" : 0)}
    >
      <ItemContent>
        {icon}
        <ContentContainer>
          <ItemText>{title || selectedElement}</ItemText>
          <OpenPanelIcon expanded={(height === "auto").toString()} />
        </ContentContainer>
      </ItemContent>
      <div>
        <AnimateHeight id="ExpandedPanel" duration={350} height={height}>
          <PanelContent>
            {elementList.map((el) => (
              <Button
                key={el.title}
                value={el.title}
                onClick={(e) => {
                  handleSelection(e.target);
                }}
              >
                {el.title}
              </Button>
            ))}
          </PanelContent>
        </AnimateHeight>
      </div>
    </ItemContainer>
  );
};

DropdownMenu.defaultProps = {
  title: '',
  initElement: '',
};

DropdownMenu.propTypes = {
  icon: PropTypes.object.isRequired,
  initElement: PropTypes.string,
  elementList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectionHandler: PropTypes.func,
  title: PropTypes.string,
};

export default DropdownMenu;
