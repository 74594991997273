import { styled, getStyle } from 'style';

export const Label = styled.label`
  font-size: ${getStyle('font.md')};
  font-weight: bold;
  color:  ${props => (props.primary ? getStyle('color.primary') : getStyle('color.secondary'))};
  min-height: 1.5rem;
`;

export default Label;
