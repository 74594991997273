/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { AccordionWidget } from 'components/elements';
import { styled, getStyle, responsive } from 'style';
import { DocumentsIcon, LayoutImage } from 'assets/icons';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  ${props => responsive('grid-template-columns', [
  {
    width: getStyle('screen.sm', props),
    value: '50% 50%'
  }
])}
`;

const StyledAccordian = styled(AccordionWidget)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px !important;
  width: 100%;
`;

const Document = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DocumentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LinkContainer = styled.div`
  margin-top: ${getStyle('spacing.md')};
  font-size: ${getStyle('font.heading.md')};
  text-align: center;
`;

const DocumentPost = ({ post, wide }) => (
  <Grid>
    <StyledAccordian title={post.title} icon={<DocumentsIcon />} wide>
      <DocumentContainer>
        {
          post.files && post.files.map(item => (
            <Document key={item.asset.url}>
              <a href={`${item.asset.url}`} target="_blank" rel="noreferrer"><LayoutImage /> </a>
              <LinkContainer key={item.asset.originalFilename}><a href={`${item.asset.url}`} target="_blank" rel="noreferrer">{item.asset.originalFilename.split('.')[0]}</a></LinkContainer>
            </Document>
          ))
        }
      </DocumentContainer>
    </StyledAccordian>
  </Grid>
);

DocumentPost.propTypes = {
  post: PropTypes.object.isRequired,
  wide: PropTypes.bool
};

export default DocumentPost;
