import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { AccordionWidget, FieldList, ButtonContainer, Button } from 'components/elements';
import { PhoneIcon, MessagesIcon } from 'assets/icons';

const AccountManagerWidget = ({ manager, buttonTitle, addEmptySpace }) => {
  const navigate = useNavigate();
  return (
    <AccordionWidget title={manager.displayName()} subtitle="Kundeveileder" icon={<MessagesIcon />}>
      <FieldList
        fields={[
          { type: 'tel', label: 'Telefonnummer', value: manager.phone, icon: <PhoneIcon /> },
          ...(addEmptySpace ? [{ type: 'empty', label: '', value: '' }] : []),
        ]}
      />
      <ButtonContainer>
        <Button role="link" onClick={() => navigate('/messages')}>
          {buttonTitle}
        </Button>
      </ButtonContainer>
    </AccordionWidget>
  );
};

AccountManagerWidget.defaultProps = {
  buttonTitle: 'Send melding',
  addEmptySpace: false,
};

AccountManagerWidget.propTypes = {
  manager: PropTypes.object.isRequired,
  buttonTitle: PropTypes.string,
  addEmptySpace: PropTypes.bool,
};

export default AccountManagerWidget;
