import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { sanity, imageUrlFor, sanityApi } from 'services/sanity';
import { useAuth, isEmployee } from 'services/auth';
import { styled, getStyle, responsive } from 'style';

import {
  H1, Text, Button, WidgetContainer, PhaseStepper
} from 'components/elements';
import { BikeLoader } from 'components/loading';
import {
  PostContainer, QuotePost, ReadMorePost, Card
} from 'pages/blog';

import { Navbar } from 'components/layout';

const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
`;

const Image = styled.div`
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  background-image: url('${props => props.url}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  z-index: 1;
`;

const Section = styled.div`
  position: relative;
  width:100%;
  background: ${props => (props.light ? getStyle('color.background.light') : 'transparent')};
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: ${getStyle('spacing.xl')};
  padding-bottom: ${getStyle('spacing.xxl')};
`;

const SomeTitle = styled.div`
  text-align: center;
`;

const Padding = styled.div`
  padding: 3em;
  text-align: center;
`;

const BigSection = styled(Section)`
  padding: 0;
  margin-top: 3em;
  margin-bottom: 2em;
  max-width: 90vw;
  ${props => responsive('max-width', [
  {
    width: getStyle('screen.sm', props),
    value: '70vw'
  }
])}
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  min-height: 30vh; 
  background: #fff;
  padding: 5em ${getStyle('spacing.xxl')};
  border-radius: 10px;
`;

const CenterSection = styled(Section)`
  text-align: center;
  min-height: 50vh;
`;

const StyledH1 = styled(H1)`
  z-index: 2;
  color: ${props => (props.white ? '#fff' : '#000')};
  font-size: ${getStyle('font.heading.xxl')};
`;
const StyledText = styled(Text)`
  z-index: 2;
  color: #fff;
  font-size: ${getStyle('font.heading.xl')};
`;

const PaddedText = styled(Text)`
  padding: 1em;
`;

const StyledWidgetContainer = styled(WidgetContainer)`
  width: 100%;
  margin: 0 auto;
  max-width: 90vw;
  ${props => responsive('max-width', [
  {
    width: getStyle('screen.sm', props),
    value: '70vw'
  }
])}
`;

const Home = () => {
  let navigate = useNavigate();
  const { login, isAuthenticated, user } = useAuth();
  const [references, setReferences] = useState(null);
  const [allier, setAllier] = useState(null);
  const [quotes, setQuotes] = useState(null);
  const [assInfo, setAssInfo] = useState([]);

  useEffect(() => {
    sanityApi.fetchAllierPublic().then(res => {
      setReferences(res.references);
      setQuotes(res.quotes);
      setAllier(res.allier);
    });

    sanityApi.fetchAssociationInformationList().then(res => {
      setAssInfo(res);
    });
  }, []);

  if (allier == null || references == null || quotes == null) {
    return <BikeLoader />;
  }

  if(isAuthenticated){
    navigate(isEmployee(user) ? "/accountmanager/dashboard" : "/dashboard");
  }

  return (
    <>
      <Navbar />
      <LoginContainer>
        <CenterSection>
          <Image url={imageUrlFor(allier.mainImage.asset.url).url()} alt="Hovedbilde" />
          <StyledH1 white>{allier.title}</StyledH1>
          <StyledText white>{allier.subtitle}</StyledText>
        </CenterSection>
        <Section light>
          <Padding>
            Velg ditt borettslag
          </Padding>
          <StyledWidgetContainer>
            { assInfo.map(ass => (
              <Card onclickFunc={ () => login()} key={ass.id} title={ass.label} sanity={sanity} text="" image={<img onClick={() => login()} style={{cursor: 'pointer'}} src={ass.picture} alt={ass.label} title={ass.label} />} />
            ))}
          </StyledWidgetContainer>
        </Section>
        <BigSection>
          <Panel>
            <SomeTitle>
              <StyledH1>Om prosessen</StyledH1>
              <PaddedText>Her vil dere finne relevant informasjon om hva som skal
                skje når din leilighet rehabiliteres.
              </PaddedText>
            </SomeTitle>

            <PhaseStepper
              phases={allier.checklist
                .map(item => ({ id: item._key, title: item.title, blocks: item.description }))}
              wide
            />
          </Panel>
        </BigSection>
        <Section>
          <StyledWidgetContainer>
            <Card title="Allier" sanity={sanity} text="Les mer om Allier gruppen" image={<img src={allier.aboutAllierImage ? imageUrlFor(allier.aboutAllierImage.asset.url).url() : "#"} alt="Allier gruppen" title="Allier gruppen" />}>
              <Button role="link" onClick={() => navigate('/articles/allier')}>
                Les mer
              </Button>
            </Card>
            <Card title="Unik" sanity={sanity} text="Les mer om Unik" image={<img src={allier.aboutUnikImage ? imageUrlFor(allier.aboutUnikImage.asset.url).url() : "#"} alt="Unik" title="Unik" />}>
              <Button role="link" onClick={() => navigate('/articles/unik')}>
                Les mer
              </Button>
            </Card>
          </StyledWidgetContainer>
        </Section>
        <Section>
          <StyledWidgetContainer>
            <PostContainer title="Referanser / fagportefolie" radius>
              {references.map(reference => (
                <ReadMorePost
                  key={reference._id}
                  descriptionText={reference.intro}
                  title={reference.title}
                  slug={`articles/${reference.slug.current}`}
                  buttonText="Les mer"
                  variant="secondary"
                />
              ))}
            </PostContainer>
          </StyledWidgetContainer>
        </Section>
        <Section>
          <StyledWidgetContainer>
            <PostContainer title="Omtaler" radius>
              {quotes.map(quote => (
                <QuotePost
                  key={quote._id}
                  blocks={quote.content}
                  quotee={quote.quotee}
                />
              ))}
            </PostContainer>
          </StyledWidgetContainer>
        </Section>
      </LoginContainer>
    </>
  );
}

export default Home;
