import PropTypes from 'prop-types';
import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { styled } from 'style';

import FormActions from './FormActions';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Form = ({
  children,
  renderActions,
  enableReinitialize,
  initialValues,
  validationSchema,
  validate,
  submit
}) => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    validate={validate}
    onSubmit={submit}
  >
    {({
      setfieldvalue,
      isSubmitting,
      dirty,
      values,
      validateForm,
      setFieldTouched,
      handleSubmit,
      handleReset
    }) => {
      const actions = renderActions && renderActions({
        isSubmitting,
        dirty,
        values,
        validateForm: () => {
          Object.keys(values).map(field => setFieldTouched(field));
          return validateForm().then(errors => Object.keys(errors).length === 0);
        },
        submitForm: handleSubmit,
        resetForm: handleReset
      });
      return (
        <FormikForm role="form">
          <Container>
            {React.Children.map(
              children,
              child => React.cloneElement(child, { setfieldvalue })
            )}
            {actions && (
              <FormActions>
                {actions}
              </FormActions>
            )}
          </Container>
        </FormikForm>
      );
    }}
  </Formik>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  renderActions: PropTypes.func,
  enableReinitialize: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  validate: PropTypes.func,
  submit: PropTypes.func.isRequired
};

export default Form;
