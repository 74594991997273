const load = file => (
  new Promise(resolve => {
    const img = document.createElement('img');
    img.onload = () => resolve(img);

    return new Promise(read => {
      const reader = new FileReader();
      reader.onload = e => read(e.target.result);
      reader.readAsDataURL(file);
    })
      .then(url => { img.src = url; });
  })
);

const render = file => (
  load(file).then(img => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);
    return canvas;
  })
);

const scale = (canvas, factor) => {
  const scaled = document.createElement('canvas');
  scaled.width = canvas.width * factor;
  scaled.height = canvas.height * factor;
  scaled
    .getContext('2d')
    .drawImage(canvas, 0, 0, scaled.width, scaled.height);

  return scaled;
};

const toBlob = (canvas, name) => (
  new Promise(resolve => {
    canvas.toBlob(
      blob => {
        blob.name = `${name.split('.')[0]}.jpeg`; // eslint-disable-line
        resolve(blob);
      },
      'image/jpeg'
    );
  })
);

export const scaleToWidth = (file, width) => (
  render(file)
    .then(canvas => scale(canvas, width / canvas.width))
    .then(canvas => toBlob(canvas, file.name))
);
