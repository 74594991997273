import React from 'react';
import PropTypes from 'prop-types';
import { Cell, TableRow } from 'components/elements';
import { RemoveIcon, SuccessIcon } from 'assets/icons';
import { styled } from 'style';

export const TableCell = styled(Cell)`
  text-overflow: ellipsis;
  height: 49px;
  white-space: nowrap;
  overflow: hidden;
  padding: 1rem .2rem;
  border-radius: 5px;
  border: ${({ hasError, isIcon }) => hasError && !isIcon ? 'solid red 1px' : 'none'};
  ${({ isIcon }) => isIcon ? 'width: 18rem !important;' : ''}

  :hover::before {
    position: absolute;
    margin: calc(-.5rem - 1px);
    padding: .5rem;
    border: 1px black solid;
    border-radius: 5px;
    width: fit-content;

    background-color: ${({ isHeader }) => isHeader ? '#eef0f2' : 'white'};
    color: ${({ hasError }) => hasError ? 'red' : 'black'};
    content: '${({ content }) => content}';
    pointer-events: none;
  }
`;

const Ok = styled(SuccessIcon)`
  width: 2rem;
  margin-top: -8px;
`;

const Failed = styled(RemoveIcon)`
  width: 2rem;
  margin-top: -8px;
  stroke: red;
`;

const toUpperFirstCharacter = (str = '') => {
  if (!str || typeof str !== 'string') return str;
  const [first, ...rest] = str.split('') || ['', ''];
  return `${first.toLocaleUpperCase()}${rest.join('')}`;
}

const UserTableRows = ({ profile: { errors = {}, importStatus = {}, ...profile }, tableColumns }) => (
  <TableRow style={{ position: 'relative' }}>
    {
      tableColumns.map(({ name }) => name === 'importStatus' ? (
        <TableCell
          isIcon
          key={name}
          hasError={!importStatus.ok}
          content={importStatus.ok ? `${profile.givenName} ${profile.familyName} har blitt importert` : importStatus.errorMsg?.split('\'')?.join('"')}
        >
          { importStatus.ok ? <Ok /> : <Failed /> }
        </TableCell>
      ) : (
        <TableCell
          key={name}
          hasError={!!errors[name]}
          content={errors[name] || toUpperFirstCharacter(profile[name])}
        >
          {profile[name]}
        </TableCell>
      ))
    }
  </TableRow>
);

UserTableRows.defaultProps = {
  profile: {},
  tableColumns: [],
};

UserTableRows.propTypes = {
  profile: PropTypes.shape({
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    streetAddress: PropTypes.string,
    zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    city: PropTypes.string,
    dwellingNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    electronicInvoice: PropTypes.string,
  }),
  tableColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })),
};

export default UserTableRows;