import { styled, getStyle, responsive } from 'style';

import ContentContainer from './ContentContainer';

const WrapContainer = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  > * {
    margin-top: ${getStyle('spacing.md')};
  }
`;

export default WrapContainer;
