import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "style";
import { Label } from "components/elements";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
`;

const SearchField = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`;

const StyledFormField = styled.input`
  width: 13rem;
  height: 40px;
  border: 1px solid #6750A5;
  border-radius: 0.3125rem;
  margin-bottom: 0.5rem;
`;

const StyledSelect = styled.select`
  width: 6rem;
  height: 40px;
  border: 1px solid #6750A5;
  border-radius: 0.3125rem;
`;

export const SearchWidget = ({ tags, files, handleSearchFiles, selectedCustomerId, handleSearching }) => {
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const [type, setType] = useState("");
  const [fileTypes, setFileTypes] = useState([]);


  useEffect(() => {
    extractFileTypes(files);
  }, [files]);

  useEffect(() => {
    resetSearchCriteria();
  }, [selectedCustomerId]);

  useEffect(() => {
    filterFiles();
  }, [search, tag, type]);

  useEffect(() => {
    if (search || tag || type) {
      handleSearching(true);
    } else {
      handleSearching(false);
    }
  }, [search, tag, type]);
  

  const resetSearchCriteria = () => {
    setTag("");
    setType("");
    setSearch("");
  }

  const filterFiles = () => {
    const matchingFiles = files?.filter(isFileMatchingSearchCriteria);
    handleSearchFiles(matchingFiles);
  }

  const isFileMatchingSearchCriteria = (file) => {
    const normalizedSearch = search.replace(/\s/g, '').toUpperCase();
    return file.name.replace(/\s/g, '').toUpperCase().includes(normalizedSearch) &&
      (tag ? file.tags?.some(x => x.id === tag) : true) &&
      (type ? file.name.toUpperCase().includes(type) : true);
  }

  const extractFileTypes = (files) => {
    const uniqueFileTypes = new Set(files?.map(file => {
      const extension = file.name.split('.').pop();
      return extension.toUpperCase();
    }));
    setFileTypes(Array.from(uniqueFileTypes));
  }

  return (
    <StyledDiv>
      <SearchField>
        <Label htmlFor="search">Søk</Label>
        <StyledFormField 
          type="text" 
          placeholder="Søk etter fil" 
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </SearchField>
      <SearchField>
        <Label htmlFor="tagSelect">Tag</Label>
        <StyledSelect
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        >
          <option value="">Alle</option>
          {tags?.map(tag => <option key={tag.id} value={tag.id}>{tag.name}</option>)}
        </StyledSelect>
      </SearchField>

      <SearchField>
        <Label htmlFor="typeSelect">Type</Label>
        <StyledSelect 
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="">Alle</option> 
          {fileTypes?.map(type => <option key={type} value={type}>{type}</option>)}
        </StyledSelect>
      </SearchField>
    </StyledDiv>
  )
};

SearchWidget.propTypes = {
  tags: PropTypes.arrayOf(
      PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
      })
  ).isRequired,
  files: PropTypes.arrayOf(
      PropTypes.shape({
          name: PropTypes.string.isRequired,
          tags: PropTypes.arrayOf(
              PropTypes.shape({
                  id: PropTypes.string.isRequired,
                  name: PropTypes.string.isRequired
              })
          )
      })
  ).isRequired,
  handleSearchFiles: PropTypes.func.isRequired,
  selectedCustomerId: PropTypes.string,
  handleSearching: PropTypes.func.isRequired
};
