import React from 'react';
import { getStyle, styled } from 'style';

const TabButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
  background-color: white;
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  box-shadow: 0.2rem 0.2rem 0.5rem #0000000D;
`;

const TabButton = styled.button`
  border-bottom: 4px solid ${getStyle('color.status.disabled')};
  padding: 14px 8px 10px;
  display: inline-block;
  letter-spacing: 1px;
  
  &:hover {
    border-bottom: 4px solid ${getStyle('color.status.selected')};
  }
  
  &.selected {
    border-bottom: 4px solid ${getStyle('color.status.selected')};
    font-weight: 700;
    letter-spacing: 0;
  }

  &::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    letter-spacing: 1px;
  }
`;

const Tabs = ({ children }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const titles = (!Array.isArray(children) ? [children] : children).map((child) => ({ title: child.props.title, key: child.key }));

  return (
    <>
      <TabButtonContainer>
        {
          titles.map(({ title, key }, index) => (
            <TabButton
              className={index === selectedTab ? 'selected' : ''}
              key={`title-${key}`}
              title={title}
              onClick={() => setSelectedTab(index)}
            >
              {title}
            </TabButton>)
          )
        }
      </TabButtonContainer>
      {
        (!Array.isArray(children) ? [children] : children).map((child, index) => (
          <div key={`content-${child.key}`} style={{ display: index === selectedTab ? 'block' : 'none' }}>
            {child}
          </div>
        ))
      }
    </>
  );
};

Tabs.defaultProps = {
  children: null,
};

Tabs.propTypes = {
  children: ({ children }) => {
    if ((!Array.isArray(children) ? [children] : children).some(({ props, key }) => !props.title || !key)) {
      return new Error('Tab component must have a title and a key');
    }

    if ((!Array.isArray(children) ? [children] : children).some((child) => child.$$typeof !== Symbol.for('react.element'))) {
      return new Error('Tab component must be a react element');
    }
  },
};

export default Tabs;
