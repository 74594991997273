import { styled } from 'style';

export const Spinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #424242;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
