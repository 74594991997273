import { httpGet, jsonOrThrow } from 'services/common';
import config from 'config';
import { httpPost } from "./http";

const getAssociationsUrl = () => (
  `${config.apiHost}/api/associations`
);

const service = {
  getAssociations: () => (
    httpGet(getAssociationsUrl())
      .then(jsonOrThrow)
    ),

  getAssociationsById: (id) => (
    httpGet(getAssociationsUrl())
      .then(jsonOrThrow)
      .then((associations) => associations?.find(association => association.id === id) || {})
    ),

  createAssociation: (payload) => (
    httpPost(getAssociationsUrl(),payload)
      .then((res) => res)
    )
};

export default service;
