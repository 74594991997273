import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExcelRenderer } from 'react-excel-renderer';
import { allierApi } from 'services';
import { CustomersIcon } from 'assets/icons';
import { AccordionWidget } from 'components/elements';
import { ConfirmDialog, Dialog } from 'components/dialog';
import { BikeLoader } from 'components/loading';
import { importValidationSchema } from '../profile/validationSchema';
import ImportButtons from './ImportButtons';
import { CUSTOMER_FORM_FIELDS } from './importConfig';
import UserTable from './UserTable';
import { styled } from 'style';

const ConfirmDialogMessage = styled.span`
  line-height: 5;
  margin: .5rem;
  font-size: 1.5rem;
`;

const validate = (data) => {
  try {
    importValidationSchema.validateSync(data, { sync: true, abortEarly: false });
    return {};

  } catch ({ inner: errorArray }) {
    const validationErrors = {};
    errorArray.forEach(({ message, path }) => {
      validationErrors[path] = message;
    });

    return validationErrors;
  }
};

const ErrorMsg = styled.p`
  line-height: 1.2;
  margin: 0 1rem;
`;

const useFileController = (setPopupError) => {
  const [userList, setUserList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const fileRef = useRef(null);

  const onReset = () => {
    setUserList([]);
    setHasError(false);
    fileRef.current = null;
  }

  const onFileChange = (files) => {
    const [file] = files;
    if (file?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setPopupError('Filtypen støttes ikke. Vennligst velg en excel fil.');
      return;
    }

    ExcelRenderer(file, (err, resp) => {
      if (err) {
        console.error(`Error while looking into excel file ${err}`);
        setPopupError('Excel-filen kan ikke åpnes. Vennligst forsøk på nytt eller velg en annen excel-fil.');
      } else {
        // clone and remove any row where cell 0 to 9 is empty (empty row)
        const rows = [...(resp?.rows || []).filter(row => row?.slice(0,9).filter(cell => !!cell).length)];

        if (!rows?.[0] || !CUSTOMER_FORM_FIELDS.every(({ label }, i) => rows?.[0]?.[i] === label)) {
          setPopupError('Filen som ble lastet opp ser ikke ut til å være import mal. Vennligst bruk Excel mal.');
          return;
        }

        // File looks good
        // remove first row
        rows.shift();

        if (!rows.length) {
          setPopupError('Fant ingen brukere i filen.');
        }

        fileRef.current = file;
        let foundError = false;

        setUserList(rows.filter(rowCells => !!rowCells?.length).map((rowCells) => {
          const profile = {};

          rowCells.forEach((cell, index) => {
            const name = CUSTOMER_FORM_FIELDS[index]?.name;
            if (cell && name) profile[name] = cell;
          });

          profile.errors = validate(profile);
          if (Object.keys(profile.errors).length) {
            foundError = true;
          }

          return profile;
        }));

        setHasError(foundError);
      }
    })
  };

  return {
    userList,
    excelFile: fileRef.current,
    hasExcel: !!userList.length,
    hasError,
    onFileChange,
    setUserList,
    onReset,
  };
};

const ImportCustomersPage = () => {
  const { associationId } = useParams();
  const [assName, setAssName] = useState(() => associationId?.replace(/^./, associationId => associationId.toUpperCase()) || '');
  const [importTemplateUrl, setImportTemplateUrl] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [popupError, setPopupError] = useState('');

  const {
    userList,
    excelFile,
    hasExcel,
    hasError,
    onFileChange,
    onReset: onResetFile,
    setUserList,
  } = useFileController(setPopupError);

  const onReset = () => {
    if (onResetFile) onResetFile();
    setShowConfirmation(false);
    setUploading(false);
    setShowResult(false);
    setPopupError('');
  }

  const onImport = () => {
    setUploading(true);
    setShowConfirmation(false);

    allierApi.importCustomers(associationId, excelFile).then(({ importResult, parseResult } = {}) => {
      const customers = [
        ...(importResult?.customers || []),
        ...(parseResult?.customers || []),
      ];

      setUserList(prev => [...prev].map((profile, index) => {
        const importResultError = importResult?.errors?.find(({ rowNumber }) => rowNumber === index + 1);
        const parseResultError = parseResult?.errors?.find(({ rowNumber }) => rowNumber === index + 1);

        let errorMsg = '';
        if (importResultError?.message) {
          errorMsg = importResultError?.message;
        } else if (parseResultError?.message) {
          errorMsg = `${parseResultError.propertyName} ${parseResultError.message}`;
        }

        const ok = !errorMsg && customers.some((customer) => customer?.profile?.email === profile.email);
        if (!errorMsg && !ok) errorMsg = 'Ukjent feil';

        return {
          ...profile,
          importStatus: { errorMsg, ok },
      }}));

      setUploading(false);
      setShowResult(true);
    })
      .catch(e => {
        console.error(e);
        setPopupError('Importeringsprosessen feilet');
        setUploading(false);
        setShowResult(true);
      });

  };

  useEffect(() => {
    allierApi.getImportTemplateUrl().then(result => {
      setImportTemplateUrl(result.url);
    });

    allierApi.getAssociationsById(associationId).then(({ title }) => {
      if (title) setAssName(title);
    });
  }, [associationId]);

  return (
    <AccordionWidget icon={<CustomersIcon />} title={`Importer kundeliste til ${assName} borettslag`} wide>
      <ImportButtons
        onReset={onReset}
        onShowConfirmation={() => setShowConfirmation(true)}
        onFileChange={onFileChange}
        hasExcel={hasExcel}
        importTemplateUrl={importTemplateUrl}
        hasError={hasError}
        showResult={showResult}
      />

      <UserTable userList={userList} hasExcel={hasExcel} showResult={showResult}/>

      <ConfirmDialog
        onCancel={() => setShowConfirmation(false)}
        confirmText={"Ja"}
        cancelText={"Avbryt"}
        onConfirm={onImport}
        title={"Starte import?"}
        isOpen={showConfirmation}
      >
        <ConfirmDialogMessage>Vil du starte importprosessen</ConfirmDialogMessage>
      </ConfirmDialog>

      <Dialog isOpen={uploading} onClose={() => {}} title="Importerer...">
        <BikeLoader style={{ height: '15rem' }} />
      </Dialog>

      <ConfirmDialog
        onCancel={onReset}
        confirmText={"Reset"}
        onConfirm={onReset}
        title={"Feil"}
        isOpen={!!popupError}
      >
        <ConfirmDialogMessage>
          {<ErrorMsg>{popupError}</ErrorMsg>}
        </ConfirmDialogMessage>
      </ConfirmDialog>

    </AccordionWidget>
  )
};

export default ImportCustomersPage;
