import React from "react";
import Association from './Association';
import AccountManager from './AccountManager';
import Tabs from 'components/Tabs/Tabs';
import Tags from './Tags';

const AdminPage = () => {

  return (
     <Tabs>
       <AccountManager title="Kundeansvarlige" key="account-manager" />
       <Association title="Borettslag" key="association" />
       <Tags title="Tags" key="tags" />
     </Tabs>
  );
};

export default AdminPage;
