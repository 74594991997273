import { CollapseIcon, ExclamationIcon, ExpandIcon, SendIcon, } from 'assets/icons';
import {
  AccordionWidget,
  Attachments,
  Button,
  FileLink,
  Label,
  Select,
  TableRow,
  TextAreaAutosize,
  WidgetContainer
} from 'components/elements';
import { getStyle, responsive, styled, useMediaQuery } from 'style'
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { allierApi } from 'services';
import { useAuth } from 'services/auth';
import { ConfirmDialog, Dialog } from 'components/dialog';
import { toLocaleDateTime } from '../../services/common';
import { ComplaintStatus } from '../../services/common/complaint_status';
import { Spinner } from 'components/loading';
import MultiSelect from "../../components/elements/MultiSelect";

const ComplaintsLabel = styled.div`
  padding: 10px 0;
  color: black;
  margin-bottom: 0;
`;

const ExpandIconStyled = styled(ExpandIcon)`
  vertical-align: bottom;
  width: 20px;
`;

const CollapseIconStyled = styled(CollapseIcon)`
  vertical-align: bottom;
  width: 20px;
`;

const ListItem = styled(TableRow)`
  > svg {
    margin-right: ${getStyle('spacing.md')};
    min-width: ${getStyle('size.icon.md')};
    width: ${getStyle('size.icon.md')};
  }
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width:100%;
  }
  > button {
    white-space: nowrap;
    text-transform: uppercase;
    margin-right: ${getStyle('spacing.lg')};
  }
`;

const ListContainer = styled.div`
  > div, [role=row] {
    display: flex;
    align-items: center;

    padding: 0 ${getStyle('spacing.md')};
    height: ${getStyle('size.widget.li.height')};
    border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};

  ${props => responsive('padding', [
  { width: getStyle('screen.sm', props), value: `0 ${getStyle('spacing.xxl', props)}` }
])}

    :nth-child(even) {
      background: ${getStyle('color.widget.tr.background')};
    }
  }
`;

const ComplaintContainer = styled.div`
  width: 100%;
  padding: ${getStyle('spacing.md')};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-end;

  > *:first-child {
    margin-right: ${getStyle('spacing.md')};
    flex-grow: 1;
  }
`;

const MessageFieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 0.0625rem solid ${getStyle('color.border.primary')};
  border-radius: ${getStyle('border.radius.sm')};
  min-height: ${getStyle('size.input.height')};

  textarea {
    border: none;
    min-height: 0;
    width: 100%;
  }
`;

const SelectContainer = styled.span`
  div, select {
    max-width: 150px;
  }
`;

const AttachmentsContainer = styled.span`
  li {
    margin: 2px 0;  
    text-align: left;
    
    :not(:first-child,:last-child) {
      text-align: left;
    }
  }
`;

const ErrorMsg = styled.p`
  line-height: 1.2;
  margin: 1rem 1rem 2rem 1rem;  
`;

const FlexContainer = styled.div`
  display: flex;
`;

const TextLabel = styled.label`
  font-weight: bold;
`;

const InputFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const InputField = styled.input`
  border: 0.0625rem solid #6750A5;
  border-radius: 5px;
  padding: 12px;

  :focus {
    outline: none;
    background-color: #FAFAFA;
  }
`;

const DropDown = styled.select`
  border: 0.0625rem solid #6750A5;
  border-radius: 5px;
  height: 44px;
  padding: 8px;

  :focus {
    outline: none;
    background-color: #FAFAFA;
  }
`;

const ComplaintsPage = ({ userIsAccountManager }) => {
  const { userId, user } = useAuth();
  const [complaints, setComplaints] = useState(null);
  const [filteredComplaints, setFilteredComplaints] = useState(null);
  const [complaintText, setComplaintText] = useState('');
  const [complaintSendDialogOpen, setComplaintSendDialogOpen] = useState(false);
  const textareaRef = useRef();
  const attachmentsRef = useRef(null);
  const [accountManagerId, setAccountManagerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const [showText, setShowText] = useState(new Map());
  const [errorText, setErrorText] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(undefined);
  const isMobile = useMediaQuery().isMobile();
  const [optionSelected, setOptionSelected] = useState();
  const [associations, setAssociations] = useState([]);
  const [chosenAssociation, setChosenAssociation] = useState();
  const [searchText, setSearchText] = useState();
  const [hasDefaultedFilter, setHasDefaultedFilter] = useState(false);

  const sendComplaint = () => {
    setLoading(true);
    attachmentsRef.current.uploadAttachments(!userIsAccountManager ? userId : selectedCustomerId)
    .then(attachments => (
      allierApi.sendMessage({
        threadId: !userIsAccountManager ? userId : selectedCustomerId,
        senderId: !userIsAccountManager ? userId : selectedCustomerId,
        receiverId: !userIsAccountManager ? accountManagerId : userId,
        subject: "Reklamasjon",
        text: complaintText,
        type: 1,
        attachments: attachments
      })
      .then(() => {
        setLoading(false);
        setComplaintSendDialogOpen(false);
        setComplaintText('');
        attachmentsRef.current.clearAttachments();
        if(!userIsAccountManager) getComplaints(userId)
        else getComplaints();
        if(userIsAccountManager) setSelectedCustomerId('');
      })
    ))
    .catch(err => {
      setErrorText(err.message);
    });
  }

  const getComplaints = (id) => {
    allierApi.getComplaints(id).then(res => {
      setComplaints(res.messages);
      setFilteredComplaints(res.messages);
      res.messages.forEach((m) =>{
        setShowText(showText.set(m.id, false));
      })
    })
  }

  const getAsses = () => {
    allierApi.getAssociations().then((response) =>
      setAssociations(response));
  }

  const setComplaintStatus = (userId, id,status) => {
    allierApi.setComplaintStatus(userId, id,status).then(() => getComplaints());
  }

  const sortComplaints = (field) => {
    if(complaints)
    {
      setSortDirection(!sortDirection);
      //Svarte, dette må vel kunne gjøres smartere, men vi har string, date og int her...??
      if(field==="createdAt") setComplaints([...complaints].sort((a, b) => sortDirection ? new Date(a[field]) - new Date(b[field]) : new Date(b[field]) - new Date(a[field])));
      if(field==="senderName") setComplaints([...complaints].sort((a,b) => sortDirection ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field])));
      if(field==="status") setComplaints([...complaints].sort((a,b) => sortDirection ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1)));
    }
  }

  const toggleText = (id) => {
    const currentState = showText.get(id);
    setShowText(showText => {
      const newMap = new Map(showText);
      [...newMap.keys()].forEach((key) => {
        newMap.set(key, false);
      });
      newMap.set(id,!currentState);
      return newMap;
    });
  }

  const resetComplaint = () => {
    setLoading(false);
    setErrorText('');
    setComplaintSendDialogOpen(false);
    setComplaintText(complaintText);
  }

  const getCustomerList = () => {
    allierApi.getCustomers('?size=200&sort=givenName')
      .then(({ customers }) => {
        setCustomers(customers);
      });
  }

  const checkButtonDisabled = () => {
    if(!userIsAccountManager) return !complaintText || complaintText.length === 0;
    return selectedCustomerId === undefined || selectedCustomerId.length === 0 || !complaintText || complaintText.length === 0;
  }

  const handleSelectFilter = useCallback((selected) => {
    setOptionSelected(selected);
  }, []);

  const filterOnCustomers = useCallback((search) => {
    search = search ?? '';
    const normalizedSearch = search.replace(/\s/g, '').toUpperCase();
    setSearchText(normalizedSearch);    

    let filter = complaints.filter(c=>optionSelected.map(s=>parseInt(s.value)).includes(c.status));

    if(chosenAssociation && chosenAssociation !== "Alle") {
      if(chosenAssociation === "PRIVATE_CUSTOMER"){
        filter = filter.filter(c => !c.createdByAssociation || c.createdByAssociation.length===0);
      }
      else filter = filter.filter(c => c.createdByAssociation ? c.createdByAssociation.toUpperCase() === chosenAssociation.toUpperCase() : false);
    }

    let filtered = filter.filter(complaint => {

      return complaint.senderName?.replace(/\s/g, '').toUpperCase().includes(normalizedSearch) ||
        complaint.createdByAddress?.replace(/\s/g, '').toUpperCase().includes(normalizedSearch) ||
        complaint.createdByDwelling?.replace(/\s/g, '').toUpperCase().includes(normalizedSearch);
    });

    setFilteredComplaints(filtered);
  }, [chosenAssociation, complaints, optionSelected]);

  useEffect(() => {
    if(userIsAccountManager){
      getComplaints();
      getCustomerList();
      getAsses();
    }
    else allierApi.getAccountManager(userId).then(res => {
      setAccountManagerId(res ? res.userId : res);
      getComplaints(userId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsAccountManager, user]);

  useEffect(() => {
    if(complaints && userIsAccountManager) {
      if (hasDefaultedFilter === false) {
        handleSelectFilter(
          [
            {value: 0, label: "Ubehandlet"},
            {value: 1, label: "Under behandling"}
          ]);

        setHasDefaultedFilter(true);
      }      
    }
  },[complaints, userIsAccountManager, handleSelectFilter, hasDefaultedFilter]);

  useEffect(() => {
    if (complaints && userIsAccountManager && optionSelected){
      filterOnCustomers(searchText);
    }
  }, [complaints, userIsAccountManager, filterOnCustomers, searchText, optionSelected])

  return (
    <>
      <WidgetContainer>
        {filteredComplaints &&
          <AccordionWidget title="Reklamasjoner" icon={<ExclamationIcon />} customContainer wide>
            {userIsAccountManager &&
              <div style={{marginLeft: '20px', marginRight: '20px'}}>
                <div style={{width: '50%'}}>
                <TextLabel htmlFor="status">Status</TextLabel>
                <MultiSelect
                  options={Object.entries(ComplaintStatus).map(([key,value]) => ({value: key, label: value}))}
                  onChange={handleSelectFilter}
                  value={optionSelected}
                />
                </div>
                <div style={{ display: "flex" }}>
                  <InputFlex style={{width: '50%'}}>
                    <TextLabel htmlFor="search">Fritekstsøk</TextLabel>
                    <InputField type="text" onChange={(e) => filterOnCustomers(e.target.value)} />
                  </InputFlex>
                  <InputFlex style={{width:'49%',marginLeft: '10px'}}>
                    <TextLabel htmlFor="search">Borettslag</TextLabel>
                    <DropDown value={chosenAssociation} onChange={(e) => { setChosenAssociation(e.target.value); }}>
                      <option>Alle</option>
                      <option value="PRIVATE_CUSTOMER">Privat</option>
                      {associations?.map(association => <option value={association.id} key={association.id}>{association.title}</option>)}
                    </DropDown>
                  </InputFlex>
                </div>
              </div>}
            <ListContainer>
              {/*Headers*/}
              <ListItem style={{fontWeight:'bold'}}>
                <span
                  style={{cursor:'pointer'}}
                  onClick={() => sortComplaints('createdAt')}>Dato{!sortDirection ? <ExpandIconStyled /> : <CollapseIconStyled />}
                </span>
                {userIsAccountManager &&
                  <span
                    onClick={() => sortComplaints('senderName')}
                    style={{textAlign:'left',cursor:'pointer'}}>Navn{!sortDirection ? <ExpandIconStyled /> : <CollapseIconStyled />}
                  </span>
                }
                <span style={{ textAlign:'left' }}>Adresse</span>
                <span style={{ textAlign:'left' }}>Borettslag</span>
                <span style={{ cursor:'pointer', textAlign: 'left'}} onClick={() => sortComplaints('status')}>Status{!sortDirection ? <ExpandIconStyled /> : <CollapseIconStyled />}</span>
                <span style={ userIsAccountManager ? { textAlign:'right' } : { textAlign:'left' }}>Dato endret</span>
              </ListItem>

            {/*Items*/}
            {filteredComplaints && filteredComplaints.map((c) => (
              <React.Fragment key={c.id}>
                <ListItem
                    key={c.id}
                    onClick={(e) => {if(!(e.target.id ==="statusSelect" || e.target.nodeName === "BUTTON")) toggleText(c.id, e); }}
                    style={{cursor:'pointer'}}
                >
                  <span>{toLocaleDateTime(c.createdAt)}</span>
                  {userIsAccountManager &&
                    <span style={{textAlign:'left'}}>{c.senderName}</span>
                  }
                  <span
                    style={{textAlign:'left',cursor:'pointer'}}
                  >
                    {c.createdByAddress} {c.createdByDwelling}
                  </span>
                  <span
                    style={{textAlign:'left',cursor:'pointer'}}
                  >
                    {c.createdByAssociation}
                  </span>
                  {!userIsAccountManager
                    ? <span style={{textAlign:'left'}}>{ComplaintStatus[c.status]}</span>
                    :
                      <SelectContainer>
                        <Select
                          id="statusSelect"
                          aria-label="Status"
                          onChange={(e) => {
                            setComplaintStatus(c.senderId,c.id,e.target.value);
                          }}
                          value={c.status}
                        >
                          {ComplaintStatus && Object.entries(ComplaintStatus).map(([key,value]) => (
                            <option style={{textAlign:'left'}} key={key} value={key}>{value}</option>
                          ))}
                        </Select>
                      </SelectContainer>
                  }
                  <span style={userIsAccountManager ? { textAlign:'right' } : { textAlign:'left' }}>{toLocaleDateTime(c.updatedAt ?? c.createdAt)}</span>
                </ListItem>
                {/*Text view*/}
                {showText && showText.get(c.id) &&
                  <div style={{display: 'contents', borderBottom: '1px solid black'}}>
                    <TextAreaAutosize readOnly key={c.createdAt} style={{width:'98%', fontSize:'1em', marginLeft: '8px',marginTop: '8px'}} defaultValue={c.text} />
                    <div style={{marginLeft: '10px', fontSize: '0.9em'}}>
                      <AttachmentsContainer
                        title='Vedlegg'
                      >
                        <ul>
                          {c.attachments.map(f => (
                            <li key={f.target}>
                              <FileLink
                                key={f.target}
                                filename={f.name}
                                download={() => allierApi.getFileSas(f.target)}
                                onError={err => {}}
                              />
                            </li>
                          ))}
                        </ul>
                      </AttachmentsContainer>
                    </div>
                    <div style={{margin: '8px', fontStyle: 'italic', opacity: '0.5', fontSize: '0.8em'}}>{`Registrert av ${c.createdByName}`}</div>
                    <hr style={{opacity: '0.2'}} />
                  </div>
                }
              </React.Fragment>
              ))
            }
          </ListContainer>
        </AccordionWidget>
        }
        <AccordionWidget title={!userIsAccountManager ? "Send reklamasjon" : "Send reklamasjon på vegne av kunde"} icon={<ExclamationIcon />} customContainer wide>
          <ComplaintContainer>
            {loading
              ? <FlexContainer><Spinner /><div style={{marginLeft: '10px', marginTop: '3px'}}>Sender reklamasjon...</div></FlexContainer>
              : (
              <>
                {!userIsAccountManager
                  ? <ComplaintsLabel>Vennligst fyll inn detaljert beskrivelse av reklamasjon</ComplaintsLabel>
                  : <div style={{marginBottom: '20px', width: '40%'}}>
                     <Label htmlFor="customerSelect">På vegne av kunde</Label>
                     <Select
                       id="customerSelect"
                       aria-label="Kunde"
                       value={selectedCustomerId}
                       onChange={(e) => setSelectedCustomerId(e.target.value)}
                     >
                       <option key="key" aria-label="Velg kunde" value="">Velg kunde...</option>
                       {customers && customers.map(customer => (
                         <option key={customer.email} value={customer.id}>
                           {customer.displayName()}
                         </option>
                       ))}
                     </Select>
                    </div>
                }
                <Attachments ref={attachmentsRef} width={'60%'}></Attachments>
                <MessageContainer>
                  <MessageFieldContainer>
                    <TextAreaAutosize
                      aria-label="Reklamasjon"
                      placeholder={'Reklamasjonstekst'}
                      onChange={(e) => setComplaintText(e.target.value)}
                      ref={textareaRef}
                      value={complaintText}
                    />
                  </MessageFieldContainer>
                  <Button
                    disabled={checkButtonDisabled()}
                    variant="primary"
                    shape={isMobile ? 'square' : 'default'}
                    onClick={() => { setComplaintSendDialogOpen(true); } }>
                    {!isMobile && <span>Send reklamasjon</span>}
                    {isMobile ?  <SendIcon /> : <SendIcon style={{marginLeft: '5px'}} />}
                  </Button>
                </MessageContainer>
              </>
            )}
          </ComplaintContainer>
        </AccordionWidget>
      </WidgetContainer>
      <>
        <ConfirmDialog
            onCancel={() => {setComplaintSendDialogOpen(false)}}
            confirmText={"Ja"}
            cancelText={"Avbryt"}
            children={[]}
            isDisabled={loading}
            onConfirm={() => sendComplaint()}
            title={"Ønsker du å sende reklamasjonen?"}
            isOpen={complaintSendDialogOpen} />
      </>
      <Dialog
        title="En feil oppstod"
        isOpen={!!errorText}
        onClose={resetComplaint}
        variant={'error'}>
        <ErrorMsg>{errorText && errorText.toString()}</ErrorMsg>
        <Button style={{width: '100%'}} variant={'primary'} onClick={resetComplaint}>Lukk</Button>
      </Dialog>
    </>
  );
}

export default ComplaintsPage;
