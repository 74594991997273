import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormField, SelectFormField } from 'components/form';
import {AccordionWidget, Button, Checkbox, Label} from 'components/elements';
import validationSchema from './validationSchema';
import { getStyle, responsive, styled } from 'style';
import { DeleteIcon } from 'assets/icons';
import {ConfirmDialog} from 'components/dialog';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'components/loading';

const CUSTOMER_FORM_FIELDS = [
  { name: 'givenName', label: 'Fornavn' },
  { name: 'familyName', label: 'Etternavn' },
  { name: 'email', label: 'E-postadresse' },
  { name: 'phone', label: 'Mobilnummer',  type: 'tel' },
  { name: 'streetAddress', label: 'Adresse' },
  { name: 'zipCode', label: 'Postnummer',  type: 'number' },
  { name: 'city', label: 'Sted' },
  { name: 'dwellingNumber', label: 'Bolignummer' },
  { name: 'association', label: 'Kundeprofil', type: 'select' },
  { name: 'electronicInvoice', label: 'Efaktura', type: 'checkbox'}
];

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
  
  ${props => responsive('padding-right', [
    {
      width: getStyle('screen.sm', props),
      value: props.hidden || props.wide ? 0 : '3rem'
    }
  ])}
`;

const EditButton = styled.div`
  position: absolute;
  right: 73px;
  top: -60px;
`;

const ElInvoiceLabel = styled(Label)`
  color: grey;
`

const ElInvoiceContainer = styled.div`
  display: flex;
  margin-top: 10px;
  color: grey;
`

const ElInvoiceP = styled.p`
  margin-top: 5px;
  margin-right: 10px;
  color: grey;
`

const isEqual = (a = {}, b = {}) => JSON.stringify(a) === JSON.stringify(b);

const flattenCustomerData = (customer) => customer.id
  ? {
    email: customer.email || '',
    familyName: customer.familyName || '',
    givenName: customer.givenName || '',
    phone: customer.phone || '',
    id: customer.id || '',
    association: customer.association.id || '',
    city: customer.address.city || '',
    dwellingNumber: customer.address.dwellingNumber || '',
    streetAddress: customer.address.streetAddress || '',
    zipCode: customer.address.zipCode || '',
    electronicInvoice: customer.electronicInvoice
  }
  : {electronicInvoice: true};

const validate = (schema, data) => {
  try {
    schema.validateSync(data, { sync: true, abortEarly: false });
    return {};

  } catch ({ inner: errorArray }) {
    const validationErrors = {};
    errorArray.forEach(({ message, path }) => {
      validationErrors[path] = message;
    });

    return validationErrors;
  }
}

const CustomerForm = ({ customer, associations, isNew, onSave, onCancel, isEditing, onDelete }) => {
  const [customerData, setCustomerData ] = useState(() => flattenCustomerData(customer));
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSavingCustomer, setIsSavingCustomer] = useState(false);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  let navigate = useNavigate();
  const onChange = ({ target }) => {
    const { name, value = '', type = '' } = target;
    if (!name) return;

    if (type.includes('select')) {
      onBlur({ target })
    }

    setCustomerData(prevState => {
      const newProfileState = { ...prevState, [name]: value }
      setErrors(validate(validationSchema, newProfileState));

      return newProfileState;
    });
  };

  const onBlur = ({ target }) => {
    const { name } = target;
    const additionalEffects = {};
    if (name === 'association') {
      additionalEffects.dwellingNumber = true;
    }
    setTouched(prevState => ({ ...prevState, ...additionalEffects, [name]: true }));
  };

  const options = [{label: 'Privatkunde', value: ''}, ...associations.map(({ title, id }) => ({ label: title, value: id }))];

  const isChanged = isEqual(flattenCustomerData(customer), customerData);

  const onSaveCustomer = async () => {
    if (Object.keys(errors).some(key => errors[key])) {
      const toucheAll = {};
      CUSTOMER_FORM_FIELDS.forEach(({ name }) => {
        toucheAll[name] = true;
      });
      setTouched(toucheAll);
      return;
    }
    setIsSavingCustomer(true);
    const result = await onSave(customerData);
    if(result && result.includes('409')) {
      setErrors({ ...errors, email: 'Epostadressen er allerede i bruk' });
      setIsSavingCustomer(false);
      return;
    }
    setIsSavingCustomer(false);
    onCancel();
  };

  const onDeleteCustomer = async () => {
    onDelete(customer.id).then(() => {
      navigate("/accountmanager/dashboard");
    });
  }

  return (
    <AccordionWidget title="Personopplysninger" wide collapsed>
      {isEditing &&
        <>
          <ConfirmDialog
            onCancel={() => {setDeleteConfirmDialogOpen(false)}}
            confirmText={"Ja"}
            cancelText={"Avbryt"}
            children={[]}
            onConfirm={() => onDeleteCustomer()}
            title={"Ønsker du å slette kunde?"}
            isOpen={deleteConfirmDialogOpen} />
          <EditButton>
            <Button onClick={() => setDeleteConfirmDialogOpen(true)}>
              Slett
              <DeleteIcon style={{marginLeft:'10px'}} />
            </Button>
          </EditButton>
        </>
      }
      <Container>
        {
          CUSTOMER_FORM_FIELDS.map(({ name, label, type = 'text', disabled  = false }) => type === 'select'
            ? <SelectFormField
              key={name}
              label={label}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={customerData[name] || ''}
              disabled={disabled}
              error={touched[name] ? (errors[name] || '') : ''}
              options={options}
            />
            : type === 'checkbox'
            ?
              <div key={name}>
                <ElInvoiceLabel>Efaktura</ElInvoiceLabel>
                <ElInvoiceContainer>
                  <ElInvoiceP>Send eFaktura</ElInvoiceP>
                  <Checkbox
                    disabled={true}
                    children={""}
                    checked={customerData[name]}
                    onChange={(e) => onChange({target:{name:'electronicInvoice',value:e}})}
                  />
                </ElInvoiceContainer>
              </div>
            :
              <FormField
              key={name}
              label={label}
              name={name}
              type={type}
              onChange={onChange}
              onFocus={onBlur}
              value={customerData[name] || ''}
              error={touched[name] ? (errors[name] || '') : ''}
            />
          )
        }
      </Container>
      <ButtonContainer>
        { isSavingCustomer && <Spinner style={{height: '30px', width: '30px', marginTop: '7px'}} />}
        <Button variant="secondary" onClick={onCancel}>Avbryt</Button>
        <Button disabled={isChanged || isSavingCustomer} onClick={onSaveCustomer}>{isNew ? 'Registrer kunde' : 'Oppdater kunde'}</Button>
      </ButtonContainer>
    </AccordionWidget>
  );
};

CustomerForm.defaultProps = {
  customer: {},
  associations: [],
  isNew: false,
  onSave: () => {},
  onCancel: () => {},
  isEditing: false,
  onDelete: () => {}
};

CustomerForm.prototype = {
  customer: PropTypes.shape({}),
  associations: PropTypes.arrayOf(PropTypes.shape({})),
  isNew: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  isEditing: PropTypes.bool
};

export default CustomerForm;
