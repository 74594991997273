import { forwardRef } from 'react';
import TextAreaAutosizeCore from 'react-textarea-autosize';
import { styled, getStyle } from 'style';

const StyledTextArea = styled(TextAreaAutosizeCore)`
  font-size: ${getStyle('font.lg')};
  padding: ${getStyle('spacing.md')};
  border: 0.0625rem solid ${getStyle('color.border.primary')};
  border-radius: ${getStyle('border.radius.sm')};
  outline: none;
  resize: none;
  min-height: ${getStyle('size.input.height')};
`;

const TextAreaAutosize = forwardRef(({ ...props }, ref) => (
  <StyledTextArea ref={ref} {...props} />
));

export default TextAreaAutosize;
