import PropTypes from 'prop-types';
import { styled, getStyle, variant } from 'style';

const fontWeight = variant('variant', {
  primary: 'bold',
  secondary: 'bold',
  link: 'normal',
  inline: 'normal'
});

const textDecoration = variant('variant', {
  primary: 'none',
  secondary: 'none',
  link: 'underline',
  inline: 'none'
});

const textTransform = variant('variant', {
  primary: 'capitalize',
  secondary: 'capitalize',
  link: 'none',
  inline: 'none'
});

const borderRadius = variant('variant', {
  primary: getStyle('border.radius.sm'),
  secondary: getStyle('border.radius.sm'),
  link: '0',
  inline: '0'
});

const borderWidth = variant('variant', {
  primary: '0',
  secondary: '0.0625rem',
  link: '0',
  inline: '0'
});

const background = variant('variant', {
  primary: getStyle('color.button.background.primary'),
  secondary: getStyle('color.button.background.secondary'),
  link: 'none',
  inline: 'none'
});

const backgroundHover = variant('variant', {
  primary: getStyle('color.button.background.hover'),
  secondary: getStyle('color.button.background.hover'),
  link: 'default',
  inline: 'default'
});

const color = variant('variant', {
  primary: getStyle('color.button.primary'),
  secondary: getStyle('color.button.secondary'),
  link: getStyle('color.primary'),
  inline: getStyle('color.button.secondary')
});

const colorHover = variant('variant', {
  primary: getStyle('color.button.primary'),
  secondary: getStyle('color.button.primary'),
  link: getStyle('color.status.selected'),
  inline: getStyle('color.primary')
});

const height = variant('variant', {
  primary: getStyle('size.button.height'),
  secondary: getStyle('size.button.height'),
  link: 'auto',
  inline: 'auto'
});

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${getStyle('font.md')};
  font-weight: ${fontWeight};
  line-height: 140%;
  text-decoration: ${textDecoration};

  min-width: ${props => (props.shape !== 'default'
    ? getStyle('size.button.height')
    : getStyle('size.element.minWidth')
  )};
  min-height: ${getStyle('size.element.minHeight')};
  width: ${props => (props.shape !== 'default' ? height(props) : 'auto')};
  min-height: ${height};

  background: ${background};
  color: ${color};
  stroke: ${color};
  fill: ${color};

  border-color: ${getStyle('color.button.background.primary')};
  border-radius: ${props => (props.shape === 'round' ? height(props) : borderRadius(props))};
  border-style: solid;
  border-width: ${borderWidth};
  outline: none;

  padding: 0 ${props => (
    props.shape === 'default' && (props.variant === 'primary' || props.variant === 'secondary')
      ? getStyle('spacing.xl', props) : '0'
  )};

  svg {
    height: ${getStyle('size.icon.md')};
  }

  > :not(:first-child) {
    margin-left: ${getStyle('spacing.xs')};
  }

  @media (pointer: fine) {
    :hover {
      background: ${backgroundHover};
      color: ${colorHover};
      stroke: ${colorHover};
      fill: ${colorHover};
    }
  }

  -webkit-tap-highlight-color: transparent;

  &:disabled,
  [disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;

  & :first-letter {
    text-transform: ${textTransform};
  }
}
`;

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'inline']),
  shape: PropTypes.oneOf(['default', 'round', 'square'])
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  shape: 'default'
};

export default Button;
