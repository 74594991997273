import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Form } from 'components/form';
import { TextAreaAutosize, Button, UploadButton } from 'components/elements';
import { styled, getStyle, useMediaQuery } from 'style';
import { AttachmentIcon, ImageIcon, RemoveIcon, SendIcon } from 'assets/icons';
import validationSchema from './validationSchema';
import { Spinner } from "../../components/loading";
import config from "../../config";
import { default as TagsDialog } from 'components/dialog/TagsDialog';

const Container = styled.div`
  width: 100%;
  padding: ${getStyle('spacing.md')};
`;

const UploadButtonContainer = styled.div`
  display: flex;
  margin-bottom: ${getStyle('spacing.md')};

  > * {
    margin-right: ${getStyle('spacing.md')};
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-end;

  > *:first-child {
    margin-right: ${getStyle('spacing.md')};
    flex-grow: 1;
  }
`;

const MessageFieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 0.0625rem solid ${getStyle('color.border.primary')};
  border-radius: ${getStyle('border.radius.sm')};
  min-height: ${getStyle('size.input.height')};

  > ul {
    padding-left: ${getStyle('spacing.md')};
    margin-bottom: 0.0625rem;
    border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        height: ${getStyle('size.icon.sm')};
      }
    }
  }

  textarea {
    border: none;
    min-height: 0;
    width: 100%;
  }
`;

const MessageField = ({ name, placeholder, attachments, removeAttachment, onTextChange }) => {
  const [{ onChange, ...fieldRest}] = useField({ name });

  const onChangeLocal = (event) => {
    onChange(event);
    onTextChange(event);
  };

  return (
    <MessageFieldContainer>
      <ul hidden={attachments.length === 0}>
        {attachments.map(f => (
          <li key={f.name}>
            <span>{f.name}</span>
            <Button variant="inline" onClick={() => removeAttachment(f)}>
              <RemoveIcon />
            </Button>
          </li>
        ))}
      </ul>
      <TextAreaAutosize
        aria-label="Melding"
        placeholder={placeholder}
        onChange={onChangeLocal}
        {...fieldRest}
      />
    </MessageFieldContainer>
  );
};

MessageField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  attachments: PropTypes.array.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
};

const MessageForm = ({
  subject, senderId, threadId, receiverId, placeholder, sendMessage, refresh, uploadFiles, userIsAccountManager
}) => {
  const isMobile = useMediaQuery().isMobile();

  const [attachments, setAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [chosenTags, setChosenTags] = useState([]);
  const [tagsDialogOpen, setTagsDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const bottomDivRef = useRef(null);

  const onCloseTags = (tags) => {
    setTagsDialogOpen(false);
  }

  const onConfirmTags = (tags) => {
    setChosenTags(tags.filter(tag => tag.documentTag));
    setTagsDialogOpen(false);
  }

  return (
    <Form
      initialValues={{
        subject,
        text: ''
      }}
      validationSchema={validationSchema}
      submit={(values, { resetForm }) => {
        setIsLoading(true);
        Promise.all((attachments.concat(images) || [])
          .map(attachment => {
              const meta = {
                  name: attachment.name,
                  access: [{ userId: receiverId }],
                  tags: chosenTags
              };
              return uploadFiles(meta, attachment)
                  .then(file => ({
                      type: 'fileRef',
                      name: file.name,
                      target: file.id
                  }));
          }))
          .then(attachments => (
            sendMessage({
              threadId,
              senderId,
              receiverId,
              subject: values.subject,
              text: values.text.trim(),
              attachments: attachments
          })
          .then(() => {
              setIsLoading(false);
              resetForm();
              setAttachments([]);
              setImages([]);
              setChosenTags([]);
              refresh();
          })));
      }}
    >
      <Container>
        {isLoading
          ? <div style={{display: 'flex'}}><Spinner /><div style={{marginLeft: '10px', marginTop: '3px'}}>Sender melding...</div></div>
          : (
            <>
              <UploadButtonContainer>
                <UploadButton
                  variant="secondary"
                  shape="square"
                  aria-label="Vedlegg"
                  accept="application/pdf"
                  multiple
                  files={attachments}
                  onFilesChanged={setAttachments}
                  icon
                  maxSize={config.fileUploadLimitBytes}
                >
                  <AttachmentIcon />
                </UploadButton>
                <UploadButton
                  variant="secondary"
                  shape="square"
                  aria-label="Bilder"
                  accept="image/jpeg,image/png,video/mp4,video/webm,video/quicktime"
                  multiple
                  files={images}
                  onFilesChanged={setImages}
                  maxSize={config.fileUploadLimitBytes}
                >
                  <ImageIcon />
                </UploadButton>
                {images.length > 0 && userIsAccountManager === true ? <UploadButton variant="secondary" onClick={() => setTagsDialogOpen(true)}>Tags</UploadButton> : null}
              </UploadButtonContainer>
              <MessageContainer>
                <MessageField
                  name="text"
                  onTextChange={({ target }) => setIsDisabled(!target.value.trim())}
                  placeholder={placeholder}
                  attachments={attachments.concat(images)}
                  removeAttachment={file => {
                    setAttachments(attachments.filter(f => f.name !== file.name));
                    setImages(images.filter(f => f.name !== file.name));
                  }}
                />
                <Button
                  type="submit"
                  shape={isMobile ? 'square' : 'default'}
                  aria-label="Send melding"
                  disabled={isDisabled}
                >
                  {!isMobile && <span>Send melding</span>}
                  <SendIcon />
                </Button>
              </MessageContainer>
              <TagsDialog
                blockOpen={images.length < 1}
                blockText="Du må velge en fil før du kan sette tags"
                isDialogOpen={tagsDialogOpen}
                onCancel={onCloseTags}
                onConfirm={onConfirmTags}
                chosenTags={chosenTags}
              ></TagsDialog>
            </>
          )}
      </Container>
      <div ref={bottomDivRef}/>
    </Form>
  );
};

MessageForm.propTypes = {
  subject: PropTypes.string.isRequired,
  threadId: PropTypes.string,
  receiverId: PropTypes.string,
  placeholder: PropTypes.string,
  sendMessage: PropTypes.func.isRequired,
  userIsAccountManager: PropTypes.bool.isRequired
};

export default MessageForm;
