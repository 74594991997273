import { httpGet, httpPost, httpPut, jsonOrThrow, httpDelete, throwOnError } from 'services/common';
import config from 'config';
import { httpPostFile } from '../common/http';

const mapUserProfile = ({ profile }) => {
  if (!profile) return {};

  const associationData = profile.association
    ? {
      association: {
        ...profile.association,
        id: profile.association.id || 'PRIVATE_CUSTOMER',
        sanityId: profile.association.sanityId
      }
    }
    : {};

  return {
    ...profile,
    ...associationData,
    displayName: () => (
      profile.givenName && profile.familyName
        ? `${profile.givenName} ${profile.familyName}`
        : profile.email
    ),
  }
};

const getCustomersUrl = () => (
  `${config.apiHost}/api/customers`
);

const getCustomerUrl = (id) => (
  `${config.apiHost}/api/users/${id}`
);

const getUsersUrl = () => (
  `${config.apiHost}/api/users`
)

const getAccountManagerUrl = () => (
  `${config.apiHost}/api/users/accountManager`
)

const getAssociationsUrl = () => (
  `${config.apiHost}/api/associations`
);

const getContactsUrl = (customerId) => (
  `${config.apiHost}/api/customers/${customerId}/contacts`
);

const getImportCustomersUrl = (associationId) => (
  `${config.apiHost}/api/customers/${associationId}/import`
)

const customers = {
  getAccountManager: customerId => (
    httpGet(`${getCustomersUrl()}/${customerId}/accountManager`)
      .then(response => jsonOrThrow(response))
      .then(profile => mapUserProfile({ profile }))
  ),

  getAccountManagers: () => (
    httpGet(`${getUsersUrl()}/accountManagers`)
    .then(res => {
      if(res.ok) {
        return res.json()
      }
      return res.text().then(text => {throw new Error(text)})
    })
  ),

  getCustomers: (urlQueries = '') => (
    httpGet(`${getCustomersUrl()}${urlQueries}`)
      .then(jsonOrThrow)
      .then(data => ({
        customers: data.customers.map(customer => mapUserProfile(customer)),
        pageInfo: data.pageInfo,
      }))
  ),

  getCustomer: (customerId) => (
    httpGet(getCustomerUrl(customerId))
      .then(jsonOrThrow)
  ),

  createCustomer: customer => (
    httpPost(getCustomersUrl(), {
      accountManagerId: customer.accountManagerId,
      status: customer.status,
      profile: customer.profile,
      association: customer.association
    })
      .then(jsonOrThrow)
  ),

  createAccountManager: (customer, roleId) => (
    httpPost(getAccountManagerUrl() + `?roleIds=${roleId}`, customer)
    .then(res => {
      if(res.ok) {
        return res.json()
      }
      return res.text().then(text => {throw new Error(text)})
    })
  ),

  updateCustomer: customer => (
    httpPut(getCustomerUrl(customer.id), customer)
      .then(throwOnError)
  ),

  deleteCustomer: customerId => (
    httpDelete(`${getCustomersUrl()}/${customerId}`)
      .then(throwOnError)
  ),

  getCustomerAssociations: () => (
    httpGet(getAssociationsUrl())
      .then(jsonOrThrow)
  ),

  getCustomerContacts: customerId => (
    httpGet(getContactsUrl(customerId))
      .then(jsonOrThrow)
  ),

  importCustomers: (associationId, excelFile) => (
    httpPostFile(getImportCustomersUrl(associationId), excelFile)
      .then(jsonOrThrow)
  ),

  isCustomerContact: () => (
    httpGet(`${getCustomersUrl()}/isCustomerContact`)
      .then(jsonOrThrow)
  )
};

export default customers;
