import React, {useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/elements';
import ErrorReportDialog from '../../dialog/ErrorReportDialog';
import { useNavigate } from "react-router-dom";
import { styled, getStyle } from 'style';

import { ProfileIcon, ExpandIcon, CollapseIcon } from 'assets/icons';
import { useAuth } from 'services/auth';

import { renderProfileOverlay } from './Overlay';

const SelectButton = styled(Button)`
  font-size: ${getStyle('font.sm')};
  white-space: nowrap;

  span:hover {
    text-decoration: underline;
  }
`;

const SelectItems = styled.nav`
  display: flex;
  flex-direction: column;
`;

const SelectItem = styled.button`
  font-size: ${getStyle('font.md')};
  padding-left: ${getStyle('spacing.xl')};
  border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};
  height: ${getStyle('size.overlay.li.height')};
  text-align: left;
  outline: none;
`;

const ProfileButton = ({
  userProfile, isMobile, isOverlayVisible, showOverlay
}) => {
  const [errorReportDialogIsOpen, setErrorDialogIsOpen] = useState(false);

  let navigate = useNavigate();
  const { userId, logout } = useAuth();
  
  const ref = useRef();
  useOnClickOutside(ref, () => handleClickOutSide());

  const handleClickOutSide = useCallback(() => {
    if (!errorReportDialogIsOpen) {
      showOverlay(false);
    }
  }, [errorReportDialogIsOpen, showOverlay]);

  const handleOpenDialog = () => {
    setErrorDialogIsOpen(true);
  }

  const handleCloseDialog = () => {
    setErrorDialogIsOpen(false);
  };

  return (
    <>
      {(isMobile
        ? (
          <Button
            variant="inline"
            aria-label="Profil"
            role="link"
            onClick={() => {
              showOverlay(false);
              navigate('/profile');
            }}
          >
            <ProfileIcon />
          </Button>
        )
        : (
          <SelectButton
            variant="inline"
            aria-label="Profil"
            onClick={() => showOverlay(!isOverlayVisible)}
          >
            <span>{userProfile.displayName()}</span>
            {isOverlayVisible ? <CollapseIcon /> : <ExpandIcon /> }
          </SelectButton>
        )
      )}

      {isOverlayVisible && renderProfileOverlay(
        <SelectItems ref={ref}>
          <SelectItem
            role="link"
            onClick={() => {
              showOverlay(false);
              navigate('/profile');
            }}
          >
            Min profil
          </SelectItem>
          <SelectItem role="link" onClick={() => {logout()}}>
            Logg ut
          </SelectItem>
          <SelectItem role="link" onClick={handleOpenDialog}>
            Rapporter teknisk feil
          </SelectItem>
        </SelectItems>
      )}
      <ErrorReportDialog isOpen={errorReportDialogIsOpen} userId={userId} handleCloseDialog={handleCloseDialog} />
    </>
  );
};

ProfileButton.propTypes = {
  userProfile : PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOverlayVisible: PropTypes.bool.isRequired,
  showOverlay: PropTypes.func.isRequired
};

ProfileButton.defaultProps = {
  isMobile: false,
  isOverlayVisible: false,
};

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [ref, handler]
  );
}

export default ProfileButton;
