import PropTypes from 'prop-types';
import { Button } from 'components/elements';
import Dialog from './Dialog';
import DialogActions from './DialogActions';

const ConfirmDialog = ({
  title, confirmText, cancelText, isOpen, onConfirm, onCancel, children, isDisabled
}) => (
  <Dialog title={title} isOpen={isOpen} onClose={onCancel}>
    {children}
    <DialogActions>
      { cancelText ? <Button variant="secondary" onClick={onCancel} disabled={isDisabled}>{cancelText}</Button> : null}
      <Button onClick={onConfirm} disabled={isDisabled}>{confirmText}</Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default ConfirmDialog;
