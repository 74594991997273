import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from 'services/auth';
import { allierApi } from 'services';
import { BikeLoader } from 'components/loading';
import { MessageWidget } from 'pages/messages';
import { Select, Label, TableHeader, ColumnHeader, Button, TableRow, Cell, Table, AccordionWidget } from "../../components/elements";
import { styled, getStyle, useMediaQuery } from 'style';
import { ArrowRight } from "../../assets/icons";
import { useParams } from "react-router-dom";

const Container = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${getStyle('size.input.width.lg')};
  margin-bottom: ${getStyle('spacing.xl')};
`;

const RightContent = styled.div`
    margin-left: auto;
    margin-right: 0;
    float: right;
`;

const HiddenRightContent = styled.div`
    visibility: hidden;
`;

const MessagesPage = ({ userIsAccountManager }) => {

  const { userId } = useAuth();
  const [messages, setMessages] = useState(null);
  const { customerId } = useParams();
  const [accountManagerId, setAccountManagerId] = useState(null);
  //if user is accman - we need the customer list for the select
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(customerId ?? "");
  const [usersHavingUnreadMessages, setUsersHavingUnreadMessages] = useState([]);
  const bottomDivRef = useRef(null);
  const isMobile = useMediaQuery().isMobile();

  //Basically three cases: 1) user is accman, 2) user is not accman but has assigned one, 3) user is not accman and is NOT assigned one.
  //In case of 3) - should we even show Meldinger in the menu? Or should every user be assigned an accman automatically based on postal code?

  const getMessageThread = (customerId) => {
    allierApi.getMessageThread(customerId).then(res2 => insertMessages(res2.messages));
    setSelectedCustomerId(customerId);
  }

  const insertMessages = (messages) => {
    setMessages(messages);
  }

  const refresh = useCallback(() => {
    if (selectedCustomerId)
    {
      allierApi.getMessageThread(selectedCustomerId).then(res2 => setMessages(res2.messages));
      if(usersHavingUnreadMessages.filter(u=>u.id === selectedCustomerId).length > 0) {
        allierApi.setMessagesUnread(selectedCustomerId,true);
      }
    }
    else {
      allierApi.getConversations().then(res => {
        if (res.conversations.length > 0) {
          allierApi.getMessageThread(res.conversations[0].threadId).then(res2 => insertMessages(res2.messages));
        }
        else setMessages([]);
      });
      if(userIsAccountManager) allierApi.getUsersHavingUnreadMessages().then(res => setUsersHavingUnreadMessages(res));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerId])

  useEffect(() => {
    if (!userIsAccountManager) allierApi.getAccountManager(userId).then(res => setAccountManagerId(res ? res.userId : res));
    // Added size 200 temporally, need better solution.
    else allierApi.getCustomers('?size=1000&sort=givenName').then(({ customers }) => setCustomers(customers));
    refresh();
  }, [refresh, userId, userIsAccountManager]);

  if (messages == null) {
    return <BikeLoader />;
  }

  return (
    <>
      {userIsAccountManager &&
        <>
          <Container>
            <Label htmlFor="customerSelect">Kunde</Label>
            <Select
              id="customerSelect"
              aria-label="Kunde"
              onChange={e => {
                getMessageThread(e.target.value);
              }}
              value={selectedCustomerId}
            >
              <option key="key" aria-label="Uleste meldinger" value="">Uleste meldinger</option>
              {customers && customers.map(customer => (
                <option key={customer.email} value={customer.id}>
                  {customer.displayName()}
                </option>
              ))}
            </Select>
          </Container>
          { !selectedCustomerId &&
            <AccordionWidget
                title={`${usersHavingUnreadMessages.length > 0 ? 'Uleste kundemeldinger' : 'Ingen uleste kundemeldinger'}`}
                customContainer={!isMobile} wide
            >
              {usersHavingUnreadMessages.length > 0
                ?
                  <Table style={{backgroundColor: 'white'}}>
                    <TableHeader>
                      <ColumnHeader>Navn</ColumnHeader>
                      <ColumnHeader>Adresse</ColumnHeader>
                      <ColumnHeader style={{textAlign:'center'}}>Borettslag</ColumnHeader>
                      <ColumnHeader width="10%">
                        <HiddenRightContent>
                          <Button variant="inline" aria-label="Meny">
                            <ArrowRight />
                          </Button>
                        </HiddenRightContent>
                      </ColumnHeader>
                    </TableHeader>
                    {
                      usersHavingUnreadMessages.map((user) => (
                          <TableRow
                              key={user.id}
                              style={{cursor: 'pointer'}}
                              onClick={() => setSelectedCustomerId(user.id)}
                          >
                            <Cell>{`${user.givenName} ${user.familyName}`}</Cell>
                            <Cell>{user.address.streetAddress} {user.address.dwellingNumber ? ' - ' + user.address.dwellingNumber : ''}</Cell>
                            <Cell>{user.association.title}</Cell>
                            <Cell talign="right" width="10%">
                              <RightContent>
                                <Button variant="inline" aria-label="Meny">
                                  <ArrowRight />
                                </Button>
                              </RightContent>
                            </Cell>
                          </TableRow>
                      ))
                    }
                  </Table>
                : <></>
              }
            </AccordionWidget>
          }
        </>
      }
      {(selectedCustomerId || !userIsAccountManager) &&
        <MessageWidget
          userIsAccountManager={userIsAccountManager}
          messages={(userIsAccountManager && !selectedCustomerId) ? [] : messages}
          userId={userId}
          receiverId={userIsAccountManager ? selectedCustomerId : accountManagerId}
          sendMessage={allierApi.sendMessage}
          uploadFiles={allierApi.uploadFile}
          refresh={refresh}
          hideForm={userIsAccountManager && !selectedCustomerId}
        />
      }
      <div ref={bottomDivRef}/>
    </>
  );
}


export default MessagesPage;
