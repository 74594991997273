import { AccordionWidget } from 'components/elements';
import { Button, Text } from 'components/elements';
import { styled, getStyle } from 'style';
import { DocumentsIcon } from 'assets/icons';
import PropTypes from 'prop-types';
import React from 'react';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div:last-child{
    margin-top: ${getStyle('spacing.xs')};
  }

  * {
  overflow: hidden;
  text-overflow: ellipsis;

  :not(:last-child) {
    margin-bottom: ${getStyle('spacing.xs')};
  }
}
`;

const Item = styled.div`
    min-height: ${getStyle('size.widget.header.height')};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
`;

const ItemGray = styled.div`
    min-height: ${getStyle('size.widget.header.height')};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${getStyle('spacing.xs')};
    width: 100%;
    background: #BCBABB26;
    border-radius: ${getStyle('border.radius.sm')};
`;

const ProjectIdehefteWidget = ({ url }) => (
  <AccordionWidget icon={<DocumentsIcon />} title="Idèhefte">
    <Content>
      <ItemGray>
        <Text variant="md">Se og last ned vårt hefte med inspirasjon</Text>
      </ItemGray>
      <Item>
        <Button role="link" variant="secondary">
          <a href={url.url} download target="_blank" rel="noreferrer">Se og last ned vårt idèhefte</a>
        </Button>
      </Item>
    </Content>
  </AccordionWidget>
);

ProjectIdehefteWidget.propTypes = {
  url: PropTypes.object.isRequired
};

export default ProjectIdehefteWidget;
