import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Button, Text } from 'components/elements';
import { styled, getStyle, responsive } from 'style';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  width: 100%;
  
  ${props => responsive('padding', [
    {
      width: getStyle('screen.sm', props),
      value: `${getStyle('spacing.md', props)} ${getStyle('spacing.xl', props)}`
    }
  ])}
`;

const StyledText = styled.div`
  padding: ${getStyle('spacing.xl')};
  flex: 1;
  font-weight: ${props => (props.bold ? '600' : 'inherit')};
`;

const ChildStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReadMorePost = ({
  title, descriptionText, slug, buttonText, variant
}) => {
  let navigate = useNavigate();
  //Should fix this is a more general refactor of Sanity routing logic 
  const slugParsedForComplaintsUrl = slug.split(/[?#]/)[0] === "/borettslag/complaints" ? "/complaints" : slug;
  return (
  <ChildStyle>
    <Content>
      <StyledText bold>
        <Text variant="lg">
          {title}
        </Text>
      </StyledText>
      <StyledText>
        <Text variant="md">
          {descriptionText}
        </Text>
      </StyledText>
      {slug && (
        <ButtonContainer>
          <Button role="link" variant={variant} onClick={()=>{navigate(slugParsedForComplaintsUrl)}}>
            { buttonText || 'Les mer' }
          </Button>
        </ButtonContainer>
      )}
    </Content>
  </ChildStyle>
)};

ReadMorePost.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionText: PropTypes.string,
  descriptionBlocks: PropTypes.array,
  slug: PropTypes.string,
  buttonText: PropTypes.string,
  variant: PropTypes.string
};

export default ReadMorePost;
