/* eslint-disable react/prop-types */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { sanity } from 'services/sanity';
import { BlockContent } from 'components/elements';
import { styled, getStyle } from 'style';
import { CollapseIcon, ExpandIcon } from 'assets/icons';

const Title = styled.div`
  color: ${getStyle('color.widget.header')};
  font-size: ${getStyle('font.heading.lg')};
  margin: ${getStyle('spacing.lg')} 0;
  line-height: 2rem;
  svg {
    stroke: ${getStyle('color.widget.header')};
    width: 32px;
    min-width: 32px;
    margin-left: auto;
    margin-right: ${getStyle('spacing.md')};
  }
  display: flex;
`;

const ExpandleContent = styled.div`
  margin: ${getStyle('spacing.lg')} 0;
  line-height: 1rem;
  display: ${props => (props.isExpanded ? 'inherit' : 'none')};
`;

const ExpandleContainer = styled.div`
  background: ${getStyle('color.background.light')};
  margin: ${getStyle('spacing.lg')} 0;
  padding: ${getStyle('spacing.sm')};
  border-radius: 10px;
  cursor: pointer;
`;

const ExpandablePost = ({ props }) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <ExpandleContainer onClick={() => {
      setExpanded(!isExpanded);
    }}
    >
      <Title>
        {props.mark.expandableName} {isExpanded ? <CollapseIcon /> : <ExpandIcon /> }
      </Title>
      <ExpandleContent isExpanded={isExpanded}>
        <BlockContent
          blocks={props.mark.content}
          projectId={sanity.clientConfig.projectId}
          dataset={sanity.clientConfig.dataset}
        />
      </ExpandleContent>
    </ExpandleContainer>
  );
};

ExpandablePost.propTypes = {
  props: PropTypes.object.isRequired
};

export default ExpandablePost;
