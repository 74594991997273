import PropTypes from 'prop-types';
import { styled, getStyle, variant } from 'style';

const fontSize = variant('variant', {
  sm: getStyle('font.sm'),
  md: getStyle('font.md'),
  lg: getStyle('font.lg'),
  xl: getStyle('font.xl')
});

const Text = styled.p`
  font-size: ${fontSize};
`;

Text.propTypes = {
  variant: PropTypes.oneOf(['sm', 'md', 'lg', 'xl'])
};

Text.defaultProps = {
  variant: 'md'
};

export default Text;
