import PropTypes from 'prop-types';
import { Button } from 'components/elements';
import { styled } from 'style';

import { NotificationIcon } from 'assets/icons';

import AlertIcon from './AlertIcon';
import NotificationList from './NotificationList';


const OverlayAlertIcon = styled(AlertIcon)`
  margin-top: -0.4rem;
  margin-left: 0.6rem;
`;

const NotificationButton = ({
  notificationData,
  isOverlayVisible,
  showOverlay,
  renderOverlay,
  setNotificationRead,
  refreshNotifications,
  isUser
}) => {
  const renderButton = notifications => {
    const newNotifications = notifications.filter(n => n.status==='new').length;

    const doShowOverlay = show => {
      if (!show) {
        notifications.filter(n => n.status==='new').forEach(n => setNotificationRead(n.id,'read'));
        //TODO: Billig triks...dette må forbedres
        setTimeout(() => {  refreshNotifications(); }, 2000);
      }
      showOverlay(show);
    };

    return (
      <>
        <Button
          variant="inline"
          aria-label="Varsler"
          onClick={() => doShowOverlay(!isOverlayVisible)}
        >
          <NotificationIcon />
          <OverlayAlertIcon hidden={newNotifications === 0}>{newNotifications}</OverlayAlertIcon>
        </Button>

        {isOverlayVisible && renderOverlay(
          <NotificationList
            isUser={isUser}
            notifications={notifications}
            onClose={() => doShowOverlay(false)}
          />
        )}
      </>
    );
  };

  return (
    renderButton(notificationData)
  );
};

NotificationButton.propTypes = {
  notificationData: PropTypes.array.isRequired,
  maxNotifications: PropTypes.number.isRequired,
  isOverlayVisible: PropTypes.bool.isRequired,
  showOverlay: PropTypes.func.isRequired,
  renderOverlay: PropTypes.func.isRequired,
  setNotificationRead: PropTypes.func.isRequired
};

NotificationButton.defaultProps =  {
  notificationData: [],
  maxNotifications: 5,
  isOverlayVisible: false,
  showOverlay: () => {},
  renderOverlay: () => {},
  setNotificationRead: ()  => {}
}

export default NotificationButton;
