import PropTypes from 'prop-types';
import { sanity } from 'services/sanity';
import { BlockContent, PhaseStepper, AccordionWidget } from 'components/elements';
import { styled, getStyle } from 'style';

import { DoubleCheck } from 'assets/icons';

const StyledBlock = styled.div`
background: ${getStyle('color.background.light')};
margin: ${getStyle('spacing.lg')} 0;
padding: ${getStyle('spacing.sm')};
border-radius: 10px;
cursor: pointer;
`;

const CheckList = ({ post }) => (
  <div>
    <AccordionWidget icon={<DoubleCheck />} title={post.title} wide>
      <PhaseStepper
        key={post._key}
        phases={post.checklist
          .map(item => ({ id: item._key, title: item.title, blocks: item.description }))}
        wide
        forceMobile
      />
      <StyledBlock>
        <BlockContent
          blocks={post.body}
          projectId={sanity.clientConfig.projectId}
          dataset={sanity.clientConfig.dataset}
        />
      </StyledBlock>
    </AccordionWidget>
  </div>
);

CheckList.propTypes = {
  post: PropTypes.object.isRequired
};

export default CheckList;
