import { styled, getStyle } from 'style';

export const H1 = styled.h1`
  font-size: ${getStyle('font.heading.xl')};
  font-weight: bold;
`;

export const H2 = styled.h2`
  font-size: ${getStyle('font.heading.lg')};
  font-weight: 500;
`;

export const H3 = styled.h3`
  font-size: ${getStyle('font.heading.md')};
  font-weight: bold;
`;

export const H4 = styled.h4`
  font-size: ${getStyle('font.heading.sm')};
  font-weight: bold;
`;
