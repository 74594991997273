import PropTypes from 'prop-types';
import { Match } from 'components/elements';
import { styled, getStyle } from 'style';

import InfoContainer from './InfoContainer';

const Title = styled.div`
  color: ${getStyle('color.widget.subtitle')};
  font-size: ${getStyle('font.sm')};
`;
const Subtitle = styled.div`
  font-size: ${getStyle('font.md')};
  color: #000;
`;

const Link = styled.a``;

const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Icon = styled.div`
  background: #fff;
  color: ${getStyle('color.widget.subtitle')};
  border: 0.125rem solid #AEA0CD;
  border-radius: 50%;
  padding: ${getStyle('spacing.xs')};
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: ${getStyle('spacing.md')};
  svg {
    stroke-width: 1.2;
    height:10px;
    width:10px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${getStyle('spacing.sm')};
  margin-bottom: ${getStyle('spacing.sm')};
  background: ${getStyle('color.widget.li.background')};

  color: ${getStyle('color.widget.header')};
  stroke: ${getStyle('color.widget.header')};
  fill: ${getStyle('color.widget.header')};

  min-height: ${getStyle('size.widget.header.height')};
  border-radius: ${getStyle('border.radius.sm')};
`;


const HeaderSection = ({ title, subtitle, icon, style }) => (
  <Header style={style}>
    {icon && (<Icon>{icon}</Icon>)}
    <HeaderGroup>
      <Title>{title}</Title>
      {subtitle && (
        <Subtitle>
          {subtitle}
        </Subtitle>
      )}
    </HeaderGroup>
  </Header>
);

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  icon: PropTypes.object
};

/*
const Field = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => (props.warn ? getStyle('color.status.warn', props) : 'inherit')};

  span:last-child {
    margin-left: ${getStyle('spacing.xs')};
    text-align: right;
  }
`;
*/

const FieldList = ({ header, fields, footer }) => (
  <InfoContainer>
    {header && header()}
    {fields.map((field) => (
      <Match
        key={field.label}
        value={field.type || "text"}
        tel={() => (
          <HeaderSection
            key={field.label}
            title={field.label}
            subtitle={<Link href={`tel:${field.value}`}>{field.value}</Link>}
            icon={field.icon}
          />
        )}
        email={() => (
          <HeaderSection
            key={field.label}
            title={field.label}
            subtitle={<Link href={`mailto:${field.value}`}>{field.value}</Link>}
            icon={field.icon}
          />
        )}
        bookmark={() => (
          <a
            href={field.value}
            download={!!field.download}
            target={field.target}
          >
            <HeaderSection
              key={field.label}
              title=""
              subtitle={<p>{field.label}</p>}
              icon={field.icon}
            />
          </a>
        )}
        url={() => (
          <HeaderSection
            key={field.label}
            title={field.label}
            subtitle={
              <Link
                href={field.value}
                target={!field.value.startsWith("/") && "_blank"}
              >
                {field.value}
              </Link>
            }
            icon={field.icon}
          />
        )}
        empty={() => (
          <HeaderSection style={{ backgroundColor: "white" }} title="" />
        )}
        default={() => (
          <HeaderSection
            key={field.label}
            title={field.label}
            subtitle={field.value}
            icon={field.icon}
          />
        )}
      />
      /* <Field key={field.label} warn={field.warn}>
        <span>{`${field.label}:`}</span>
        <Match
          value={field.type || 'text'}
          tel={() => (
            <Link href={`tel:${field.value}`}>{field.value}</Link>
          )}
          url={() => (
            <Link
              href={field.value}
              target={!field.value.startsWith('/') && '_blank'}
            >
              {field.value}
            </Link>
          )}
          default={() => (
            <span>{field.value}</span>
          )}
        />
      </Field> */
    ))}
    {footer && footer()}
  </InfoContainer>
);

FieldList.propTypes = {
  header: PropTypes.func,
  fields: PropTypes.array.isRequired,
  footer: PropTypes.func
};

export default FieldList;
