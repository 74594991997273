import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { styled, getStyle } from 'style';

import CollapseIcon from 'assets/icons/item_collapse.svg';
import ExpandIcon from 'assets/icons/item_expand.svg';

const Header = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: ${getStyle('spacing.md')};
    height: ${getStyle('size.icon.md')};
  }
`;

const Accordion = ({
  title, children, expanded, onExpanded, onCollapsed
}) => {
  const [isExpanded, setExpanded] = useState();

  useEffect(() => { setExpanded(expanded); }, [expanded]);

  return (
    <div>
      <Header
        onClick={() => {
          setExpanded(!isExpanded);
          (isExpanded ? onCollapsed : onExpanded)();
        }}
      >
        <span>{title}</span>
        {(isExpanded
          ? <CollapseIcon aria-label="Skjul innhold" />
          : <ExpandIcon aria-label="Vis innhold" />
        )}
      </Header>
      {isExpanded && children}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onExpanded: PropTypes.func,
  onCollapsed: PropTypes.func
};

Accordion.defaultProps = {
  expanded: false,
  onExpanded: () => {},
  onCollapsed: () => {}
};

export default Accordion;
