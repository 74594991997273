import config from '../../config.js';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () => (
  window.history.replaceState({}, document.title, window.location.pathname)
);

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

let _client;
let _user;

const createClient = async initOptions => {
  _client = await createAuth0Client(initOptions);
  return _client;
};

export const getUserId = () => (
  _user && _user.sub
);

export const getTokenSilently = () => (
  _client.getTokenSilently()
);

const isClient = typeof window !== 'undefined';

const getUserRoles = () => (
  isClient ? JSON.parse(localStorage.getItem('userRoles') || '[]') : []
);

const setUserRoles = user => (
  isClient && localStorage.setItem('userRoles', JSON.stringify(user[config.authRolesKey] || []))
);

export const clearUserRoles = () => window.localStorage.removeItem('userRoles');

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState({ [config.authRolesKey]: getUserRoles() });
  const [auth0Client, setAuth0] = useState();
  const [isLoading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const updateUser = async client => {
    _user = await client.getUser();
    setUserRoles(_user);
    setUser(_user);
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const client = await createClient(initOptions);
      setAuth0(client);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const authenticated = await client.isAuthenticated();

      if (authenticated) {
        await updateUser(client);
      }

      setIsAuthenticated(authenticated);
      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
      await updateUser(auth0Client);
      setIsAuthenticated(true);
    } finally {
      setPopupOpen(false);
    }
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    await updateUser(auth0Client);
    setIsAuthenticated(true);
    setLoading(false);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...args) => auth0Client.getIdTokenClaims(...args),
        loginWithRedirect: (...args) => auth0Client.loginWithRedirect(...args),
        getTokenSilently: (...args) => auth0Client.getTokenSilently(...args),
        getTokenWithPopup: (...args) => auth0Client.getTokenWithPopup(...args),
        logout: (...args) => auth0Client.logout(...args),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.object,
  onRedirectCallback: PropTypes.func
};
