import { useState, useEffect } from 'react';
import { useAuth, isEmployee } from 'services/auth';
import { allierApi, sanityApi } from 'services';
import { BikeLoader } from 'components/loading';
import { Article, Category } from './components';

const Blog = () => {

  const { userId, user } = useAuth();
  const [borettslag, setBorettslag] = useState(null);
  const [profile, setProfile] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [associationId, setAssociationId] = useState("");

  useEffect(()=>{
    const employee = isEmployee(user);
    if(!employee) {
      allierApi.getUserProfile(userId).then(profile => {
        setProfile(profile);
        allierApi.getCustomerContacts(profile.id).then(contacts => {
          setContacts(contacts);
        });
        sanityApi.fetchAssociationAndCategories(profile.association.sanityId).then(res=>{
          setBorettslag(res);
        });
      });
    } else {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let associationId = params.association_id;
      setAssociationId(associationId);
      sanityApi.fetchAssociationAndCategories(associationId).then(res=>{
        setBorettslag(res);
      });
    }
  },[userId]);

  if(borettslag == null) {
    return <BikeLoader/>;
  }

  return (
    <Article
        profile={profile}
        title={borettslag.borettslag.name}
        subtitle="Her vil dere finne relevant informasjon om hva som skal skje
      når din leilighet rehabiliteres"
        ingress={borettslag.borettslag.bio}
        imageUrl={borettslag.borettslag.image.asset.url}
        borettslag={borettslag.borettslag}
        contacts={contacts}
      >
        { borettslag.categories.map(category => (
          <Category
            key={category.title}
            category={category}
            associationId={associationId}
          />
        ))}
      </Article>
  );
}

export default Blog;
