import { styled, getStyle, responsive } from 'style';

export const Table = styled.div`
  > div, [role=row] {
    display: flex;
    align-items: center;

    padding: 0 ${getStyle('spacing.md')};
    height: ${getStyle('size.widget.li.height')};
    border-bottom: 0.0625rem solid ${getStyle('color.border.primary')};

  ${props => responsive('padding', [
    { width: getStyle('screen.sm', props), value: `0 ${getStyle('spacing.xxl', props)}` }
  ])}

    :nth-child(even) {
      background: ${getStyle('color.widget.tr.background')};
    }
  }
`;

Table.defaultProps = {
  role: 'table'
};

export const TableHeader = styled.div`
  font-size: ${getStyle('font.md')};
  font-weight: bold;
  background: ${getStyle('color.widget.th.background')};
  & :not(:first-child,:nth-last-child(2)) {
    text-align: center;
  }
`;

TableHeader.defaultProps = {
  role: 'row'
};

export const ColumnHeader = styled.div`
  width: ${props => props.width || '100%'};
  text-align: ${props => props.talign || 'inherit'};
`;

ColumnHeader.defaultProps = {
  role: 'columnheader'
};

export const TableRow = styled.div`
  font-size: ${getStyle('font.sm')};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  & :first-letter {
    text-transform: uppercase;
  }
  & :not(:first-child,:last-child) {
    text-align: center;
  }
`;

TableRow.defaultProps = {
  role: 'row'
};

export const Cell = styled.div`
  width: ${props => props.width || '100%'};
  text-align: ${props => props.talign || 'inherit'};
  & :first-letter {
    text-transform: uppercase;
  }
`;

Cell.defaultProps = {
  role: 'cell',
  talign: 'inherit'
};
