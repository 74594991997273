import PropTypes from 'prop-types';
import { styled, getStyle } from 'style';
import { ExpandIcon } from 'assets/icons/index';

const Container = styled.div`
  position: relative;

  select {
    border: 0.0625rem solid ${getStyle('color.button.background.primary')};
    border-radius: ${getStyle('border.radius.sm')};
    padding: 0 ${getStyle('spacing.sm')};
    height: ${getStyle('size.input.height')};
    width: 100%;
  
    appearance: none;
    outline: none;
  }

  svg {
    position: absolute;
    width: ${getStyle('size.icon.md')};
    top: 1rem;
    right: 0.2rem;
    background: white;
    pointer-events: none;
  }
`;

const Select = ({ children, ...props }) => (
  <Container>
    <select {...props}>{children}</select>
    <ExpandIcon />
  </Container>
);

Select.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Select;
