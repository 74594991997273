import PropTypes from 'prop-types';
import { styled, getStyle } from 'style';
import { BlockContent, Widget } from 'components/elements';

const Container = styled(Widget)`
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  svg,img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${getStyle('spacing.lg')};
  display: flex;
  flex-direction: column;
  
  > div {
    margin-bottom: ${getStyle('spacing.lg')};
  }
`;

const Title = styled.div`
  color: ${getStyle('color.widget.header')};
  margin-bottom: ${getStyle('spacing.lg')};
  font-size: ${getStyle('font.heading.lg')};
  font-weight: bold;
`;

const Card = ({
  title, sanity, text, block, image, wide, children, onclickFunc
}) => (
  <Container>
    {image && (<>{image}</>)}
    <Content style={{cursor: 'pointer'}} onClick={onclickFunc}>
      <Title>{title}</Title>
      {text && <div>{text}</div>}
      {block && (
      <BlockContent
        blocks={block}
        projectId={sanity.clientConfig.projectId}
        dataset={sanity.clientConfig.dataset}
      />
      )}
      {children && <>{children}</>}
    </Content>
  </Container>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  sanity: PropTypes.object.isRequired,
  text: PropTypes.string,
  block: PropTypes.array,
  image: PropTypes.object,
  wide: PropTypes.bool,
  children: PropTypes.object
};

export default Card;
