import { styled } from 'style';

const ExcelStyledTable = styled.table`
  border: solid #B0CBEF;
  border-width: 1px 1px 1px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 10px;
  
  td {
    background-color: white;
    padding: 0 4px 0 2px;
    border: solid #D0D7E5;
    border-width: 1px 1px 1px 0;
  }
  
  td b {
    border: 0;
    background-color: white;
    font-weight: bold;
  }
  
  th {
    font-weight: bold;
    font-size: 14px;
    border: solid #9EB6CE;
    border-width: 0 1px 1px 0;
    height: 17px;
    line-height: 17px;
    text-align: center;
  }
  
`;

const ExcelTable = ({ data, cols }) => {
  return (
    <div>
      <ExcelStyledTable>
        <tbody>
          <tr>
            {
              cols.map((c) =>
                <th key={c.key}>{c.key === -1 ? "" : c.name}</th>
              )
            }
          </tr>
          {data.map((r,i) =>
            <tr key={i}>
              {<td key={i}>{i}</td>}
              {cols.map(c => <td style={{fontWeight: i===0 ? 'bold' : 'normal'}} key={c.key}>{ r[c.key] }</td>)}
            </tr>
          )}
        </tbody>
      </ExcelStyledTable>
    </div>
  )
};

export default ExcelTable;
