import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { styled } from 'style';

const Container = styled.div`
  overflow: hidden;
`;

const ItemContainer = styled.div`
  position: relative;
  width: ${props => `${(100 * props.items) / props.pageSize}%`};

  > button {
    float: left;
    width: ${props => `${100 / props.items}%`};
    text-align: center;
    outline: none;

    -webkit-tap-highlight-color: transparent;
  }
`;

// May need to smarten up this one ...
const calculatePageSize = values => {
  const maxLength = values[values.length - 1].length;
  if (maxLength <= 2) return 9;
  if (maxLength <= 4) return 7;
  if (maxLength <= 6) return 5;
  return 3;
};

const calculateContainerOffset = (pageSize, selectedIndex) => (
  100 * ((Math.floor(pageSize / 2) - selectedIndex) / pageSize)
);

const calculateItemOpacity = (pageSize, indexOffset) => (
  1 - (indexOffset / Math.floor(pageSize / 2)) * 0.8
);

const applyStyle = ({ containerRef, pageSize, selectedIndex }) => {
  const itemContainer = containerRef.current.firstChild;
  itemContainer.childNodes.forEach((n, i) => (
    i === selectedIndex
      ? n.firstChild.classList.add('selected')
      : n.firstChild.classList.remove('selected')
  ));
  itemContainer.childNodes.forEach((n, i) => {
    const node = n.firstChild;
    node.style = `opacity: ${calculateItemOpacity(pageSize, Math.abs(selectedIndex - i))}`;
  });

  itemContainer.style = `left: ${calculateContainerOffset(pageSize, selectedIndex)}%`;
};

const Slider = ({
  label,
  values,
  selectedValue,
  selectedValueChanged,
  renderValue
}) => {
  const selectedIndex = values.indexOf(selectedValue);

  const containerRef = useRef();
  const [swipeOffset, setSwipeOffset] = useState(null);
  const [pageSize] = useState(calculatePageSize(values));

  useEffect(() => {
    applyStyle({ containerRef, pageSize, selectedIndex });
  }, [selectedValue]);

  useEffect(() => { setSwipeOffset(null); }, [selectedValue]);

  const swipingHandlers = useSwipeable({
    onSwiping: (e) => {
      const itemWidth = containerRef.current.clientWidth / pageSize;
      const offset = Math.round(e.deltaX / itemWidth);
      const index = selectedIndex + offset;

      if (offset !== swipeOffset && index >= 0 && index < values.length) {
        applyStyle({ containerRef, pageSize, selectedIndex: index });
        setSwipeOffset(offset);
      }
    },
    onSwiped: () => selectedValueChanged(selectedIndex + swipeOffset),
  });

  return (
    <div {...swipingHandlers}>
      <Container
        aria-label={label}
        aria-valuenow={selectedValue}
        role="slider"
        ref={containerRef}
      >
        <ItemContainer
          pageSize={pageSize}
          items={values.length}
        >
          {values.map(value => (
            <button
              key={value}
              type="button"
              aria-label={value}
              onClick={() => (
                swipeOffset === null && selectedValueChanged(value)
              )}
            >
              {renderValue(value)}
            </button>
          ))}
        </ItemContainer>
      </Container>
    </div>
  );
};

Slider.propTypes = {
  label: PropTypes.string,
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  selectedValueChanged: PropTypes.func.isRequired,
  renderValue: PropTypes.func.isRequired
};

export default Slider;
