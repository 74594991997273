import { useEffect, useState } from 'react';
import { useNavigate, Navigate } from "react-router-dom";
import { useAuth, isEmployee} from 'services/auth';
import { allierApi, sanityApi } from 'services';
import { styled, getStyle, responsive } from 'style';
import {Button, AccordionWidget, AccountManagerWidget, WrapContainer, PhaseStepper, BlockContent} from 'components/elements';
import { BikeLoader } from 'components/loading';
import { ProjectInfoWidget } from 'pages/projects';
import { ProcessIcon } from 'assets/icons';
import { default as WelcomeHeader } from './WelcomeHeader';
import {sanity} from "../../services/sanity";
import {Dialog} from "../../components/dialog";
import ToggleButton from "../../components/elements/ToggleButton";

const ButtonContainer = styled.div`
  margin-bottom: ${getStyle('spacing.md')};
  display: flex;
  width: 100%;

  > button {
    width: 100%;
  }
`;

const ToggleButtonContainer = styled.div`
  display: flex;
  line-height: 30px;
`;

const ToggleButtonText = styled.div`
  margin-left: 10px;
  font-weight: bold;
  font-size: 0.9em;
`;

const DashboardPage = () => {
  const navigate = useNavigate();

  const { userId, user } = useAuth();
  const [project, setProject] = useState(null);
  const [profile, setProfile] = useState(null);
  const [manager, setManager] = useState(null);
  const [consentGdpr, setConsentGdpr] = useState(false);
  const [consentMarketing, setConsentMarketing] = useState(false);
  const [gdprDialogOpen, setGdprDialogOpen] = useState(false);
  const [associationChecklist, setAssociationChecklist] = useState(null);
  const [gdprBlock, setGdprBlock] = useState(null);
  const isUser = !isEmployee(user);

  useEffect(() => {
    if(isUser){
      allierApi.getProjects().then(res => setProject(res[0]));
      allierApi.getUserProfile(userId).then(profile => {
        setProfile(profile);
        if(profile.association.id) sanityApi.fetchAssociationChecklist(profile).then(resp => setAssociationChecklist(resp.borettslag.checklist));
        if(!profile.consentGdpr)
        {
          sanityApi.fetchAllierPublic().then(res => {
            setGdprBlock(res.allier.welcomeGdpr);
            setGdprDialogOpen(true);
          });
        }
      });
      allierApi.getAccountManager(userId).then(manager => setManager(manager));
    }
  }, [isUser, userId]);

  if(!isUser){
    return <Navigate to="/accountmanager/dashboard" />
  }

  if (project === null || profile === null || manager === null) {
    return <BikeLoader />;
  }

  const updateUserConsent = () => {
    allierApi.updateUserConsent(userId, consentGdpr, consentMarketing).then(() => {
      setGdprDialogOpen(false);
    });
  }

  const welcomeText = !profile.association.id
    ? "Her vil du finne relevant informasjon om hva som skal skje når din bolig rehabiliteres."
    : "Her vil du finne relevant informasjon om hva som skal skje når din leilighet rehabiliteres, samt felles informasjon for ditt borettslag."

  return (
    <>
      <Dialog
          title={"Velkommen til Allierportalen"}
          isOpen={gdprDialogOpen}
          onClose={() => setGdprDialogOpen(false)}
          wide
          variant={"hands"}
      >
        <BlockContent
            blocks={gdprBlock}
            projectId={sanity.clientConfig.projectId}
            dataset={sanity.clientConfig.dataset}
        />
        <ToggleButtonContainer>
          <ToggleButton checked={consentMarketing} onChange={(e) => setConsentMarketing(e)} />
          <ToggleButtonText>Ønsker du å motta direkte markedsføring?</ToggleButtonText>
        </ToggleButtonContainer>
        <ToggleButtonContainer>
          <ToggleButton checked={consentGdpr} onChange={(e) => setConsentGdpr(e)} />
          <ToggleButtonText>Vi tar personvern på alvor</ToggleButtonText>
        </ToggleButtonContainer>
        <ButtonContainer>
          <Button variant="primary" onClick={updateUserConsent}>Lagre</Button>
        </ButtonContainer>
      </Dialog>
      <WelcomeHeader title="Velkommen til portalen," subTitle={profile.displayName()} welcomeText={welcomeText}  />
      <AccordionWidget icon={<ProcessIcon />} title="Prosessoversikt" wide showBranding>
        {associationChecklist && project && <PhaseStepper phases={associationChecklist.map(item => ({ id: item._key, title: item.title, blocks: item.description }))} currentPhaseId={project.currentPhaseId} />}
        <Container>
          <div>
            {welcomeText}
          </div>
          <Button variant="secondary" onClick={() => { navigate('/borettslag/andelseiers-plikter') }}>
            Les mer om mine plikter
          </Button>
        </Container>
      </AccordionWidget>
      <WrapContainer>
        {project && <ProjectInfoWidget customerProfile={profile} project={project} />}
        <AccountManagerWidget manager={manager} addEmptySpace/>
      </WrapContainer>
    </>
  )
}

export default DashboardPage;

const Container = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background: #BCBABB26;
  border-radius: 10px;
  padding: ${getStyle('spacing.lg')} ${getStyle('spacing.lg')};

  div {
    margin-right: ${getStyle('spacing.lg')}
  }
  ${props => responsive('flex-direction', [
  {
    width: getStyle('screen.sm', props),
    value: 'row'
  }
])}
`;
