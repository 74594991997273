import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export const getUrlParamFromQueries = (queries = {}) => {
  const keys = Object.keys(queries).filter((key) => !!queries[key]);
  return keys.length
    ? `?${keys.map((key) => `${key}=${queries[key]}`).join('&')}`
    : '';
};

const useURLParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueriesObj = useCallback(() => {
    const queries = {};
    searchParams.forEach((value, key) => {
      queries[key] = value;
    });

    return queries;
  }, [searchParams]);

  const updateQueries = useCallback((queries = {}) => {
    const newQueries = { ...getQueriesObj(), ...queries };
    const queriesToAdd = {};

    Object.keys(newQueries).forEach(key => {
      if (newQueries[key] !== '') {
        queriesToAdd[key] = newQueries[key];
      }
    });

    setSearchParams(queriesToAdd);
  }, [getQueriesObj, setSearchParams]);

  const resetAllQueries = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return {
    queries: getQueriesObj(),
    updateQueries,
    resetAllQueries
  };
};

export default useURLParam;