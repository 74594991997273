import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Button } from 'components/elements';
import { styled, getStyle } from 'style';

import { ReactComponent as Icon } from 'assets/icons/back.svg';

const Container = styled.div`
  margin-bottom: ${getStyle('spacing.sm')};
`;

const BackButton = ({ children, url }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Button variant="link" role="link" onClick={() => navigate(url)}>
        <Icon />
        <span>{children}</span>
      </Button>
    </Container>
  );
};

BackButton.propTypes = {
  children: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default BackButton;
