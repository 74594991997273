import React from 'react';
import { AllierLogo } from 'assets/logos';
import { getStyle, styled } from 'style';

const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - calc(calc(${getStyle('size.header.height')} + ${getStyle('spacing.xxxl')}) * 3));
  margin: 0;
  display: flex;
  align-content: center;
  justify-content: center;
`;
const StyledAllierLogo = styled(AllierLogo)`
  width: ${getStyle('size.menu.width.md')}},
  polygon {
    stroke: transparent;
  }
  .dot-1 { animation: animation 1s ease-in-out infinite alternate-reverse .2s; }
  .dot-2 { animation: animation 1s ease-in-out infinite alternate-reverse .4s; }
  .dot-3 { animation: animation 1s ease-in-out infinite alternate-reverse .6s; }
  .dot-4 { animation: animation 1s ease-in-out infinite alternate-reverse .8s; }
  .dot-5 { animation: animation 1s ease-in-out infinite alternate-reverse 1s; }
  @keyframes animation {
    0% { fill: black; }
    100% { fill: rgba(0,0,0,.1); }
  }
`;

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #424242;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const BikeLoader = ({ type = 'allier', ...rest }) => (
  <LoadingContainer {...rest}>
    { type === 'allier' ? <StyledAllierLogo /> : <Spinner /> }
  </LoadingContainer>
);

export default BikeLoader;