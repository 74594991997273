/* eslint-disable quote-props */
import { getTokenSilently } from 'services/auth';

const bearerToken = token => `Bearer ${token}`;

class HttpError extends Error {
  constructor(response) {
    super(`${response.status} ${response.statusText}`);
    this.statusCode = response.status;
  }
}

export const httpGet = url => (
  getTokenSilently().then(token => (
    fetch(url, {
      headers: { 'Authorization': bearerToken(token) }
    })
  ))
);

export const httpPost = (url, payload) => (
  getTokenSilently().then(token => (
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': bearerToken(token),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  ))
);

export const httpPut = (url, payload) => (
  getTokenSilently().then(token => (
    fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': bearerToken(token),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  ))
);

export const httpPutFile = (url, file) => (
  getTokenSilently().then(token => (
    fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': bearerToken(token),
        'Content-Type': file.type
      },
      body: file
    })
  ))
);

export const httpPostFile = (url, file, options = {}) => (
  getTokenSilently().then(token => (
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': bearerToken(token),
        ...options,
      },
      body: file
    })
  ))
);

export const httpDelete = url => (
  getTokenSilently().then(token => (
    fetch(url, {
      method: 'DELETE',
      headers: { 'Authorization': bearerToken(token) }
    })
  ))
);

export const httpPatch = url => (
  getTokenSilently().then(token => (
      fetch(url,{
        method: 'PATCH',
        headers: { 'Authorization': bearerToken(token) }
      })
  ))
);

export const throwOnError = response => {
  if (!response.ok) {
    throw new HttpError(response);
  }
  return response;
};

export const jsonOrThrow = response => (
  throwOnError(response).json()
);
