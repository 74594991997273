import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHeader } from 'components/elements';
import { CUSTOMER_FORM_FIELDS, CUSTOMER_FORM_FIELDS_AFTER_IMPORT } from './importConfig';
import UserTableRows, { TableCell } from './UserTableRows';
import { styled } from 'style';

const UserTableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const UserTable = ({ userList, hasExcel, showResult }) => {
  const tableColumns = showResult ? CUSTOMER_FORM_FIELDS_AFTER_IMPORT : CUSTOMER_FORM_FIELDS;

  return hasExcel
    ? (
      <UserTableContainer>
        <Table style={{ minWidth: '65rem' }}>
          <TableHeader style={{ position: 'relative' }}>
            {
              tableColumns.map(({ label, isIcon = false }) => (
                <TableCell
                  content={label}
                  key={label}
                  isHeader={true}
                  isIcon={isIcon}
                >
                  {label}
                </TableCell>
              ))
            }
          </TableHeader>
          {
            userList.map((profile) => (
              <UserTableRows profile={profile} key={`${profile.email}-${profile.phone}`} tableColumns={tableColumns}/>
            ))
          }
        </Table>
      </UserTableContainer>
    )
    : null;
}

UserTable.defaultProps = {
  userList: [],
  hasExcel: false,
  showResult: false,
};

UserTable.propTypes = {
  hasExcel: PropTypes.bool,
  showResult: PropTypes.bool,
  userList: PropTypes.arrayOf(PropTypes.shape({
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    streetAddress: PropTypes.string,
    zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    city: PropTypes.string,
    dwellingNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    electronicInvoice: PropTypes.string,
  })),
};

export default UserTable;
