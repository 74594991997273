import { curry, reduce, has } from 'ramda';

const getStyle = curry(
  (posId, { theme }) => reduce(
    (acc, val) => {
      if (!has(val, acc)) {
        /* eslint-disable-next-line */
        console.error(`Prop ${val} not found in theme. Check spelling`);
      }
      return acc[val];
    },
    theme,
    posId.split('.')
  )
);

export default getStyle;

/* Use with styled-components to have a organized theme like:
   const theme = {
     font: {
       heading: {
         large: '2rem'
       }
     }
   };

   So this:
   const Header = styled.h1`
     font-size: ${({ theme }) => theme.font.heading.large};
     color: ${props => props.theme.color.text.primary};
   `;

   Becomes:
   const Header = styled.h1`
     font-size: ${getStyle('font.heading.large')};
     color: ${getStyle('color.text.primary')};
  `;
 *
 */
