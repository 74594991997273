import React from 'react';
import PropTypes from 'prop-types';
import { ProcessIcon } from 'assets/icons';
import { styled, getStyle } from 'style';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${getStyle('spacing.md')};
  width: 100%;

  > *:first-child {
    margin-right: ${getStyle('spacing.lg')};
    min-width: 2.5rem;
    width: 2.5rem;
  }

  > *:last-child {
    height: 1.5rem;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  span:first-child {
    color: ${getStyle('color.widget.header')};
    font-weight: bold;
  }
`;

const CompanyHeader = ({ title, subTitle }) => (
  <Container>
    <ProcessIcon />
    <Title>
      <span>{title}</span>
      <span>{subTitle}</span>
    </Title>
    <img src="/logos/unik.png" alt="Unik" title="Unik" />
  </Container>
);

CompanyHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired
};

export default CompanyHeader;
