import { styled, getStyle } from 'style';

const AlertIcon = styled.span`
  position: absolute;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background: ${getStyle('color.status.alert')};
  line-height: 0.875rem;
  font-size: 0.5rem;
  color: white;
`;

export default AlertIcon;
