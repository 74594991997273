import React, { useCallback } from 'react';
import { ArrowRight } from 'assets/icons';
import { styled, responsive, getStyle } from 'style';
import InspirationPageText from './InspirationPageText';
import { useInspirationNavigation } from './hooks';

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: ${({ imageType, isMobile }) => {
    switch (imageType) {
      case 'l-12-5': return '100%';
      case 'l-4-3': return '100%';
      case 'm-1-1': return isMobile ? '100%' : 'calc(50%  - .5em)';
      case 's-1-1-s':
      case 's-1-1': return isMobile ? 'calc(50%  - .5em)' : 'calc(25%  - .75em)';
      case 'm-3-4': return isMobile ? '100%' : 'calc(33.3%  - .665em)';
      default: return '100%';
    }
  }};
  
  ${({ isMobile, imageType }) => !isMobile && imageType === 's-1-1-s'
    ? 'margin-right:calc(4.17%  + .03em); margin-left: calc(4.17%  + .03em);'
    : '0'
  }
  
`;

const Image = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: ${({ hasLink }) => hasLink ? 'pointer' : 'unset'};
  width: 100%;
  position: relative;
  overflow: hidden;

  aspect-ratio: ${({ imageType }) => {
    switch (imageType) {
      case 'l-12-5': return '12/5';
      case 'l-4-3': return '4/3'
      case 'm-1-1':
      case 's-1-1-s':
      case 's-1-1': return '1/1';
      case 'm-3-4': return '3/4';
      default: return '16/9';
    }
  }};

  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  border-radius: ${({ imageType }) => {
    switch (imageType) {
      case 'l-12-5': return '30px';
      default: return '0';
    }
  }};
`;

const ImageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #6750A5;
  white-space: nowrap;
  color: white;
  width: fit-content;

  svg {
    stroke: white;
    width: 10px;
  }

  &.size-l-12-5, &.size-l-1-1 {
    margin: 1.5em 0.75em 0.75em 0;
    padding: .6em 1.2em;
    font-size: 14px;
    svg { 
      margin-left: 0.5em;
      
      ${props => responsive('margin-left', [
        {
          width: getStyle('screen.sm', props),
          value: '2em'
        }
        ])}    
    }

    ${props => responsive('font-size', [
      {
        width: getStyle('screen.sm', props),
        value: '22px'
      }
      ])}

      ${props => responsive('margin', [
        {
          width: getStyle('screen.sm', props),
          value: '1.5em'
        }
        ])}      
  }
  
  &.size-m-1-1, &.size-m-3-4 {
    margin: 1em;
    padding: .3em 1em;
    font-size: 16px;
    svg { margin-left: .7em }
  }
  
  &.size-s-1-1 {
    margin: .5em;
    padding: .2em .7em;
    font-size: 12px;
  }
  
  &.bildebånd {
    justify-content: center;
    transform: rotate(-45deg);
    padding-left: 50%;
    padding-right: 50%;
    margin: 10% -40%;
  }
`;

const getTitleSizeClassName = (imageType, titleType = '') => {
  const titleTypeClassName = titleType.toLowerCase().replace(' ', '-');

  switch (imageType) {
    case 'l-12-5': return `size-l-12-5 ${titleTypeClassName}`;
    case 'l-4-3': return `size-l-1-1 ${titleTypeClassName}`;
    case 'm-1-1': return `size-m-1-1 ${titleTypeClassName}`;
    case 's-1-1-s': return `size-s-1-1 ${titleTypeClassName}`;
    case 's-1-1': return `size-s-1-1 ${titleTypeClassName}`;
    case 'm-3-4': return `size-m-3-4 ${titleTypeClassName}`;
    default: return '';
  }
}

const AddBookletLink = ({ bookletUrl, linkToBooklet, children }) => bookletUrl && linkToBooklet
  ? <a href={bookletUrl} download target="_blank" rel="noreferrer">{children}</a>
  : children;

const InspirationPageImage = ({ imageUrl, imageType, text, title, isMainPage, link, isMobile, titleType, bookletUrl, linkToBooklet }) => {
  const titleSizeClassName = getTitleSizeClassName(imageType, titleType);
  const goToSlug = useInspirationNavigation();
  const onClick = useCallback(() => {
    link && !(bookletUrl && linkToBooklet) && goToSlug(link);
  }, [goToSlug, link, bookletUrl, linkToBooklet]);

  return (
    <ImageContainer imageType={imageType} isMobile={isMobile}>
      <AddBookletLink bookletUrl={bookletUrl} linkToBooklet={linkToBooklet}>
        <Image
          imageUrl={imageUrl}
          imageType={imageType}
          isMainPage={isMainPage}
          hasLink={!!link || (linkToBooklet && !!bookletUrl)}
          onClick={onClick}
        >
          {
            (titleType === 'Bildebånd' || titleType === 'Knapp')
              ? <ImageTitle className={titleSizeClassName} >
                {title}
                { imageType !== 's-1-1' && titleType !== 'Bildebånd' ? <ArrowRight /> : null }
              </ImageTitle>
              : null
          }
        </Image>
        <InspirationPageText
          className={titleSizeClassName}
          onClick={onClick}
          style={{ textAlign: 'center', marginTop: '.5em', textDecoration: 'underline', cursor: link ? 'pointer' : 'unset', fontSize: '14px' }}
          text={titleType === 'Tekst under' ? title : ''}
        />
        <InspirationPageText text={text} style={{ marginTop: '.5em' }}/>
      </AddBookletLink>
    </ImageContainer>
  )
};

export default InspirationPageImage;