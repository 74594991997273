import React, { useEffect, useRef, useState } from 'react';
import { styled, getStyle, responsive } from 'style';
import { useAuth, isEmployee } from '../../services/auth';
import { allierApi } from '../../services';
import { ProfileIcon } from '../../assets/icons';
import { FormField } from '../../components/form';
import { BikeLoader } from '../../components/loading';
import {
  AccordionWidget,
  Button,
  Checkbox,
  Label,
  WidgetContainer
} from '../../components/elements';
import { Dialog } from '../../components/dialog';
import { profileValidationSchema } from './validationSchema';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'components/loading';

let USER_PROFILE_FIELDS = []

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  input:disabled {
    color: #777;
  }
  
  span {
    display: inline-flex;
    gap: 1rem;
    &:nth-child(2) {
      justify-content: flex-end;
    }

    ${props => responsive('width', [
      {
        width: getStyle('screen.sm', props),
        value: props.hidden || props.wide
          ? '100%'
          : `calc(50% - ${getStyle('spacing.xxxl', props)})`
      }
    ])}
  }
`;

const DialogContent = styled.span`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem;
  align-items: center;
  
  button {
    width: 50%;
  }
`;

const EmailNotifLabel = styled(Label)`
  color: black;
`

const EmailNotifContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

const EmailNotifP = styled.p`
  margin-top: 5px;
  margin-right: 10px;
`


const getFormatedData = data => ({
  id: data.id,
  email: data.email || '',
  familyName: data.familyName || '',
  givenName: data.givenName || '',
  phone: data.phone || '',
  ...(data.address || {}),
  associationId: data.association.id,
  consentMarketing: data.consentMarketing || false,
  emailNotifications: data.emailNotifications
});

const isEqual = (a = {}, b = {}) => JSON.stringify(a) === JSON.stringify(b);

const validate = (schema, data) => {
  try {
    schema.validateSync(data, { sync: true, abortEarly: false });
    return {};

  } catch ({ inner: errorArray }) {
    const validationErrors = {};
    errorArray.forEach(({ message, path }) => {
      validationErrors[path] = message;
    });

    return validationErrors;
  }
};

const ProfilePage = () => {
  const { userId, user } = useAuth();
  const [userProfile, setUserProfile] = useState({});
  const [errors, setErrors] = useState({});
  const [popup, setPopup] = useState({});
  const [userIsAccountManager, setUserIsAccountManager] = useState(false);
  const originalUserProfile = useRef({});
  const [isSaving, setIsSaving] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    allierApi.getUserProfile(userId).then(res => {
      USER_PROFILE_FIELDS = [{ name: 'givenName', label: 'Fornavn' },
        { name: 'familyName', label: 'Etternavn' },
        { name: 'email', label: 'E-postadresse' },
        { name: 'phone', label: 'Mobilnummer',  type: 'tel' },
        { name: 'streetAddress', label: 'Adresse',},
        { name: 'zipCode', label: 'Postnummer',  type: 'number' },
        { name: 'city', label: 'Sted' },
        { name: 'dwellingNumber', label: 'Bolignummer' }]
      const isAccountManager = isEmployee(user);
      setUserIsAccountManager(isAccountManager);
      if (isAccountManager) {
        //This removes the dwelling number from the form, if the order of elements in the array changes, this should be changed as well.
        //Pop is much less complex than find or remove as long as the dwellig number is last in the array.
        USER_PROFILE_FIELDS.pop();
      }
      if(!isAccountManager) USER_PROFILE_FIELDS.push({ name: 'consentMarketing', label: 'Markedsføring', type: 'checkbox'});
      originalUserProfile.current = res;
      setUserProfile(getFormatedData(res));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId,userIsAccountManager]);

  const onChange = ({ target }) => {
    const { name, value = '' } = target;
    if (!name) return;
    setUserProfile(prevState => {
      const newProfileState = { ...prevState, [name]: value }
      setErrors(validate(profileValidationSchema, newProfileState));

      return newProfileState;
    });
  };

  const onReset = () => {
    setUserProfile(getFormatedData(originalUserProfile.current))
    setErrors({});
  };

  const onSave = () => {
    if (Object.keys(validate(profileValidationSchema, userProfile)).length) return;
    setIsSaving(true);
    const profileData = {
      id: userProfile.id,
      email: userProfile.email,
      familyName: userProfile.familyName,
      givenName: userProfile.givenName,
      phone: userProfile.phone,
      consentMarketing: userProfile.consentMarketing,
      address: {
        city: userProfile.city,
        dwellingNumber: userProfile.dwellingNumber,
        streetAddress: userProfile.streetAddress,
        zipCode: userProfile.zipCode,
      },
      association: { id: userProfile.associationId },
      emailNotifications: userProfile.emailNotifications
    };

    allierApi.updateUserProfile(profileData).then(res => {
      originalUserProfile.current = res;
      setUserProfile(getFormatedData(res));
    }).catch(() => setErrors({ ...errors, email: 'Epostadressen er allerede i bruk' }));
    setIsSaving(false);
  };

  const onChangePassword = () => {
    allierApi.resetPassword(userProfile.email)
      .then(({ message = '', title = '' }) => {
        setPopup({ message, title });
      })
  }

  const onClosePopup = () => {
    setPopup({});
  }

  if (!userProfile.id) return <BikeLoader />;
  const isChanged = isEqual(getFormatedData(originalUserProfile.current), userProfile);

  const hasErrors = Object.keys(errors).some(key => errors[key]);

  return (
    <WidgetContainer>
      <Dialog isOpen={!!popup.title} title={popup.title || ''} onClose={onClosePopup}>
        <DialogContent>
          { popup.message || ''}
          <Button variant="secondary" onClick={onClosePopup}>OK!</Button>
        </DialogContent>
      </Dialog>
      <AccordionWidget title="Min profil" icon={<ProfileIcon />} wide>
        <Container>
          {
            USER_PROFILE_FIELDS.map(({ name, label, type = 'text', disabled  = false }) => (type === 'checkbox' && !userIsAccountManager)
              ? <div key={name}>
                  <Label style={{color:'black'}}>Markedsføring</Label>
                    <div style={{display:'flex', marginTop: '10px'}}>
                      <div style={{marginTop: '5px', marginRight: '10px'}}>Ønsker å motta direkte markedsføring</div>
                      <Checkbox
                        children={""}
                        checked={userProfile[name]}
                        onChange={(e) => onChange({target:{name:'consentMarketing',value:e}})}
                      />
                    </div>
                </div>
              :
            <FormField
              key={name}
              label={label}
              name={name}
              type={type}
              onChange={onChange}
              value={userProfile[name] || ''}
              disabled={disabled}
              error={errors[name] || ''}
            />
            )
          }
          <div key={'emailNotifications'}>
                  <EmailNotifLabel>Epostvarsler</EmailNotifLabel>
                    <EmailNotifContainer>
                      <EmailNotifP>Ønsker å motta epostvarsler</EmailNotifP>
                      <Checkbox
                        children={""}
                        checked={userProfile['emailNotifications']}
                        onChange={(e) => onChange({target:{name:'emailNotifications',value:e}})}
                      />
                    </EmailNotifContainer>
                </div>
        </Container>
        <Container>
          <span>
            <Button variant="secondary" onClick={onChangePassword}>Bytt passord?</Button>
          </span>
          <span>
            { isSaving && <span><Spinner style={{marginTop: '7px',height: '30px', width: '30px'}} /></span>}
            <Button variant="secondary" onClick={()=> navigate("/dashboard")}>Avbryt</Button>
            <Button variant="primary" disabled={isChanged} onClick={onReset}>Nullstill</Button>
            <Button disabled={isChanged || hasErrors} onClick={onSave}>Lagre mine opplysninger</Button>
          </span>
        </Container>
      </AccordionWidget>
    </WidgetContainer>
  )
};

export default ProfilePage;
