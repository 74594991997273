import PropTypes from 'prop-types';
import { styled, getStyle, variant } from 'style';

const background = variant('variant', {
  form: getStyle('color.input.form')
});

const backgroundFocused = variant('variant', {
  form: getStyle('color.input.focused.form')
});

// NB! Font size less than 16 px will result in auto zoom on iPhone.
const Input = styled.input`
  font-size: ${getStyle('font.lg')};
  background: ${background};
  border: ${props => (props.error
    ? `0.0625rem solid ${getStyle('color.status.error', props)};`
    : `0.0625rem solid ${getStyle('color.border.primary', props)};`
  )};
  border-radius: ${getStyle('border.radius.sm')};
  padding: 0 ${getStyle('spacing.sm')};
  height: ${getStyle('size.input.height')};
  width: 100%;
  outline: none;

  :focus {
    background: ${backgroundFocused};
  }
  :disabled {
    color: ${getStyle('color.status.disabled')};
  }

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

Input.propTypes = {
  variant: PropTypes.oneOf(['form'])
};

export default Input;
