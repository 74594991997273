import {
  httpGet, httpPut, jsonOrThrow, throwOnError
} from 'services/common';
import config from 'config';
import {httpPatch} from "../common/http";

const mapUserProfile = profile => ({
  ...profile,
  displayName: () => (
    profile.givenName && profile.familyName
      ? `${profile.givenName} ${profile.familyName}`
      : profile.email
  ),
  association: {
    ...profile.association,
    sanityId: profile.association.sanityId
  }
});


const getUrl = userId => (
  `${config.apiHost}/api/users/${userId}`
);

const resetPassword = (email) =>
  fetch(`https://${config.authDomain}/dbconnections/change_password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      client_id: config.authClientId,
      email,
      connection: 'Username-Password-Authentication'
    })
  })
    .then(throwOnError)
    .then(() => ({
      title: 'Endre passord',
      message: `Sjekk e-postadressen ${email} for instruksjoner om hvordan du endrer ditt passord.`,
      variant: 'success',
    }))
    .catch(() => ({
      title: 'Feil',
      message: 'En uventet feil oppstod ved forespørsel om å endre passord.',
      variant: 'error',
    }));

const user = {
  getUserProfile: userId => (
    httpGet(getUrl(userId))
      .then(jsonOrThrow)
      .then(res => mapUserProfile(res))
  ),

  updateUserProfile: profile => (
    httpPut(getUrl(profile.id), profile)
      .then(jsonOrThrow)
      .then(res => mapUserProfile(res?.profile || {}))
  ),

  updateUserConsent: (userId, consentGdpr, consentMarketing) => (
    httpPatch(`${config.apiHost}/api/users/consent/${userId}?consentGdpr=${consentGdpr}&consentMarketing=${consentMarketing}`)
        .then(throwOnError)
  ),

  resetPassword,
};

export default user;
