import React, { useEffect, useState } from 'react';
import { styled } from 'style';
import { useErrorHandler } from 'react-error-boundary'
import PropTypes from 'prop-types';
import { allierApi } from 'services';
import { Button } from 'components/elements'
import { Dialog } from 'components/dialog';

export const TagField = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagButton = styled.button`
  background-color: ${props => props.isDocumentTag ? '#686868' : '#C5C5C5'};
  border-radius: 18px;
  border: 6px solid;
  border-color: ${props => props.isDocumentTag ? '#919191' : '#C5C5C5'};
  color: ${props => props.isDocumentTag ? '#FFFFFF' : '#686868'};
  padding: 5px;
  margin: 5px;
  text-align: center;
  min-width: 75px;
`;

const TagsDialog = ({
  blockOpen, blockText, isDialogOpen, onCancel, onConfirm, chosenTags, removeTags, handleDeleteTags, deleteTagsList
}) => {
  const [tags, setTags] = useState([]);
  const [oldTags, setOldTags] = useState([]);
  const [updateTags, setUpdateTags] = useState(false);
  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchTags = async () => {
      const res = await allierApi.getTags();
      const formattedTags = res.tags.map(tag => ({ id: tag.id, name: tag.name, documentTag: false }));
      setTags(formattedTags);
      setOldTags(JSON.parse(JSON.stringify(formattedTags)));
    };

    fetchTags().catch(handleError);
  }, [handleError]);

  useEffect(() => {
    setOldTags(JSON.parse(JSON.stringify(tags)));
    if (chosenTags) {
      for (let i = 0; i < tags.length; i++) {
        tags[i].documentTag = chosenTags.some(ct => ct.id === tags[i].id);
      }

      setTags(tags);
    }
  }, [chosenTags, tags]);

  const cancelTagDialog = () => {
    setTags(JSON.parse(JSON.stringify(oldTags)));
    onCancel(oldTags);
    handleDeleteTags && handleDeleteTags(null, true);
  }

  const confirmTags = () => {
    setOldTags(JSON.parse(JSON.stringify(tags)));
    onConfirm(tags);
  }

  const handleTagClick = (tag) => {
    if (handleDeleteTags) {
      handleDeleteTags(tag);
    }
    
    tag.documentTag = !tag.documentTag;
    //Hacky way to force refresh of state...
    setUpdateTags(!updateTags);
  }

  return (
    <Dialog
      title={removeTags ? "Fjerne tags" : "Legg til tags"}
      isOpen={isDialogOpen}
      onClose={cancelTagDialog}
      wide
    >
      <div>
        {blockOpen
          ? <div>{blockText}</div>
          : <>
            <TagField>
              {tags && deleteTagsList && deleteTagsList.map(tag => <TagButton key={tag?.id} isDocumentTag={tag.documentTag} onClick={() => handleTagClick(tag)}>{tag?.name} </TagButton>)}
              {tags && !deleteTagsList && tags.map(tag => <TagButton key={tag?.id} isDocumentTag={tag.documentTag} onClick={() => handleTagClick(tag)}>{tag?.name} </TagButton>)}
            </TagField>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
              <Button style={{ marginRight: '25px', width: '75%' }} onClick={() => confirmTags()}>{removeTags ? "Fjerne valgte tags" : "Sett tags"}</Button>
              <Button style={{ width: '25%' }} variant="secondary" onClick={() => cancelTagDialog()}>Avbryt</Button>
            </div>
          </>
        }
      </div>
    </Dialog>
  )
};

TagsDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  blockOpen: PropTypes.bool,
  blockText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  tags: PropTypes.array,
  chosenTags: PropTypes.array,
  removeTags: PropTypes.bool,
  handleDeleteTags: PropTypes.func,
};

export default TagsDialog;