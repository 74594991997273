import {
  Home,
  CustomerDashboard,
  Blog,
  Category,
  Allier,
  Unik,
  Reference,
  Messages,
  Documents,
  Project,
  Profile,
  Customer,
  AccountManagerProjectPage,
  InspirationPage,
  ContactInfo,
  Mediabank,
} from 'pages';
import { default as AccountManager } from 'pages/accountManager/AccountManager';
import { useAuth, roles, hasRole, isEmployee } from 'services/auth';
import { Page } from 'components/layout';
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { BikeLoader } from 'components/loading';
import DocumentsPage from "./pages/documents/DocumentsPage";
import { useEffect, useState } from "react";
import messageHub from 'services/allier/messageHub';
import ComplaintsPage from './pages/complaints/ComplaintsPage';
import ImportCustomersPage from './pages/import/ImportCustomersPage';
import AdminPage from 'pages/admin/AdminPage';
import MediabankPage from 'pages/mediabank/MediabankPage';

const R = require('ramda');

const ProtectedRoutes = (role) => {
  const { isAuthenticated, user } = useAuth();
  if (!R.isEmpty(role)) {
    return isAuthenticated && hasRole(user, role.role) ? <Outlet /> : <Navigate to="/" />;
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

const RouteManager = () => {
  const { isLoading, user } = useAuth();

  const [isNewNotification, setIsNewNotification] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if(user.email) messageHub.connect(setIsNewNotification);
    }
  }, [isLoading, user.email])

  //  Give auth0 some time to figure out authentication stuff
  if (isLoading) {
    return (<BikeLoader />)
  }

  const isUser = !isEmployee(user);

  return (
    <Routes>

      {/*  PUBLIC ROUTES */}

      <Route path="/" index element={<Home />} />
      <Route path="/articles">
        <Route path=":slug" element={<Reference />} />
        <Route path="allier" element={<Allier />} />
        <Route path="unik" element={<Unik />} />
      </Route>

      <Route element={<ProtectedRoutes role={roles.admin}/>}>
          <Route path='/admin' element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><AdminPage/></Page>}></Route>
      </Route>


      {/*  ACCOUNT MANAGER ROUTES */}
      <Route element={<ProtectedRoutes role={roles.accountManager} />}>
        <Route path="/accountmanager/borettslag">
          <Route path=":slug" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><AccountManager /></Page>} />
          <Route index element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><AccountManager /></Page>} />
        </Route>
        <Route path="/accountmanager/dashboard" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><AccountManager /></Page>} />
        <Route path="/accountmanager/customer/:customerId" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Customer /></Page>} />
        <Route path="/accountmanager/project/:projectId/:customerId" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><AccountManagerProjectPage /></Page>} />
        <Route path="/accountmanager/messages/:customerId?" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Messages userIsAccountManager={!isUser} /></Page>} />
        <Route path="/accountmanager/documents" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><DocumentsPage userIsAccountManager={!isUser} /></Page>} />
        <Route path="/mediabank" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><MediabankPage userIsAccountManager={!isUser} /></Page>} />
        <Route path="/accountmanager/projects" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><AccountManager /></Page>} />
        <Route path="/accountmanager/complaints" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><ComplaintsPage userIsAccountManager={!isUser} /></Page>} />
        <Route path="/accountmanager/import/:associationId" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><ImportCustomersPage userIsAccountManager={!isUser} /></Page>} />
        <Route path="/accountmanager/mediabank" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><MediabankPage userIsAccountManager={!isUser} /></Page>} />
        
      </Route>

      {/*  USER MANAGER ROUTES*/}

      <Route element={<ProtectedRoutes />}>
        <Route path="/borettslag">
          <Route index element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Blog /></Page>} />
          <Route path=":slug" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Category /></Page>} />
        </Route>
        <Route path="/project/:projectId" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Project /></Page>} />
        <Route path="/dashboard" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><CustomerDashboard /></Page>} />
        <Route path="/messages" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Messages /></Page>} />
        <Route path="/documents" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Documents /></Page>} />
        <Route path="/mediabank" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><MediabankPage userIsAccountManager={!isUser} /></Page>} />
        <Route path="/profile" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><Profile /></Page>} />
        <Route path="/contactinfo" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><ContactInfo /></Page>} />
        <Route path="/complaints" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><ComplaintsPage userIsAccountManager={!isUser} /></Page>} />
        <Route path="/inspiration">
          <Route index element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><InspirationPage /></Page>} />
          <Route path=":slug" element={<Page isNewNotification={isNewNotification} setIsNewNotification={setIsNewNotification}><InspirationPage /></Page>} />
        </Route>
      </Route>
    </Routes>);
}

export default RouteManager;
