import config from '../../config.js';

export const roles = {
  accountManager: 'Kundeansvarlig',
  admin: 'Admin'
};

export const hasRole = ( user, role) => {
  const userRoles = user[config.authRolesKey] || [];
  return userRoles.includes(role);
};

export const isEmployee = user => (
  hasRole(user, roles.accountManager)
);

export const isAdmin = user => (
  hasRole(user, roles.admin)
);
