import { styled, getStyle } from 'style';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${getStyle('border.radius.sm')};

  * {
    overflow: hidden;
    text-overflow: ellipsis;

    :not(:last-child) {
      margin-bottom: ${getStyle('spacing.xs')};
    }
  }
`;

export default InfoContainer;
