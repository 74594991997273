import React from 'react';
import { H2 } from 'components/elements';
import { styled } from 'style';
import { sanity } from 'services/sanity';
import { BlockContent } from 'components/elements';

const TextContainer = styled.div`
  background-color: ${({ backgroundColor }) => (backgroundColor || 'white')};
  padding: .5em 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .2em;

  h2 {
    font-size: 22px;
    margin-bottom: .5em;
  }

  ul {
    margin: 1rem;
    padding: 1rem;
    list-style: inherit;
  }
`;

const InspirationPageText = ({ text, title, showTitle, ...props }) =>  {
  return text || title
  ? (
    <TextContainer {...props}>
      { showTitle && title ? <H2>{title}</H2> : null }
      { Array.isArray(text) ? <BlockContent
        blocks={text}
        projectId={sanity.clientConfig.projectId}
        dataset={sanity.clientConfig.dataset}
      /> : null }
      { !Array.isArray(text) && text ? text.split('\n').map(e => <p key={e}>{e}</p>) : null }
    </TextContainer>
  )
  : null;
}

export default InspirationPageText;