import PropTypes from 'prop-types';
import { sanity } from 'services/sanity';
import { BlockContent } from 'components/elements';
import { styled, getStyle, responsive } from 'style';
import { StarIcon } from 'assets/icons';

const Content = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  width: 100%;
  ${props => responsive('padding', [
    {
      width: getStyle('screen.sm', props),
      value: `${getStyle('spacing.md', props)} ${getStyle('spacing.xl', props)}`
    }
  ])}
`;

const Stars = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 14px;
  }
  margin-bottom: ${getStyle('spacing.md')}
`;
const Quotee = styled.div`
  font-weight: bold;
  margin-top: ${getStyle('spacing.md')}
`;

const Quote = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin: 10px;
  padding: ${getStyle('spacing.md')};
  ${props => responsive('padding', [
    {
      width: getStyle('screen.sm', props),
      value: `${getStyle('spacing.md', props)} ${getStyle('spacing.xl', props)}`
    }
  ])}
  background: ${getStyle('color.background.light')}
`;

const QuotePost = ({ blocks, quotee }) => (
  <Quote>
    <Content>
      <Stars>
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </Stars>
      <BlockContent
        blocks={blocks}
        projectId={sanity.clientConfig.projectId}
        dataset={sanity.clientConfig.dataset}
      />
      <Quotee>{quotee}</Quotee>
    </Content>
  </Quote>
);

QuotePost.propTypes = {
  blocks: PropTypes.array.isRequired,
  quotee: PropTypes.string
};

export default QuotePost;
