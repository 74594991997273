import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  styled, getStyle, responsive, useMediaQuery
} from 'style';
import config from '../../../config.js';
import { Button } from 'components/elements';
import { AllierLogo } from 'assets/logos';
import { OpenMenuIcon, CloseMenuIcon } from 'assets/icons';
import { useAuth, isEmployee } from 'services/auth';
import { allierApi } from 'services';

import { renderNotificationOverlay } from './Overlay';

import ProfileButton from './ProfileButton';
import NotificationButton from './NotificationButton';

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${getStyle('color.header.foreground')};
  background: ${getStyle('color.header.background')};
  border-bottom: 0.0625rem solid ${getStyle('color.border.theme')};

  height: ${getStyle('size.header.height')};

  padding-left: ${getStyle('spacing.lg')};
  padding-right: ${getStyle('spacing.lg')};

  ${props => responsive('padding-left', [
  { width: getStyle('screen.md', props), value: getStyle('spacing.xxxl', props) }
])}
  ${props => responsive('padding-right', [
  { width: getStyle('screen.sm', props), value: getStyle('spacing.xxxl', props) }
])}
`;

const StyledLogo = styled(AllierLogo)`
  fill: ${getStyle('color.header.foreground')};
  height: 1.2rem;
`;

const TestEnv = styled.span`
  vertical-align: top;
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 6px;
  padding: 8px 10px;
  background: #6750A5;
  color: #fff;
`

const ActionsContainer = styled.div`
  display: flex;
  height: 100%;

  > button {
    margin: auto 0 auto ${getStyle('spacing.xs')};

    color: ${getStyle('color.header.foreground')};
    stroke: ${getStyle('color.header.foreground')};
    fill: ${getStyle('color.header.foreground')};

    :hover {
      color: ${getStyle('color.header.foreground')};
      stroke: ${getStyle('color.header.foreground')};
      fill: ${getStyle('color.header.foreground')};
    }
  }
`;

const Navbar = ({ isMenuVisible, showMenu, overlayVisibilityChanged, isNewNotification, setIsNewNotification }) => {
  const { userId, user, isAuthenticated, login } = useAuth();
  const [isProfileOverlayVisible, showProfileOverlay] = useState(false);
  const [isNotificationOverlayVisible, showNotificationOverlay] = useState(false);
  const [profile, setProfile] = useState();
  const [notifications, setNotifications] = useState();
  const isMobile = useMediaQuery().isMobile();
  const [isUser,setIsUser] = useState(true);

  const refreshNotifications = useCallback(() => {
    allierApi.getNotifications({ limit: 10 }).then(res => {
      setNotifications(res.notifications);
      setIsNewNotification(false);
    });
  }, [setIsNewNotification]);

  const checkUserRole = useCallback(() => {
    setIsUser(!isEmployee(user));
  },[user]);

  useEffect(() => {
    if (isAuthenticated) {
      allierApi.getUserProfile(userId).then(res => {
        setProfile(res);
      });
      refreshNotifications();
      checkUserRole();
    }
  }, [userId, isAuthenticated, refreshNotifications, checkUserRole])

  useEffect(() => {
    if (isNewNotification) {
      refreshNotifications();
    }
  }, [isNewNotification, refreshNotifications])

  return (
    <Container>
      <p>
        <StyledLogo />
        {config.isTest ? <TestEnv>Test!</TestEnv> : ''}
      </p>
      <ActionsContainer>
        {
          isAuthenticated && profile ? <>
            <NotificationButton
              notificationData={notifications}
              isOverlayVisible={isNotificationOverlayVisible}
              showOverlay={show => {
                showMenu(false);
                showProfileOverlay(false);
                showNotificationOverlay(show);
                overlayVisibilityChanged(show);
              }}
              renderOverlay={renderNotificationOverlay}
              setNotificationRead={allierApi.setNotificationStatus}
              refreshNotifications={refreshNotifications}
              isUser={isUser}
            />
            <ProfileButton
              userProfile={profile}
              isOverlayVisible={isProfileOverlayVisible}
              showOverlay={show => {
                showProfileOverlay(show);
                showNotificationOverlay(false);
                showProfileOverlay(show);
                overlayVisibilityChanged(show);
              }} />
          </>
            :
            <button onClick={() => login()}>
              Logg inn
            </button>
        }
        {isMobile && (
          <Button
            variant="inline"
            aria-label={isMenuVisible ? 'Lukk meny' : 'Åpne meny'}
            onClick={() => {
              showNotificationOverlay(false);
              showMenu(!isMenuVisible);
              overlayVisibilityChanged(!isMenuVisible);
            }}
          >
            {isMenuVisible ? <CloseMenuIcon /> : <OpenMenuIcon />}
          </Button>
        )}
      </ActionsContainer>
    </Container>
  );
}
Navbar.propTypes = {
  isMenuVisible: PropTypes.bool.isRequired,
  showMenu: PropTypes.func.isRequired,
  overlayVisibilityChanged: PropTypes.func,
  isNewNotification: PropTypes.bool,
  setIsNewNotification: PropTypes.func
};

Navbar.defaultProps = {
  isMenuVisible: false,
  showMenu: () => { },
  isNewNotification: false,
  setIsNewNotification:  () => { }
}

export default Navbar;
