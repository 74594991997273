import PropTypes from 'prop-types';
import { Button, Text } from 'components/elements';
import { useNavigate } from "react-router-dom";
import { styled, getStyle, responsive } from 'style';
import { BathtubIcon } from 'assets/icons';
import { ProgressPost, ContainerWidget, ReadMorePost } from 'pages/blog/components';
import {
  ImageContainer, Grid, Empty, StyledAccordian
} from './Style';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  ${props => responsive('padding', [
    {
      width: getStyle('screen.sm', props),
      value: `${getStyle('spacing.md', props)} ${getStyle('spacing.xl', props)}`
    }
  ])}
`;

const StyledText = styled.div`
  padding: ${getStyle('spacing.xl')};
  flex: 1;
  font-weight: ${props => (props.bold ? '600' : 'inherit')};
`;

const DefaultCategory = ({ category, associationId }) => {
  let navigate = useNavigate();
  return (
  <StyledAccordian radius title={category.title} icon={<BathtubIcon />} wide={(category.image && category.render === 'standard') || category.wide}>
    <Content>
      { (category.image && category.render === 'inside') ? <ImageContainer size="contain" miHeight="15rem" render={category.render} url={category.image ? category.image.asset.url : ''} />
        : (
          <Empty />
        )}
      <StyledText>
        <Text variant="md">
          {category.descriptionSmall}
        </Text>
      </StyledText>
      {category.slug && (
        <ButtonContainer>
          <Button role="link" onClick={() => navigate(category.slug.current + "?association_id=" + associationId)}>
            { category.buttonText ? category.buttonText : 'Gå til' }
          </Button>
        </ButtonContainer>
      )}
    </Content>
  </StyledAccordian>
)};

DefaultCategory.propTypes = {
  category: PropTypes.object.isRequired,
  associationId: PropTypes.string
};

const Category = ({ category, associationId }) => {
  if (category.render === 'progress') {
    return (
      <Grid>
        <ContainerWidget columns="100%" height="100%" justifyItems="center">
          {category.posts.map(post => <ProgressPost key={`post_${post._id}`} post={post} />)}
        </ContainerWidget>
        <ContainerWidget title={category.title} columns="100%">
          <Text variant="md">
            {category.descriptionSmall}
          </Text>
        </ContainerWidget>
      </Grid>
    );
  }
  if (category.image && category.render === 'standard' && category.children.length === 0) {
    return (
      <Grid>
        <DefaultCategory category={category} associationId={associationId}/>
        { (category.image && category.render === 'standard') && <ImageContainer url={category.image ? category.image.asset.url : ''} /> }
      </Grid>
    );
  }
  if (category.children.length > 0) {
    return (
      <ContainerWidget radius title={category.title} icon={<BathtubIcon />} wide>
        {category.children.map(child => (
          <ReadMorePost
            key={child.title}
            title={child.title}
            descriptionText={child.descriptionSmall}
            buttonText={child.buttonText}
            slug={child.slug.current + "?association_id=" + associationId}
          />
        ))}
      </ContainerWidget>
    );
  }
  return <DefaultCategory category={category} associationId={associationId} radius />;
};

Category.propTypes = {
  category: PropTypes.object.isRequired,
  wide: PropTypes.bool,
  associationId: PropTypes.string
};

export default Category;
