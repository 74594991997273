import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, UploadSingleButton } from 'components/elements';
import { styled } from 'style';

const ButtonContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: ${({ hasExcel }) => hasExcel ? 'flex-end' : 'center'};
  margin: ${({ hasExcel }) => hasExcel ? '0 0 1rem 0' : '4rem 0'};
  gap: 1rem;
`;

const ButtonWithTooltip = styled(Button)`

  :hover::after {
    ${({ tooltip }) => !tooltip ? 'display: none;' : '' }
    position: absolute;
    margin: 4rem -.5rem -.5rem -.5rem;
    padding: .5rem;
    border: 1px #666666 solid;
    border-bottom: none;
    border-right: none;
    background-color: white;
    transform: rotate(45deg);
    content: '';
    z-index: 1;
  }

  :hover::before {
    ${({ tooltip }) => !tooltip ? 'display: none;' : '' }
    position: absolute;
    margin: 6.5rem -.5rem -.5rem -.5rem;
    padding: .5rem;
    border: 1px #666666 solid;
    border-radius: 5px;
    width: fit-content;
    font-weight: lighter;

    background-color: white;
    color: #ff5900;
    content: '${({ tooltip }) => tooltip}';
    pointer-events: none;
    z-index: 1;
  }
`;

const ImportButtons = ({ hasExcel, hasError, importTemplateUrl, onFileChange, onReset, onShowConfirmation, showResult }) => {
  const navigate = useNavigate();

  return (
    <ButtonContainer hasExcel={hasExcel}>
      <Button variant="secondary" onClick={() => navigate('/accountmanager/dashboard')}>Avbryt</Button>
      {
        hasExcel
          ? (
            <>
              <Button variant="secondary" onClick={onReset}>Reset</Button>
              {
                !showResult
                  ? <ButtonWithTooltip
                    disabled={hasError}
                    variant="primary"
                    onClick={onShowConfirmation}
                    tooltip={hasError ? 'Det foreligger feil i data som er lastet opp.' : ''}
                  >
                    Importer
                  </ButtonWithTooltip>
                  : null
              }
            </>
          ): (
            <>
              <Button variant="secondary"><a href={importTemplateUrl} download> Last ned mal</a></Button>
              <UploadSingleButton
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onFileChange={onFileChange}
              >
                Last opp fil
              </UploadSingleButton>
            </>
          )
      }
    </ButtonContainer>
  )
};

ImportButtons.defaultProps = {
  hasExcel: false,
  hasError: false,
  showResult: false,
  importTemplateUrl: '',
};

ImportButtons.propTypes = {
  hasExcel: PropTypes.bool,
  hasError: PropTypes.bool,
  showResult: PropTypes.bool,
  importTemplateUrl: PropTypes.string,
  onFileChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onShowConfirmation: PropTypes.func.isRequired,
};

export default ImportButtons;
