import { styled, getStyle, responsive } from 'style';

const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${props => responsive('width', [
    {
      width: getStyle('screen.sm', props),
      value: props.hidden || props.wide
        ? '100%'
        : `calc(50% - ${getStyle('spacing.xxxl', props)})`
    }
  ])}

  > * {
    width: ${props => `calc(50% - ${getStyle('spacing.xs', props)})`};
  }
`;

export default FieldGroup;
