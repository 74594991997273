import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { useField } from 'formik';
import { Input, Label, Text } from 'components/elements';
import { styled, getStyle } from 'style';
import FieldContainer from './FieldContainer';
import FieldError from './FieldError';

const AutosuggestFieldContainer = styled(FieldContainer)`
  .react-autosuggest__container {
    position: relative;
  }
  .react-autosuggest__input {
    width: 100%;
  }
  .react-autosuggest__suggestions-container--open {
    position: absolute;
    border: 0.0625rem solid ${getStyle('color.border.secondary')};
    border-top: none;
    border-bottom-left-radius: ${getStyle('border.radius.sm')};
    border-bottom-right-radius: ${getStyle('border.radius.sm')};
    background-color: ${getStyle('color.background.primary')};
  }
  .react-autosuggest__suggestion {
    padding: ${getStyle('spacing.sm')};
    cursor: pointer;
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${getStyle('color.input.focused.form')};
  }
`;

const AutosuggestFormField = ({
  name,
  label,
  inputProps,
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  getSuggestionValue,
  ...props
}) => {
  const [field, meta] = useField({ name });
  const touchedAndError = meta.error && meta.touched;
  return (
    <AutosuggestFieldContainer>
      <Label htmlFor={name}>{label}</Label>
      <Autosuggest
        inputProps={inputProps}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        focusInputOnSuggestionClick={false}
        renderInputComponent={inputElementProps => {
          const onBlur = event => {
            inputElementProps.onBlur(event);
            field.onBlur(event);
            field.onChange(event);
          };
          return (
            <Input
              variant="form"
              id={field.name}
              name={field.name}
              error={touchedAndError}
              {...inputElementProps}
              onBlur={onBlur}
            />
          );
        }}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <div aria-label="Forslag" {...containerProps}>
            {children}
          </div>
        )}
        renderSuggestion={suggestion => (
          <Text>{suggestion.name}</Text>
        )}
        onSuggestionSelected={(event, { method }) => {
          if (method === 'enter') {
            event.preventDefault();
          }
        }}
      />
      <FieldError hidden={!touchedAndError}>{meta.error}</FieldError>
    </AutosuggestFieldContainer>
  );
};

AutosuggestFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputProps: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired
};

export default AutosuggestFormField;
