import PropTypes from "prop-types";
import { InfoContainer } from "components/elements";
import { allierApi } from "services";
import { Image, Video, Button } from "components/elements";
import { styled, getStyle, responsive } from "style";

const Container = styled.div`
  width: ${(props) => (props.wide ? "100%" : "30%")};

  :not(:last-child) {
    margin-right: ${(props) => (props.wide ? "0" : "5%")};
  }

  > img,
  video {
    border-radius: ${getStyle("border.radius.sm")};
    margin-bottom: ${getStyle("spacing.xs")};
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};
    width: 100%;
  }

  > div {
    display: ${(props) => (props.wide ? "block" : "none")};

    ${(props) =>
      responsive("display", [
        { width: getStyle("screen.sm", props), value: "block" },
      ])}

    > span {
      display: -webkit-box;
      margin-bottom: 3px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${(props) => (props.wide ? "" : "3")};
    }
  }
`;

const ProjAttachH3 = styled.h3`
  font-size: ${getStyle("font.heading.md")};
  font-weight: bold;
  margin-bottom: 10px;
`;

const ProjectAttachment = ({
  accountManagerPage,
  title,
  attachment,
  onClick,
  onProjectEdit,
  wide,
  ...props
}) => (
  <Container clickable={!!onClick} wide={wide} {...props}>
    {title && <ProjAttachH3>{title}</ProjAttachH3>}
    {attachment.file.type.includes("image") ? (
      <Image
        alt={attachment.file.name}
        download={() => allierApi.getFileSas(attachment.fileId)}
        onClick={onClick}
      />
    ) : (
      <Video
        alt={attachment.file.name}
        type={attachment.file.type}
        download={() => allierApi.getFileSas(attachment.fileId)}
        onClick={() => {}}
      />
    )}
    <InfoContainer>
      <span>{attachment.description}</span>
    </InfoContainer>
    <>
      {accountManagerPage && onProjectEdit ? (
        <Button onClick={() => onProjectEdit(attachment)}>Rediger</Button>
      ) : (
        <></>
      )}
    </>
  </Container>
);

ProjectAttachment.propTypes = {
  accountManagerPage: PropTypes.bool,
  title: PropTypes.string,
  attachment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onProjectEdit: PropTypes.func,
  wide: PropTypes.bool,
};

export default ProjectAttachment;
