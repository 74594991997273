/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { sanity } from 'services/sanity';
import { styled, getStyle, useMediaQuery } from 'style';
import { Dialog, useDialog } from 'components/dialog';
import { Button, BlockContent } from 'components/elements';

const PhaseStepperProgressWidth = '1.5rem';
const PhaseStepperDotColor = '#AEA0CD';
const PhaseStepperDotSize = '0.75rem';
const PhaseStepperRowHeight = '2rem';

const ContainerBase = styled.div`
  display: flex;

  .progress {
    display: flex;

    background: ${getStyle('color.slider.background')};
    border-radius: ${getStyle('border.radius.lg')};

    > div {
      position: relative;
      flex-grow: 1;

      > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: ${PhaseStepperDotColor};
        border-radius: ${getStyle('border.radius.lg')};
        width: ${PhaseStepperDotSize};
        height: ${PhaseStepperDotSize};
      }

      &.completed {
        background: ${getStyle('color.slider.selected.foreground')};

        > div {
          display: none;
        }
      }

      &.current {
        background: ${getStyle('color.slider.selected.foreground')};

        > div {
          background: ${getStyle('color.slider.foregroundDot')};
          width: ${PhaseStepperDotColor};
          height: ${PhaseStepperDotColor};
        }
      }
    }
  }
`;

const ContainerMobile = styled(ContainerBase)`
  .progress {
    flex-direction: column;

    margin-left: ${getStyle('spacing.xs')};
    width: ${PhaseStepperProgressWidth};

    > div {
      flex-basis: ${PhaseStepperRowHeight};

      &:first-child {
        border-top-left-radius: ${getStyle('border.radius.lg')};
        border-top-right-radius: ${getStyle('border.radius.lg')};
      }
      &.current {
        border-bottom-left-radius: ${getStyle('border.radius.lg')};
        border-bottom-right-radius: ${getStyle('border.radius.lg')};
      }
    }
  }

  .phases {
    display: flex;
    flex-direction: column;
    line-height: ${PhaseStepperRowHeight};
    margin: 0 ${getStyle('spacing.xxl')};
    justify-content: space-between;
    word-wrap: break-word;
    text-align: left;
    align-items: flex-start;
  }
`;

const Container = styled(ContainerBase)`
  flex-direction: column;
  justify-content: space-between;

  .progress {
    height: ${PhaseStepperProgressWidth};
    margin-bottom: ${getStyle('spacing.md')};

    > div {
      &:first-child {
        border-top-left-radius: ${getStyle('border.radius.lg')};
        border-bottom-left-radius: ${getStyle('border.radius.lg')};
      }
      &.current::after {
        background: ${getStyle('color.slider.current')};
        width: 0.3rem;
        height: ${PhaseStepperProgressWidth};
        position: absolute;
        right: 0;
        top: 0;
        content: " ";
      }
    }
  }

  .phases {
    display: flex;
    font-size: ${getStyle('font.sm')};
    text-align: center;

    > button, span {
      flex-grow: 1;
      flex-basis: 0;
      margin: 0 ${getStyle('spacing.xs')};
    }
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: ${getStyle('spacing.md')};
  width: 100%;

  > button {
    width: 100%;
  }
`;

const PhaseStepper = ({ phases, currentPhaseId, forceMobile }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const isMobile = useMediaQuery().isMobile();
  const PhaseContainer = isMobile || forceMobile ? ContainerMobile : Container;
  const currentPhaseIdx = phases.findIndex(p => p.id === currentPhaseId);

  const [dialogState, setDialogState] = useState({ title: '', content: '', blocks: [] });
  const openPhaseDialog = phase => {
    setDialogState({
      title: phase.title,
      blocks: phase.blocks
    });
    openDialog();
  };
  return (
    <PhaseContainer>
      <Dialog
        title={dialogState.title}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        wide
      >
        <BlockContent
          blocks={dialogState.blocks}
          projectId={sanity.clientConfig.projectId}
          dataset={sanity.clientConfig.dataset}
        />
        <ButtonContainer>
          <Button variant="secondary" onClick={() => closeDialog()}>Greit, jeg skjønner</Button>
        </ButtonContainer>
      </Dialog>
      <div className="progress">
        {phases.map((phase, idx) => {
          const completed = idx < currentPhaseIdx ? 'completed' : '';
          const current = idx === currentPhaseIdx ? 'current' : '';
          return (
            <div className={completed || current} key={phase.id}><div /></div>
          );
        })}
      </div>
      <div className="phases">
        {phases.map(phase => (
          <Button variant="link" onClick={() => openPhaseDialog(phase)} key={phase.id}>{phase.title}</Button>
        ))}
      </div>
    </PhaseContainer>
  );
};

PhaseStepper.propTypes = {
  phases: PropTypes.array.isRequired,
  currentPhaseId: PropTypes.string,
  forceMobile: PropTypes.bool
};

export default PhaseStepper;
