import React  from 'react';
import PropTypes from 'prop-types';
import { CrossroadIcon, EditIcon, HomeSearchIcon, MapIcon, MessagesIcon, MobilePhoneIcon, RoadmapIcon } from 'assets/icons';
import { AccordionWidget, Button } from 'components/elements';
import { getStyle, responsive, styled } from 'style';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FieldContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${getStyle('size.widget.li.height')};

  > svg {
    stroke: ${getStyle('color.secondary')};
    fill: ${getStyle('color.secondary')};
    height: ${getStyle('size.icon.md')};
    margin-right: ${getStyle('spacing.lg')};
  }
  span {
    display: block;
  }
  span:first-child {
    font-weight: 500;
    margin-bottom: ${getStyle('spacing.md')};
  }
  span:last-child {
    font-size: ${getStyle('font.sm')};
    color: ${getStyle('color.secondary')};
  }

  ${props => responsive('width', [
  { width: getStyle('screen.sm', props), value: '50%' },
  { width: getStyle('screen.md', props), value: '33%' }
])}
`;

const MarketingField = styled.div`
  font-size: 0.8em;
  font-style: italic;
`;

const EditButton = styled.div`  
  position: absolute;
  right: 25px;
  top: -60px;

  svg {
    margin-left: 10px;
  }
`;

const Field = ({ name, value, icon: Icon }) => (
  <FieldContainer>
    <Icon />
    <div>
      <span>{value}</span>
      <span>{name}</span>
    </div>
  </FieldContainer>
);

Field.propTypes = {
  icon: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.string
};

const CustomerInfoWidget = ({ customer, setEditMode }) => {
  const { email = '', phone = '', address = {}, consentMarketing = false} = customer;
  const { streetAddress = '', zipCode = '', city = '', dwellingNumber = 'Ikke oppgitt' } = address;

  return (
    <AccordionWidget title="Personopplysninger" wide collapsed>
      <EditButton>
        <Button onClick={() => {setEditMode(true)}}>
          Rediger
          <EditIcon />
        </Button>
      </EditButton>
      <Container>
        <Field icon={MessagesIcon} name="E-postadresse" value={email} />
        <Field icon={MobilePhoneIcon} name="Telefonnummer" value={phone} />
        <Field icon={MapIcon} name="Adresse" value={streetAddress} />
        <Field icon={RoadmapIcon} name="Postnummer" value={zipCode} />
        <Field icon={CrossroadIcon} name="Poststed" value={city} />
        <Field icon={HomeSearchIcon} name="Bolignummer" value={dwellingNumber} />
        {consentMarketing
          ? <MarketingField>Kunde ønsker å motta direkte markedsføring</MarketingField>
          : <MarketingField>Kunde ønsker ikke å motta direkte markedsføring</MarketingField>
        }
      </Container>
    </AccordionWidget>
  );
}

CustomerInfoWidget.defaultProps = {
  customer: {},
  setEditMode: () => {},
};

CustomerInfoWidget.propTypes = {
  customer: PropTypes.shape({}),
  setEditMode: PropTypes.func
};

export default CustomerInfoWidget;
