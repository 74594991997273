import PropTypes from 'prop-types';
import { styled, getStyle, variant } from 'style';

const background = variant('variant', {
  form: getStyle('color.input.form')
});

const backgroundFocused = variant('variant', {
  form: getStyle('color.input.focused.form')
});

// NB! Font size less than 16 px will result in auto zoom on iPhone.
const TextArea = styled.textarea`
  font-size: ${getStyle('font.lg')};
  background: ${background};
  border: ${props => (props.error
    ? `0.0625rem solid ${getStyle('color.status.error', props)};`
    : `0.0625rem solid ${getStyle('color.border.primary', props)};`
  )};
  border-radius: ${getStyle('border.radius.sm')};
  padding: ${getStyle('spacing.sm')};
  outline: none;
  resize: none;

  :focus {
    background: ${backgroundFocused};
  }
  :disabled {
    color: ${getStyle('color.status.disabled')};
  }
`;

TextArea.propTypes = {
  variant: PropTypes.oneOf(['form'])
};

TextArea.defaultProps = {
  variant: 'form'
};

export default TextArea;
