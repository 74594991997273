import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { uuid } from '../../services/common';
import { FormField } from 'components/form';
import { AccordionWidget, Button } from 'components/elements';
import { styled } from 'style';
import { allierApi } from '../../services';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
  button {
    width: calc(50% - 2rem);
  }
`;

const CustomerInfoWidget = ({ userId, customerId, refreshData }) => {
  const [projectName, setProjectName] = useState('');
  const [missingNameError, setMissingNameError] = useState('');

  const onSubmit = useCallback(async () => {
    if (!projectName?.trim()) {
      setMissingNameError('Prosjektnavn er påkrevd');
      return;
    }

    const projectTemplate = await allierApi.getProjectTemplate()

    const project = {
      ...projectTemplate,
      createdAt: new Date().toISOString(),
      createdBy: userId,
      customerId,
      id: uuid(),
      title: projectName,
    };

    await allierApi.createProject(project);
    await refreshData(false);
  }, [userId, customerId, projectName, refreshData]);

  const onChange = useCallback(({ target }) => {
    setProjectName(target.value || '');
    setMissingNameError(target.value?.trim() ? '' : 'Prosjektnavn er påkrevd');

  }, []);

  const onReset = useCallback(() => {
    setProjectName('');
    setMissingNameError('');
  }, []);

  return (
    <AccordionWidget title="Opprett nytt prosjekt" wide collapsed>
      <FormField
        label="Prosjektnavn"
        onChange={onChange}
        value={projectName || ''}
        placeholder="Prosjektnavn"
        name="projectName"
        error={missingNameError || ''}
      />
      <ButtonContainer>
        <Button variant="secondary" onClick={onReset}>Nullstill</Button>
        <Button onClick={onSubmit}>Opprett prosjekt</Button>
      </ButtonContainer>
    </AccordionWidget>
  );
}

CustomerInfoWidget.defaultProps = {
  userId: '',
  customerId: '',
  refreshData: () => {},
};

CustomerInfoWidget.propTypes = {
  userId: PropTypes.string,
  customerId: PropTypes.string,
  refreshData: PropTypes.func,
};

export default CustomerInfoWidget;
