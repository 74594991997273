import PropTypes from 'prop-types';
import { Text } from 'components/elements';

const ProgressPost = ({ post }) => (
  <>
    <Text variant="md">{post.title}</Text>
    {post.files.map(file => <a target="_blank" rel="noreferrer" key={`f_${file._id}`} href={`${file.asset.url}`}>{file.asset.originalFilename.split('.')[0]}</a>)}
  </>
);

ProgressPost.propTypes = {
  post: PropTypes.object
};

export default ProgressPost;
