import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'services/auth';
import { allierApi, sanityApi } from 'services';
import { MAIN_PAGE_SLUG } from './InspirationPage';

const fetchData = async (userId) => {
  const profile = await allierApi.getUserProfile(userId);

  return Promise.all([
    sanityApi.fetchInspiration(),
    sanityApi.fetchAssociationIdehefteUrl(profile),
  ]);
}

export const useInspirationSanityData = (slug) => {
  const data = useRef([]);
  const bookletUrl = useRef('');
  const [loading, setLoading] = useState(true);
  const { userId } = useAuth();

  useEffect(() => {
    fetchData(userId)
      .then(([ inspirationData = [], bookletUrlData = '']) => {
        data.current = inspirationData;
        bookletUrl.current = bookletUrlData?.url || '';
        setLoading(false);
      });
  }, [userId]);

  // useMemo need to run when loading state changes.
  // eslint-disable-next-line
  const pageData = useMemo(() => data.current?.find(d => d.slug === slug) || {}, [slug, loading]);

  return {
    content: pageData?.content || [],
    title: pageData?.title || '',
    linkArray: pageData?.linkArray || [],
    bookletUrl: bookletUrl.current,
    loading,
  }
};

export const useInspirationNavigation = () => {
  const navigate = useNavigate();

  return useCallback((slug) => {
    const link = slug === MAIN_PAGE_SLUG
      ? '/inspiration'
      : `/inspiration/${slug}`;

    navigate(link);
    window?.scrollTo(0, 0);
  }, [navigate]);
};