import { useEffect, useState } from 'react';
import { sanityApi, allierApi } from 'services';
import { useAuth} from 'services/auth';
import { Article, ContactWidget } from './components';

const ContactInfoPage = () => {
    const [borettslag, setBorettslag] = useState(null);
    const { userId } = useAuth();
    useEffect(() => {
      allierApi.getUserProfile(userId).then(profile => {
        sanityApi.fetchAssociationAndPostForCategory(profile.association.sanityId)
          .then(res => {
            if (!res.borettslag) {
              sanityApi.fetchPrivateAssociation()
                .then(pa => setBorettslag(pa.privateAssociation))
            } else {
              setBorettslag(res.borettslag)
            }
          });
        })
    }, [userId]);

  if (!borettslag) {
    return <></>;
  }

  return (
    <Article
      title="Kontaktpersoner"
      imageUrl={(borettslag.image ? borettslag.image.asset.url : '')}
    >
      <ContactWidget borettslag={borettslag} widePage={true} />
      <></>
    </Article>
  )
};

export default ContactInfoPage;
