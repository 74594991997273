import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Button } from 'components/elements';

const UploadSingleButton = ({
  accept, onFileChange, children, ...props
}) => {
  const ref = useRef();

  const onClick = e => {
    e.persist();
    ref.current.click();
  };

  const onChange = ({ target }) => {
    onFileChange(target.files);
    target.value = '';
  };

  return (
    <Button
      onClick={onClick}
      {...props}
    >
      {children}
      <input
        type="file"
        ref={ref}
        onChange={onChange}
        accept={accept}
        hidden
        aria-hidden
      />
    </Button>
  );
};

UploadSingleButton.propTypes = {
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]).isRequired
};

export default UploadSingleButton;
