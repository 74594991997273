import PropTypes from 'prop-types';
import { Dialog, useDialog } from 'components/dialog';
import { Carousel, Button, ButtonContainer } from 'components/elements';
import { useMediaQuery, styled, getStyle } from 'style';

import ProjectAttachment from './ProjectAttachment';

const NoAttachments = styled.div`
  font-size: ${getStyle('font.md')};
  min-height: ${getStyle('size.widget.header.height')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getStyle('spacing.xs')};
  width: 100%;
  background: #BCBABB26;
  border-radius: ${getStyle('border.radius.sm')};
`;

const Attachment = ({ accountManagerPage, title, attachment, clickable, onProjectEdit }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  return (
    <>
      <ProjectAttachment
        accountManagerPage={accountManagerPage}
        title={title}
        attachment={attachment}
        onClick={clickable ? openDialog : null}
        style={{ padding: '0 0.25rem', height: '100%' }}
        wide
        onProjectEdit={onProjectEdit}
      />
      <Dialog
        title={title}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        wide
      >
        <ProjectAttachment accountManagerPage={accountManagerPage} attachment={attachment} wide onProjectEdit={onProjectEdit} />
        <ButtonContainer>
          <Button variant="secondary" onClick={closeDialog}>Lukk</Button>
        </ButtonContainer>
      </Dialog>
    </>
  );
};

Attachment.propTypes = {
  accountManagerPage: PropTypes.bool,
  title: PropTypes.string.isRequired,
  attachment: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
  onProjectEdit: PropTypes.func.isRequired
};

const ProjectAttachmentCarousel = ({ accountManagerPage, project, onProjectEdit }) => {
  const isMobile = useMediaQuery().isMobile();
  const slidesToShow = isMobile ? 1 : 3;

  const phaseTitle = id => (project.phases.find(p => p.id === id) || {}).title;

  return project.attachments.length ? (
    <Carousel
      draggable={false}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToShow}
    >
      {
        project.attachments
        .map(attachment => (
          <Attachment
            accountManagerPage={accountManagerPage}
            key={attachment.fileId}
            title={phaseTitle(attachment.phaseId)}
            attachment={attachment}
            clickable={!isMobile}
            onProjectEdit={onProjectEdit}
          />
        ))
      }
    </Carousel>
  )
    : (
      <NoAttachments>
        Ingen media lastet opp enda
      </NoAttachments>
    );
};

ProjectAttachmentCarousel.propTypes = {
  accountManagerPage: PropTypes.bool,
  project: PropTypes.object.isRequired,
  onProjectEdit: PropTypes.func.isRequired
};

export default ProjectAttachmentCarousel;
