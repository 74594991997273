import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmployee, useAuth } from 'services/auth';
import { allierApi } from 'services';
import { H1, WidgetContainer } from 'components/elements';
import { BikeLoader } from 'components/loading';
import { MessageWidget, CustomerForm, CustomerInfoWidget } from 'pages';
import { getStyle, styled } from 'style';
import ProjectInfoWidget from '../projects/ProjectInfoWidget';
import CreateNewProject from './CreateNewProject';

const Title = styled(H1)`
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  span:first-child {
    font-weight: normal;
    color: ${getStyle('color.secondary')};
  }
`;


const useCustomerData = (customerId) => {
  const { user, isAuthenticated } = useAuth();
  const [customer, setCustomer] = useState({});
  const [associations, setAssociations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const loaded = useRef(true);

  const getMassages = useCallback(async () => {
    const { messages: messagesData = [] } = await allierApi.getMessageThread(customerId)
      .catch(console.error) || {};

    setMessages(messagesData);
  }, [customerId]);

  const getData = useCallback(async (showLoading = true) => {
    if (!isAuthenticated || !isEmployee(user)) return [];
    if (!showLoading) setLoading(true);

    const [
      customerData,
      associationsData,
      projectsData,
    ] = await Promise.all([
      allierApi.getCustomer(customerId),
      allierApi.getCustomerAssociations(),
      allierApi.getProjectsByCustomerId(customerId),
      getMassages(),
    ])
      .catch(console.error) || [];

    if (loaded.current) {
      setCustomer(customerData || {});
      setAssociations(associationsData || []);
      setProjects(projectsData || []);
      setLoading(false);
    }
  }, [isAuthenticated, user, customerId, getMassages]);

  useEffect(() => {
    getData();

    return () => {
      loaded.current = false;
    };
  }, [user, isAuthenticated, getData]);

  return { customer, associations, messages, projects, loading, refreshData: getData, refreshMessages: getMassages };
}

const Customer = () => {
  const { userId, user } = useAuth();
  const { customerId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const { customer, associations, projects, messages, loading, refreshData, refreshMessages } = useCustomerData(customerId);

  const onUpdateCustomer = useCallback(async (customer) => {
    const payLoad = {
      email: customer.email,
      familyName: customer.familyName,
      givenName: customer.givenName,
      id: customer.id,
      phone: customer.phone,
      association: { id: customer.association },
      address: {
        city: customer.city,
        dwellingNumber: customer.dwellingNumber,
        streetAddress: customer.streetAddress,
        zipCode: customer.zipCode,
      },
      electronicInvoice: customer.electronicInvoice || false
    };

    let error;
    await allierApi.updateCustomer(payLoad).catch((err) => error = err.toString());
    if(error) return error;
    await refreshData();
    return '';
  }, [refreshData]);

  if (!customerId) return null;
  if (loading) return <BikeLoader />;

  if (editMode) {
    return (
      <WidgetContainer>
        <Title><span>Oppdater kunde</span></Title>
        <CustomerForm
          isEditing={editMode}
          customer={customer}
          associations={associations}
          onCancel={() => setEditMode(false)}
          onSave={onUpdateCustomer}
          onDelete={allierApi.deleteCustomer}
        />
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer>
      <Title>
        <span>Kundeside</span>
        <span>{`${customer.givenName} ${customer.familyName}`}</span>
        <span>{`${customer.association?.title ?? ''}`}</span>
      </Title>
      <CustomerInfoWidget customer={customer} setEditMode={setEditMode} />
      {projects.length ? (
        projects.map((project) => (
          <ProjectInfoWidget
            key={project.id}
            customerProfile={customer}
            project={project}
            title="Baderom"
            buttonText="Gå til prosjektside"
            isManager
            wide
          />
        ))
      ) : (
        <CreateNewProject
          userId={userId}
          customerId={customerId}
          refreshData={refreshData}
        />
      )}
      <MessageWidget
        userIsAccountManager={isEmployee(user)}
        messages={messages}
        userId={userId}
        receiverId={customerId}
        sendMessage={allierApi.sendMessage}
        uploadFiles={allierApi.uploadFile}
        refresh={refreshMessages}
      />
    </WidgetContainer>
  );
};

export default Customer;
