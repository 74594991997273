import PropTypes from 'prop-types';
import { styled, getStyle } from 'style';

const Container = styled.div`
  background: ${getStyle('color.progress.background')};
  border-radius: ${getStyle('border.radius.lg')};
  width: 100%;

  > div {
    background: ${getStyle('color.progress.foreground')};
    border-radius: inherit;

    min-width: ${props => `${100 * props.progress}%`} !important;
    width: ${props => `${100 * props.progress}%`};
    height: ${getStyle('size.progress.md')};
  }
`;

const Progress = ({ value }) => (
  <Container role="progressbar" progress={value}>
    <div />
  </Container>
);

Progress.propTypes = {
  value: PropTypes.number.isRequired
};

export default Progress;
