import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, getStyle, useMediaQuery } from 'style';
import { Dialog, useDialog } from 'components/dialog';
import { Button } from 'components/elements';

const projectPhaseProgressWidth = '1.5rem';
const projectPhaseDotColor = '#AEA0CD';
const projectPhaseDotSize = '0.75rem';
const projectPhaseRowHeight = '2rem';

const ContainerBase = styled.div`
  display: flex;

  .progress {
    display: flex;

    background: ${getStyle('color.slider.background')};
    border-radius: ${getStyle('border.radius.lg')};

    > div {
      position: relative;
      flex-grow: 1;

      > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: ${projectPhaseDotColor};
        border-radius: ${getStyle('border.radius.lg')};
        width: ${projectPhaseDotSize};
        height: ${projectPhaseDotSize};
      }

      &.completed {
        background: ${getStyle('color.slider.selected.foreground')};
      }

      &.current {
        background: ${getStyle('color.slider.selected.foreground')};

        > div {
          background: ${getStyle('color.slider.foregroundDot')};
          width: ${projectPhaseDotColor};
          height: ${projectPhaseDotColor};
        }
      }
    }
  }
`;

const ContainerMobile = styled(ContainerBase)`
  .progress {
    flex-direction: column;

    margin-left: ${getStyle('spacing.xs')};
    width: ${projectPhaseProgressWidth};

    > div {
      flex-basis: ${projectPhaseRowHeight};

      &:first-child {
        border-top-left-radius: ${getStyle('border.radius.lg')};
        border-top-right-radius: ${getStyle('border.radius.lg')};
      }
      &.current {
        border-bottom-left-radius: ${getStyle('border.radius.lg')};
        border-bottom-right-radius: ${getStyle('border.radius.lg')};
      }
    }
  }

  .phases {
    display: flex;
    flex-direction: column;
    line-height: ${projectPhaseRowHeight};
    margin: 0 ${getStyle('spacing.xxl')};
    justify-content: space-between;
    word-wrap: break-word;
  }
`;

const Container = styled(ContainerBase)`
  flex-direction: column;
  justify-content: space-between;

  .progress {
    height: ${projectPhaseProgressWidth};
    margin-bottom: ${getStyle('spacing.md')};

    > div {
      &:first-child {
        border-top-left-radius: ${getStyle('border.radius.lg')};
        border-bottom-left-radius: ${getStyle('border.radius.lg')};
      }
      &.current::after {
        background: ${getStyle('color.slider.current')};
        width: 0.3rem;
        height: ${projectPhaseProgressWidth};
        position: absolute;
        right: 0;
        top: 0;
        content: " ";
      }
    }
  }

  .phases {
    display: flex;
    font-size: ${getStyle('font.sm')};
    text-align: center;

    > button {
      flex-grow: 1;
      flex-basis: 0;
      margin: 0 ${getStyle('spacing.xs')};
    }
  }
`;

const DialogContentContainer = styled.div`
  margin-bottom: ${getStyle('spacing.md')};
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-bottom: ${getStyle('spacing.md')};
  width: 100%;

  > button {
    width: 100%;
  }
`;

const examplePhaseDescriptionText = phase => {
  if (phase.id === '7734838b-30f8-4432-9c68-e7918eb7b6ae') {
    return (
      <DialogContentContainer>
        Nøkkelen til god prosjektgjennomføring er planlegging.<br/><br/>

        Før vi begynner, går vi i fellesskap gjennom hva som skjer i de forskjellige prosjektfasene og blir enige om oppstartsdato og overleveringsdato.
        Vi diskuterer eventuelle utfordringer og andre ting som kan påvirke fremdriften i prosjektet.
        Er det for eksempel bestilt utstyr med ekstra lang leveringstid tar vi høyde for dette i planleggingen. Slik unngår vi (dyre) forsinkelser og sikrer at riktig fagperson kommer til rett tid.
        <br/><br/>
        Du som kunde kan derfor forholde deg til den fremdriften vi er blitt enige om.
      </DialogContentContainer>
    );
  }
  if (phase.id === 'b44d5010-333d-49fc-b123-fb517af0ba4d') {
    return (
      <DialogContentContainer>
        Vi starter alltid med å dekke til det vi på forhånd har avtalt. Deretter demonterer vi baderomsmøbler og eksisterende utstyr som mellomlagres på avtalt sted. 
        Så rives vegger og gulv, slik at rommet blir klart til å bygges opp igjen.
        <br/><br/>
        Skulle vi oppdage skjulte mangler eller skader, som for eksempel råte, lekkasjer eller andre ting som må fikses, informerer vi deg umiddelbart.
        Så blir vi sammen enige om hva som skal utbedres.
      </DialogContentContainer>
    );
  }
  if (phase.id === 'e0b19009-2b77-4ac6-9fcd-048c4bd981e2') {
    return (
      <DialogContentContainer>
        I denne fasen legger vi varmekabler, støper gulv, kler vegger med våtromsplater og legger inn nye vann- og avløpsrør eller nye elektriske Installasjoner.

        Når grunnarbeidet er ferdig gjennomfører vi en kvalitetskontroll for å sikre at alt er klart før overflatearbeidet starter.
      </DialogContentContainer>
    );
  }
  if (phase.id === '12afa35a-ef16-4d74-b4f7-21ea51e1fa85') {
    return (
      <DialogContentContainer>
        Nå begynner badet virkelig å ta form! Vi utfører sluttarbeidet på tak, vegger og gulv og monterer takplater, fliser/tapetserer vegger og legger gulv med fliser eller belegg, alt etter hva du har bestilt.
      </DialogContentContainer>
    );
  }

  if (phase.id === 'be47c5b0-5a9b-43d0-931f-d32733daac79') {
    return (
      <DialogContentContainer>
        Badet er snart ferdig! Nå monterer vi baderomsmøbler og annet utstyr som dusjløsning, badekar, belysning, oppbevaring og vask.
      </DialogContentContainer>
    );
  }

  return (
    <DialogContentContainer>
      Vi er nesten ved veis ende, men før du kan nyte ditt nyoppussede bad er det kun noen få ting som gjenstår.
      <br/><br/>

      Det ene er ferdigstilling, som betyr at vi gjennomfører en kvalitetskontroll for å sikre at alle overflater og alt utstyr er som avtalt.<br/>
      Det andre og siste er en ferdigbefaring, hvor vi sammen med deg går gjennom arbeidet som er utført. 
      Du får utlevert FDV dokumentasjon inkludert brukermanualer. FDV dokumentasjon blir også lagt inn i Boligmappa.no.
      Vi fører protokoll fra ferdigbefaringen, som signeres av oss og av deg.
      <br/><br/>

      Skulle vi oppdage eventuelle feil eller mangler, vil vi utbedre disse snarlig og senest innen 14 dager*.

      *med forbehold om lang leveringstid.
    </DialogContentContainer>
  );
};

const ProjectPhases = ({ phases, currentPhaseId }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const isMobile = useMediaQuery().isMobile();
  const Phases = isMobile ? ContainerMobile : Container;
  const currentPhaseIdx = phases.findIndex(p => p.id === currentPhaseId);

  const [dialogState, setDialogState] = useState({ title: '', content: '' });
  const openPhaseDialog = phase => {
    setDialogState({
      title: phase.title,
      content: examplePhaseDescriptionText(phase)
    });
    openDialog();
  };

  return (
    <Phases>
      <Dialog
        title={dialogState.title}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        wide
      >
        {
          dialogState.content
        }
        <ButtonContainer>
          <Button variant="secondary" onClick={() => closeDialog()}>Greit, jeg skjønner</Button>
        </ButtonContainer>
      </Dialog>
      <div className="progress">
        {phases.map((phase, idx) => {
          const completed = idx < currentPhaseIdx ? 'completed' : '';
          const current = idx === currentPhaseIdx ? 'current' : '';
          return (
            <div className={completed || current} key={phase.id}><div /></div>
          );
        })}
      </div>
      <div className="phases">
        {phases.map(phase => (
          <Button variant="link" onClick={() => openPhaseDialog(phase)} key={phase.id}>{phase.title}</Button>
        ))}
      </div>
    </Phases>
  );
};

ProjectPhases.propTypes = {
  phases: PropTypes.array.isRequired,
  currentPhaseId: PropTypes.string
};

export default ProjectPhases;
