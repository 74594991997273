import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Button,AccordionWidget, FieldList, ButtonContainer } from 'components/elements';
import { BathtubIcon, StatusIcon, KeyIcon } from 'assets/icons';

const ProjectInfoWidget = ({ customerProfile, project, wide, title, buttonText, isManager }) => {
  const navigate = useNavigate();
  const phase = project.phases.find(p => p.id === project.currentPhaseId);

  return (
    <AccordionWidget
      icon={<BathtubIcon />}
      title={title}
      subtitle={project.title}
      wide={wide}
    >
      <FieldList
        fields={[
          { label: 'Status på prosjekt', value: phase ? phase.title : 'Ikke startet', icon: <StatusIcon /> },
          { label: 'Adresse', value: `${customerProfile.address.streetAddress} - ${customerProfile.address.dwellingNumber}`, icon: <KeyIcon /> }
        ]}
      />
      <ButtonContainer>
        <Button role="link" onClick={() => navigate(`${isManager ? '/accountmanager' : ''}/project/${project.id}/${isManager ? customerProfile.id : ''}`)}>
          {buttonText}
        </Button>
      </ButtonContainer>
    </AccordionWidget>
  );
};

ProjectInfoWidget.defaultProps = {
  title: 'Mitt baderom',
  buttonText: 'Gå til mitt baderom',
  isManager: false,
};

ProjectInfoWidget.propTypes = {
  customerProfile: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  wide: PropTypes.bool,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  isManager: PropTypes.bool,
};

export default ProjectInfoWidget;
