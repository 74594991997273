import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProjectPhases from './ProjectPhases';
import { allierApi } from 'services';
import { Button, AccordionWidget, CompanyHeader } from 'components/elements';
import { styled, getStyle, responsive } from 'style';
import { SelectFormField } from '../../components/form';

const PhaseSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  column-gap: 3rem;
  
  select {
    height: 50px;
  }
  
  button {
    height: 48px;
    margin: 16px 0 19px;
  }
  
  ${props => responsive('flex-direction', [
    { width: getStyle('screen.sm', props), value: 'row' }
  ])}

  > * {
    width: 100%;

    ${props => responsive('width', [
      {
        width: getStyle('screen.sm', props),
        value: `calc(50% - ${getStyle('spacing.xxxl', props)})`
      }
    ])}
  }
`;


const StyledButton = styled(Button)`
  margin-top: ${getStyle('spacing.xxxl')};
  width: 100%;
`;

const ProjectWidget = ({ project, customer, refreshData }) => {
  const [isEditing, setEditing] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState(project.currentPhaseId);

  useEffect(() => {
    setSelectedPhaseId(project.currentPhaseId);
  }, [project.currentPhaseId]);

  const onUpdateProjectPhase = async ({ phaseId }) => {
    await allierApi.updateProject({ ...project, currentPhaseId: phaseId || selectedPhaseId })
    if (refreshData) await refreshData(false);
    setEditing(false);
  };

  const onCancel = () => {
    setSelectedPhaseId(project.currentPhaseId);
    setEditing(false);
  };

  const onNextPhase = () => {
    if (!project?.phases) return;
    const currentPhaseIndex = project.phases.findIndex(({ id }) => id === project.currentPhaseId);

    if (currentPhaseIndex !== project.phases.length - 1) {
      const { id } = project.phases[currentPhaseIndex + 1] || {};
      if (id) onUpdateProjectPhase({ phaseId: id});
    }
  }

  const { address = {} } = customer || {};
  const projectTitle = `${project.title || ''}, ${address.streetAddress || ''}, ${address.dwellingNumber || ''}`;

  return (
    <AccordionWidget title={projectTitle} wide>
      <CompanyHeader title="Prosjekt" subTitle="Vedrørende bad" />
      <ProjectPhases phases={project.phases} currentPhaseId={selectedPhaseId} />
      {
        isEditing
          ? (
              <PhaseSelect>
                <SelectFormField
                  wide
                  id="phaseSelect"
                  options={project.phases}
                  value={selectedPhaseId}
                  onChange={e => setSelectedPhaseId(e.target.value)}
                  label="Velg fase"
                  notChosenOption="Ikke startet"
                />
                <Button variant="secondary" onClick={onNextPhase} >Neste fase</Button>
                <Button variant="secondary" onClick={onCancel}>Avbryt</Button>
                <Button onClick={onUpdateProjectPhase} disabled={!selectedPhaseId}>Lagre</Button>
              </PhaseSelect>
          )
          : <StyledButton onClick={() => setEditing(true)}>Endre fase</StyledButton>
      }
    </AccordionWidget>
  );
};

ProjectWidget.propTypes = {
  project: PropTypes.shape({}).isRequired,
  refreshData: PropTypes.func
};

export default ProjectWidget;
