import { styled, getStyle } from 'style';

const WidgetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    margin-bottom: ${getStyle('spacing.xxxl')};
  }
`;

export default WidgetContainer;
