import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Label, Text } from 'components/elements';
import { styled, getStyle } from 'style';
import FieldContainer from './FieldContainer';
import FieldError from './FieldError';

const Container = styled(FieldContainer)`
  p {
    color: ${getStyle('color.secondary')};
    font-weight: bold;
    min-height: 1.5rem;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: ${getStyle('spacing.xs')};
  padding: 0 ${getStyle('spacing.sm')};

  > * {
    width: 50%;
    max-width: 12rem;
  }
  label {
    font-weight: normal;
    padding-left: ${getStyle('spacing.xs')};
  }
`;

const RadioFormField = ({
  name, label, options, wide
}) => (
  <Container wide={wide}>
    <Text>{label}</Text>
    <Field name={name}>
      {({ field, meta }) => {
        const touchedAndError = meta.error && meta.touched;
        return (
          <>
            <OptionsContainer>
              {options.map(option => (
                <div key={option.name}>
                  <input
                    {...field}
                    type="radio"
                    name={name}
                    id={option.name}
                    value={option.value}
                  />
                  <Label htmlFor={option.name}>{option.name}</Label>
                </div>
              ))}
            </OptionsContainer>
            <FieldError hidden={!touchedAndError}>{meta.error}</FieldError>
          </>
        );
      }}
    </Field>
  </Container>
);

RadioFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  wide: PropTypes.bool
};

export default RadioFormField;
