import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { BackButton } from 'components/elements';
import { Page } from 'components/layout';
import { sanity, sanityApi } from 'services/sanity';
import { BlockContent } from 'components/elements';
import { ArticlePublic } from './components';

const ReferencePage = () => {
  let params = useParams();
  const [reference, setReference] = useState(null);
  useEffect(() => {
    sanityApi.fetchReference(params.slug).then(res => setReference(res.reference));
  }, [params.slug]);
  if (reference == null) {
    return <></>;
  }
  return (
    <Page>
      <BackButton url="/">
        Tilbake til forsiden
      </BackButton>
      <ArticlePublic
        title="Om Unik"
        text={reference.description}
      >
        <BlockContent
          blocks={reference.content}
          projectId={sanity.clientConfig.projectId}
          dataset={sanity.clientConfig.dataset}
        />
      </ArticlePublic>
    </Page>
  )
};

export default ReferencePage;
