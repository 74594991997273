import { useContext } from 'react';
import { useMediaQuery as mediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components';
import { reduce } from 'ramda';

export const responsive = (property, widthAndValues) => reduce(
  (queries, query) => (
    `${queries}
      @media screen and (min-width: ${query.width}px) {
        ${property}: ${query.value};
    }`),
  '',
  widthAndValues
);

export const useMediaQuery = () => {
  const theme = useContext(ThemeContext);
  return {
    isTouchDevice: () => !mediaQuery({ query: '(pointer: fine)' }),
    isMobile: () => !mediaQuery({ query: `(min-width: ${theme.screen.sm}px)` })
  };
};
