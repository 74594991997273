import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions } from '../../components/dialog';
import { AccordionWidget, Button, Cell, ColumnHeader, Table, TableHeader, TableRow } from '../../components/elements';
import { Container, CreateButton } from './AdminPageComponents';
import { FormField, SelectFormField } from '../../components/form';
import { useErrorHandler } from 'react-error-boundary';
import validationSchema from '../customer/validationSchema';
import { allierApi } from '../../services';
import { getStyle, styled } from '../../style';

const ExistingAccManagersContainer = styled(AccordionWidget)`
  margin-top: ${getStyle("spacing.xl")};
`;

const EmailCell = styled(Cell)`
  width: ${(props) => props.width || "100%"};
  text-align: ${(props) => props.talign || "inherit"};
  ::first-letter {
    text-transform: lowercase !important;
  }
`;

const ROLE_OPTIONS = [{ label: "Kundeansvarlig", value: "rol_47CqXympLsYt0ZLp" }];

const CUSTOMER_FORM_FIELDS = [
  { name: "givenName", label: "Fornavn" },
  { name: "familyName", label: "Etternavn" },
  { name: "email", label: "E-postadresse" },
  { name: "phone", label: "Mobilnummer", type: "tel" },
  { name: "streetAddress", label: "Adresse" },
  { name: "zipCode", label: "Postnummer", type: "number" },
  { name: "city", label: "Sted" }
];

const flattenCustomerData = (customer) =>
  customer?.id
    ? {
      email: customer.email || "",
      familyName: customer.familyName || "",
      givenName: customer.givenName || "",
      phone: customer.phone || "",
      id: customer.id || "",
      city: customer.address.city || "",
      dwellingNumber: customer.address.dwellingNumber || "",
      streetAddress: customer.address.streetAddress || "",
      zipCode: customer.address.zipCode || "",
    }
    : {};

const ExistingAccountManagers = ({ accountManagers = [], loading, fetchingError  }) => {
  if (loading) return <p>Laster inn kundeansvarlige...</p>;
  if (!accountManagers.length) return <p>{fetchingError}</p>;

  return (
    <ExistingAccManagersContainer title="Eksisterende kundeansvarlige:" wide collapsed>
      <div>
        <Table>
          <TableHeader>
            <ColumnHeader>Navn</ColumnHeader>
            <ColumnHeader style={{ textAlign: "start" }}>Epost</ColumnHeader>
          </TableHeader>
          {
            accountManagers.map((accManager, index) => (
              <TableRow key={index}>
                <Cell>{`${accManager.givenName} ${accManager.familyName}`}</Cell>
                <EmailCell>{accManager.email}</EmailCell>
              </TableRow>
            ))
          }
        </Table>
      </div>
  </ExistingAccManagersContainer>
  );
};

const validate = (schema, data) => {
  try {
    schema.validateSync(data, { sync: true, abortEarly: false });
    return {};
  } catch ({ inner: errorArray }) {
    const validationErrors = {};
    errorArray.forEach(({ message, path }) => {
      validationErrors[path] = message;
    });

    return validationErrors;
  }
};

const AccountManager = () => {
  const [customerData, setCustomerData] = useState(flattenCustomerData);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [existingAccountManagers, setExistingAccountManagers] = useState([]);

  const [isCreationLoading, setIsCreationLoading] = useState(false);
  const [isFetchingLoading, setIsFetchingLoading] = useState(false);

  const [fetchingError, setFetchingError] = useState("Ingen kundeansvarlige å hente");

  const [isCreated, setIsCreated] = useState(false);

  const handleError = useErrorHandler();

  useEffect(() => {
    setIsFetchingLoading(true);
    allierApi
      .getAccountManagers()
      .then(({ users = [] } = {}) => {
        setExistingAccountManagers(users.sort((a, b) => a.givenName.toLowerCase().localeCompare(b.givenName.toLowerCase())));
      })
      .catch((error) => setFetchingError(`Feil under henting av kundeansvarlige: ${error.message}`))
      .finally(() => setIsFetchingLoading(false));
  }, []);

  const onChange = ({ target }) => {
    const { name, value = "", type = "" } = target;
    if (!name) return;

    if (type.includes("select")) {
      onBlur({ target });
    }

    setCustomerData((prevState) => {
      const newProfileState = { ...prevState, [name]: value };
      setErrors(validate(validationSchema, newProfileState));
      return newProfileState;
    });
  };

  const handleCreateAccountManager = async () => {
    if (Object.keys(errors).some((key) => errors[key])) {
      const toucheAll = {};
      CUSTOMER_FORM_FIELDS.forEach(({ name }) => {
        toucheAll[name] = true;
      });
      setTouched(toucheAll);
      return;
    }

    setIsCreationLoading(true);
    const payload = {
      givenName: customerData.givenName,
      familyName: customerData.familyName,
      email: customerData.email,
      phone: customerData.phone,
      address: {
        zipCode: customerData.zipCode,
        city: customerData.city,
        streetAddress: customerData.streetAddress,
        dwellingNumber: customerData.dwellingNumber,
      },
    };
    const newAccountManger = await allierApi
      .createAccountManager(payload)
      .catch((error) => handleError(error));

    setExistingAccountManagers((prevState) => [newAccountManger, ...prevState]);
    setIsCreated(true);
    setIsCreationLoading(false);
  };

  const handleConfirmCreation = () => {
    setCustomerData({});
    setIsCreated(false);
  };

  const onBlur = ({ target }) => {
    const { name } = target;
    const additionalEffects = {};
    if (name === "association") {
      additionalEffects.dwellingNumber = true;
    }
    setTouched((prevState) => ({
      ...prevState,
      ...additionalEffects,
      [name]: true,
    }));
  };

  return (
    <>
      <AccordionWidget title="Opprett en kundeansvarlig" wide collapsed>
        <Dialog title={"Kundeveileder opprettet"} isOpen={isCreated} onClose={handleConfirmCreation}>
          <p>En epost med bekreftelse og passord har blitt sendt til kundeveilederen.</p>
          <DialogActions><Button onClick={() => handleConfirmCreation()} disabled={false}>Ok</Button></DialogActions>
        </Dialog>
        <Container>
          {CUSTOMER_FORM_FIELDS.map(({ name, label, type = "text" }) => (
            <FormField
              key={name}
              label={label}
              name={name}
              type={type}
              onChange={onChange}
              onFocus={onBlur}
              value={customerData[name] || ""}
              disabled={isCreationLoading}
              error={touched[name] ? errors[name] || "" : ""}
            />
          ))}
          <SelectFormField
            key="rolePicker"
            label="Velg Rolle"
            name="rollePicker"
            onChange={(e) => setSelectedRoleId(e.target.value)}
            onBlur={onBlur}
            value={selectedRoleId}
            disabled={isCreationLoading}
            options={ROLE_OPTIONS}
          />
          <CreateButton disabled={isCreationLoading || Object.keys(customerData).length === 0} onClick={handleCreateAccountManager}>
            Opprett
          </CreateButton>
        </Container>
      </AccordionWidget>
      <ExistingAccountManagers accountManagers={existingAccountManagers} fetchingError={fetchingError} loading={isFetchingLoading}/>
    </>
  );
};

export default AccountManager;
