/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, BlockContent } from 'components/elements';
import { styled, getStyle, responsive } from 'style';
import { BathtubIcon } from 'assets/icons';
import { Grid, StyledAccordian, ImageContainer } from './Style';

const Content = styled.div`
  display: grid;
  overflow-y: hidden;
  grid-template-rows: 1fr auto;
  height: ${props => (props.isExpanded ? '100%' : '15rem')};
  ${props => responsive('padding', [
    {
      width: getStyle('screen.sm', props),
      value: `${getStyle('spacing.md', props)} ${getStyle('spacing.xl', props)}`
    }
  ])}
`;

const ExpandleContent = styled.div`
  margin: ${getStyle('spacing.lg')} 0;
  line-height: 1rem;
  height: ${props => (props.isExpanded ? '100%' : '8rem')};
  overflow-y: hidden;
`;

const serializers = {
  marks: {
    link: props => (
      <>
        <a href={`${props.mark.href}`} target="_blank" rel="noreferrer">{props.children}</a>
        <iframe title={props.mark.href} src={`${props.mark.href}?title=0&byline=0&portrait=0`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
      </>
    )
  }
};

const BlogPost = ({ post, sanity, wide }) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Grid wide>
      <StyledAccordian title={post.title} icon={<BathtubIcon />} wide>
        <Content isExpanded={isExpanded}>
          <ExpandleContent isExpanded={isExpanded}>
            <BlockContent
              blocks={post.body}
              projectId={sanity.clientConfig.projectId}
              dataset={sanity.clientConfig.dataset}
              imageOptions={{ w: 320, h: 240, fit: 'max' }}
              serializers={serializers}
            />
          </ExpandleContent>
          <Button
            role="link"
            onClick={() => {
              setExpanded(!isExpanded);
            }}
          >
            {isExpanded ? 'Lukk' : 'Les mer' }
          </Button>
        </Content>
      </StyledAccordian>
      { (post.mainImage && isExpanded) && <ImageContainer size="contain" expand="contain" url={post.mainImage ? post.mainImage.asset.url : ''} /> }
      { (post.mainImage && !isExpanded) && <ImageContainer url={post.mainImage ? post.mainImage.asset.url : ''} /> }
    </Grid>
  );
};

BlogPost.propTypes = {
  post: PropTypes.object.isRequired,
  sanity: PropTypes.object.isRequired,
  wide: PropTypes.bool
};

export default BlogPost;
